@import '../../constants/colors.scss';
@import '../../constants/mixins.scss';
@import '../../constants/global.scss';

.sidebar {
	@include flexColumn;
	width: 20.8rem;
	min-width: 20.8rem;
	background-color: $sideBarBackground;
	transition: 650ms;
	overflow: hidden;
	z-index: 1;
	border-right: 1px solid $neutral200;
}

.sidebarNav {
	@include flexColumn;
	gap: 0.8rem;
	min-height: 30rem;
	overflow: scroll;
}

.sidebarNav::-webkit-scrollbar {
	width: 2px;
}

.sidebarNav::-webkit-scrollbar-thumb {
	background: transparent;
}

.sidebarNav:hover::-webkit-scrollbar-thumb {
	background: $neutral300;
}

.sidebar:active {
	transition: 350ms;
}

.sidebarHidden {
	display: flex;
	flex-direction: column;
	background-color: $neutral900;
	left: -100%;
	width: 0rem;
	transition: 650ms;
	overflow: hidden;
}

.sidebarHidden:active {
	transition: 350ms;
}

.header {
	@include flex;
	height: 9rem;
	align-items: center;
	justify-content: flex-start;
	padding: 0 2.4rem;
}

.logo {
	@include flex;
	&:first-child {
		display: flex;
	}
	&:last-child {
		display: none;
	}
}

.closeList {
	overflow: hidden;
	transition: 400ms;
	opacity: 0;
}

.openList {
	transition: 400ms;
	opacity: 1;
}

.sidebarLinksContainer {
	@extend .flex;
	@extend .fontSize14;
	@extend .fontWeight250;
	transition: 400ms;
	align-items: center;
	padding: 1.4rem 1.4rem 1.4rem 3.2rem;
	height: 5rem;
	justify-content: space-between;
	text-decoration: none;
	width: 100%;
	border-left: 2px solid transparent;
	cursor: pointer;
	color: $neutral300;
	transition: 400ms;
	&:hover .link {
		color: $neutral600;
		@extend .fontWeight450;
	}
	&:hover {
		border-left: 2px solid $black;
	}
}

.link {
	@extend .flex;
	@extend .fontSize14;
	@extend .fontWeight250;
	align-items: center;
	color: $neutral600;
	transition: 400ms;
}

.sidebarActiveLinks {
	transition: 400ms;
	border-left: 2px solid $black;
	background-color: $neutral200;
	width: auto;
	&:hover .link {
		color: $neutral600;
		@extend .fontWeight450;
	}
}

.navLink {
	text-decoration: none;
}

.linkActive {
	@extend .fontWeight450;
	color: $black;
}

.linkIcon {
	width: auto !important;
	height: 1.8rem !important;
	margin-right: 1.2rem;
}

.subLink {
	@extend .link;
	margin-left: 4rem;
	padding-left: 1.4rem;
	border-left: 2px solid $neutral300;
	height: 3.6rem;
}

.subLink:hover {
	@extend .fontWeight450;
	color: $neutral600;
	border-left: 2px solid $neutral700;
}

.subLink:active {
	opacity: 0.5;
}

.subLinkActive {
	@extend .fontWeight450;
	border-left: 2px solid $neutral800;
	color: $black;
}

.userDetails {
	@include overflowHiddenEllipsisNowrap;
	color: $neutral600;
}

.linkText {
	@include overflowHiddenEllipsisNowrap;
	min-width: 9rem;
}

.arrowDown {
	transition: 400ms;
}

.arrowUp {
	transform: rotate(180deg);
	transition: 400ms;
}

@media only screen and (max-width: $sideBarCollapse) {
	.sidebar:not(:hover) {
		width: 9rem;
		min-width: 9rem;
	}

	.linkText {
		transition: 400ms;
		opacity: 0;
	}

	.logo {
		&:first-child {
			display: none;
		}
		&:last-child {
			display: flex;
		}
	}

	.linkIcon {
		margin-right: 0;
	}

	.header {
		padding: 2.4rem 3.2rem;
	}

	.userDetails {
		display: none;
	}

	.openList {
		display: none;
	}

	// open and show full size bar on hover
	.sidebar:hover .userDetails {
		display: flex;
	}

	.sidebar:hover .openList {
		display: block;
	}

	.sidebar:hover .linkText {
		display: flex;
		transition: 400ms;
		opacity: 1;
	}

	.sidebar:hover .logo {
		&:first-child {
			display: flex;
		}
		&:last-child {
			display: none;
		}
	}
	.sidebar:hover .linkIcon {
		margin-right: 1.2rem;
	}
}
