@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.container {
	border: 1px solid $neutral200;
	padding: 2.4rem;
	@include flexColumn;
}

.content {
	@include flexColumn;
	gap: 2.4rem;
}

.invoiceContainer {
	@include flex;
	gap: 0.8rem;
	justify-content: flex-start;
	padding: 1rem !important;
	&:hover {
		background-color: $neutral200;
		transition: 350ms;
	}
}

.title {
	@extend .fontSize18;
	@extend .fontWeight450;
}

.numberContainer {
	@include flexCenter;
	border-radius: 0.2rem;
	background-color: $neutral100;
	color: $neutral;
	padding: 0.8rem;
	height: 100%;
	width: 3.1rem;
	@extend .fontSize12;
	@extend .fontWeight450;
}

.body {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: repeat(2, 1fr);
	gap: 0.4rem;
}

.customerName {
	@extend .fontSize12;
	@extend .fontWeight450;
	color: $neutral600;
	max-width: 12.5rem;
	@include overflowHiddenEllipsisNowrap;
}

.invoiceTotal {
	@extend .fontSize14;
	@extend .fontWeight450;
}

.text {
	@include flexColumn;
	text-align: left;
}

.emptyStateTitle {
	font-size: 1.6rem;
	line-height: 2.4rem;
	@extend .fontWeight650;
}

.emptyStateSubTitle {
	@extend .fontSize12;
	@extend .fontWeight450;
}
