@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.container {
	@include borderNeutral;
	position: relative;
	padding: 1.4rem;
}

.productDetailsContainerCubTitle {
	@include flex;
	justify-content: space-between;
	align-items: center;
	padding: 1.4rem 0;
}

.addProduct {
	height: 3.2rem;
	padding: 0 1rem;
	line-height: unset;
}

.emptyContainer {
	@include flexCenter;
	flex-direction: column;
	height: 20rem;
	gap: 1.4rem;
}

.emptyIconContainer {
	@include flexCenter;
	height: 7.2rem;
	width: 7.2rem;
	background-color: $neutral100;
	border-radius: 5rem;
}

.pricingModelContainer {
	@include flexColumn;
	gap: 1.4rem;
}

.table {
	border: none !important;
	min-width: 70rem;
	padding-right: 0.4rem;
}

.tableCard {
	@include borderNeutral;
	background-color: $white;
	border-radius: 0.6rem;
}

.tableScroll {
	overflow: scroll;
}

.tableCardHeader {
	@include flex;
	padding: 2.4rem;
	align-items: center;
	gap: 1.4rem;
	width: 100%;
}

.itemCardIconContainer {
	@include borderNeutral;
	@include flexCenter;
	height: 4rem;
	width: 4rem;
	min-width: 4rem;
	background-color: $white;
	height: 5rem;
	width: 5rem;
	min-width: 5rem;
}

.itemTag {
	@include overflowHiddenEllipsisNowrap;
	@extend .fontSize12;
	text-align: center;
	color: $primary;
	height: 2.2rem;
	border: 1px solid $primary400;
	border-radius: 0.4rem;
	min-width: 4.5rem;
	width: fit-content;
	padding: 0 0.8rem;
}

.errorAlert {
	@extend .appearAnimation;
	position: absolute;
	left: 20%;
	top: 13rem;
	background-color: rgba(255, 255, 255, 0.7);
	border-radius: 2rem;
	color: $destructive;
	box-shadow: 0 0 5px 10px rgba(255, 255, 255, 0.7);
	animation: moveBottom 1.7s linear 1;
}

@keyframes moveBottom {
	0% {
		top: 14rem;
	}

	100% {
		top: 15rem;
	}
}

.settingsContainer {
	@include flex;
	align-items: flex-end;
}

.rightButtons {
	@include flex;
	gap: 0.8rem;
}

.issueDatePicker {
	border-radius: 6px 0 0 6px;
	border-right-color: transparent;
	width: 14rem;
	padding: 1.4rem;
}

.tooltipText {
	@extend .fontSize12;
	color: $neutral900;
}

.periodSelect {
	border-radius: 0px 6px 6px 0px;
	padding: 1.4rem 0.8rem;
}

.billingPeriodSelectTitle {
	width: 11.5rem;
}
