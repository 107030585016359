@import '../../constants/colors.scss';
@import '../../constants/global.scss';

.container {
	position: absolute;
	position: fixed;
	height: 100vh;
	width: 100%;
	background-color: rgba(255, 255, 255, 0.4);
	backdrop-filter: blur(3px);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	z-index: 40;
}

.lottie {
	height: 25rem;
}
