@import '../../constants/colors.scss';

.container {
	position: absolute;
	position: fixed;
	height: 100vh;
	width: 100%;
	background-color: rgba(255, 255, 255, 0.3);
	backdrop-filter: blur(2px);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 101;
}

.loaderIcon {
	border: 1rem solid rgba(17, 24, 39, 0);
	border-top: 1rem solid $success700;
	border-right: 1rem solid $success700;
	border-left: 1rem solid $success700;
	border-radius: 50%;
	width: 10rem;
	height: 10rem;
	animation: spin 1s linear infinite;
	animation-delay: 1s;
	opacity: 0;
}

@keyframes spin {
	0% {
		opacity: 1;
		transform: rotate(0deg);
	}
	100% {
		opacity: 1;
		transform: rotate(360deg);
	}
}
