@import '/src/constants/global';

.container {
	@include flex;
	align-items: center;
	justify-content: space-between;
	padding: 1.4rem;
}

.datePickerFooter {
	@include flex;
	gap: 1.4rem;
}

.plusYearButton {
	@extend .fontSize14;
	color: $neutral700;
	border-color: $neutral200;
}

.confirmButtonsFooter {
	@include flex;
	padding: 1.4rem;
	gap: 2.4rem;
	justify-content: center;
}

.confirmButtons {
	width: 13.5rem;
	height: 4.8rem;
}

.doneButtonContainer {
	@include flex;
	width: 100%;
	padding: 1.4rem;
	justify-content: flex-end;
}

.doneButton {
	width: fit-content;
}
