@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.listItem {
	@include alignItemsCenter;
	@include overflowHiddenEllipsisNowrap;
	@extend .fontSize14;
	@extend .fontWeight250;
	justify-content: space-between;
	margin: 0 2.4rem;
	padding-left: 1.4rem;
	cursor: pointer;
	color: $neutral;
	height: 4rem;
	border-left: 1px solid $neutral200;
	span {
		@include overflowHiddenEllipsisNowrap;
		max-width: 18rem;
	}
	&:hover {
		color: $neutral800;
		.menuContainer {
			display: flex;
		}
		.count {
			display: none;
		}
	}
	&:focus-within {
		border: 1px solid $success400;
		background-color: $neutral200;
		transition: 0.4s;

		input {
			border: none;
			color: $success;
			@extend .fontSize14;
		}
	}
}

.count {
	@include flex;
	@extend .fontSize12;
	align-items: center;
	height: 2rem;
	padding: 0 0.8rem;
	background-color: $neutral100;
	color: $neutral700;
}

.selectedItem {
	@extend .fontWeight650;
	color: $black;
	border-left: 2px solid $black;
	.menuContainer {
		display: flex;
		.isOpen {
			background-color: $neutral100;
		}
	}
	.count {
		display: none;
	}
}

.kebab {
	transform: rotate(90deg);
}

.menuContainer {
	width: 2rem;
	@include flexCenter;
	margin-right: 0.5rem;
	display: none;
}

.nameContainer {
	@include flex;
}

.nameInput {
	border: none;
	padding: 0;
}

.isOpen {
	transform: rotate(180deg);
	transition: 0.4s;
	display: flex;
}

.isClosed {
	transform: rotate(90deg);
	transition: 0.4s;
}

.alwaysVisible {
	display: flex !important;
}
