@import '../../../constants/colors.scss';

.horizontalStepperContainer {
	display: flex;
}

.horizontalStepperContainer::before {
	height: 0.2rem;
	background-color: $neutral200;
	position: absolute;
	top: 50%;
	left: 1.8rem;
	width: 92%;
	transform: translateY(-50%);
}

.timeline {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 1rem;
}
