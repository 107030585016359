@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.data {
	@include flex;
	gap: 1.4rem;
}

.docsCount {
	width: 3.6rem;
	height: 3.6rem;
	border-radius: 3.6rem;
	border: 1px solid $neutral200;
	@include flexCenter;
	@extend .fontSize12;
	@extend .fontWeight450;
	color: $neutral;
	gap: 1.4rem;
}

.mainTitle {
	@extend .fontSize14;
	@extend .fontWeight650;
	color: $neutral900;
}

.subTitle {
	@extend .fontSize12;
	@extend .fontWeight450;
	color: $neutral;
}

.dot {
	&::before {
		content: '\00a0';
	}
	&::after {
		content: '\00a0';
	}

	font-size: 0.8rem;
	@extend .fontWeight450;
	color: $neutral300;
}
