@import 'src/constants/colors';
@import 'src/constants/global';
@import 'src/constants/mixins';

.select {
	max-width: 25rem;
}

.selectDropdownContainer {
	height: auto;
}
