@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.divider {
	background-color: $neutral200;
	height: 5rem;
	margin: 1.5rem 3.4rem 0 3.4rem;
	width: 1px;
}

.topSection {
	@include flex;
	width: 100%;
	gap: 2.4rem;
}

.bottomSection {
	@include flex;
	width: 100%;
	gap: 2.4rem;
	padding: 2.4rem 0;
	align-items: flex-end;
}

.cardTitles {
	@extend .fontSize12;
	@extend .fontWeight250;
	color: $neutral;
}

.topSectionCardQuantity {
	@extend .fontSize32;
	@extend .fontWeight250;
	color: $neutral800;
}

.bottomSectionCardQuantity {
	@extend .fontSize20;
	@extend .fontWeight250;
	color: $neutral800;
}

.destructiveLabel {
	color: $destructive;
	&::before {
		content: '+';
	}
}

.bottomRightSection {
	@include flex;
	gap: 4.4rem;
}

.cardContainer {
	margin-right: 6.8rem;
}
