@import '../../constants/colors.scss';
@import '../../constants/global.scss';
@import '../../constants/mixins.scss';

.modalContainer {
	width: 73.2rem;
	max-height: calc(100vh - 10rem);
}

.dataContainer {
	border-top: 1px solid $neutral200;
	min-height: 20rem;
	height: calc(100vh - 50rem);
	overflow: scroll;
	padding: 2.4rem;
}

.subTitle {
	color: $neutral600;
}

.header {
	@include flexCenter;
	padding: 2.4rem;
	justify-content: space-between;
	height: 14rem;
}

.stepperContainer {
	padding: 2.4rem;
}

.iconContainer {
	@include flexCenter;
	@include borderNeutral;
	background-color: $neutral50;
	width: 7.8rem;
	height: 7.8rem;
	border-radius: 0.4rem;
	margin-right: 2.4rem;
}

.cardIcon {
	width: 6rem;
	height: auto;
}

.footer {
	@include flexCenter;
	padding: 2.4rem;
	gap: 2.4rem;
	border-top: 1px solid $neutral200;
	height: 10rem;
}

.confirmButtons {
	width: 50%;
	height: 4.8rem;
}
