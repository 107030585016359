@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.modalContainer {
	width: 43rem;
	padding: 2.4rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	box-shadow: 18px 15px 85px 0px rgba(17, 24, 39, 0.75);
	-webkit-box-shadow: 18px 15px 85px 0px rgba(17, 24, 39, 0.75);
	-moz-box-shadow: 18px 15px 85px 0px rgba(17, 24, 39, 0.75);
	z-index: 95;
}

.modalOverlay {
	z-index: 94;
}

.icon {
	width: 16.7rem;
	margin: 2.5rem 0;
}

.title {
	@extend .fontSize32;
	@extend .fontWeight850;
	margin-bottom: 2.4rem;
}

.input {
	width: 36.3rem;
	margin-bottom: 2.4rem;
}

.footerButtons {
	width: 36.3rem;
	height: 4.8rem;
}
