@import 'src/constants/colors';
@import 'src/constants/mixins';
@import 'src/constants/global';

.container {
	@extend .minScreenSize;
	@extend .appearAnimation;
	@include flex;
	position: relative;
	width: 100%;
	min-width: 1300px;
}

.rightSection {
	width: calc(100% - 32.2rem);
	overflow: scroll;
}

.headerButtons {
	@include flex;
	justify-content: space-between;
	padding: 2.4rem;
	height: 7.8rem;
}

.tabComponent {
	width: 12rem;
}

.actionButtons {
	@include flexCenter;
	gap: 1.4rem;
}

.bottomContainer {
	@include flexColumn;
	height: calc(100% - 7.8rem);
}

.tooltipClassName {
	bottom: -5.5rem !important;
}
