@import 'src/constants/colors';
@import 'src/constants/mixins';

.container {
	display: flex;
	transition: 200ms;
	height: max-content;
}

.innerBorders {
	width: 50%;
	border: none;
	border-right: 1px solid $neutral200;
	border-radius: 0;
}

.notEditable {
	@include notEditable;
}
