@import 'src/constants/colors';
@import 'src/constants/global';

.container {
	@include flexColumnCenter;
}

.button {
	height: 27.7rem;
	width: 34.1rem;
	@include defaultBoxShadow;
	align-items: flex-start;
	padding: 3rem;
	&:hover {
		@include borderPrimary;
	}
}

.emptyStateCard {
	background-color: $white;
	@include flexColumnCenter;
}

.header {
	@extend .fontSize20;
	@extend .fontWeight650;
}

.headerSubTitle {
	@extend .fontWeight250;
	color: $neutral;
	margin-bottom: 2.4rem;
}

.title {
	color: $neutral900;
}

.subTitle {
	@extend .fontSize14neutral;
	@extend .fontWeight250;
}

.buttonsContainer {
	@include flexCenter;
	gap: 2.4rem;
	height: 28rem;
}

.download {
	color: $primary;
	cursor: pointer;
	animation: highlight 2s linear infinite;
}

@keyframes highlight {
	0% {
		border: 1px solid $white;
	}
	50% {
		background-color: $primary100;
		border: 1px solid $primary200;
	}
	100% {
		border: 1px solid $white;
	}
}

.download:hover {
	color: $primary700;
}

.imgStyle {
	min-height: 15rem;
}

.buttonDisabled {
	pointer-events: none;
	opacity: 0.5;
}
