@import '../../../constants/colors.scss';
@import '../../../constants/global.scss';

.customersContainer {
	@extend .minScreenSize;
	@extend .appearAnimation;
}

.header {
	padding: 0rem 2.4rem;
}

.customersHeader {
	display: flex;
	justify-content: space-between;
	margin-bottom: 2.4rem;
	align-items: center;
	padding-top: 3.4rem;
}

.buttonsContainer {
	display: flex;
	height: 4rem;
	gap: 0.8rem;
}

.customerSearch {
	width: 19.2rem;
}

.description {
	@extend .fontWeight250;
	color: $neutral400;
}

.customerButtons {
	@include flexCenter;
	align-items: center;
}

.customerOptionButton {
	@include flexCenter;
	width: 3rem;
}

.customerOptionButton:hover {
	background-color: $success300;
}

.tableContainer {
	height: calc(100% - 21.8rem);
	@extend .appearAnimation;
}

.emptyStateContainer {
	height: 100%;
	width: 100%;
}

.tableRowClassName {
	@extend .fontWeight250;
}

.extendedTableRowClassName {
	@extend .fontWeight250;
	display: flex;
	justify-content: space-between;
	height: 4.5rem;
}

.extendedTableCellClassName {
	display: flex;
	width: 24rem;
	&:nth-child(2) {
		width: 8rem;
	}
	&:nth-child(3) {
		width: 12rem;
	}
	&:nth-child(4) {
		width: 12rem;
	}
	&:nth-child(5) {
		width: 12rem;
	}
}

.badges {
	@include flex;
	gap: 0.4rem;
	height: 2rem;
}

.createContract {
	height: 2rem;
	width: fit-content;
}

.name {
	@extend .fontWeight650;
	color: $destructive;
}

.customerOptionArrowUp {
	transform: rotate(180deg);
	transition: 400ms;
}

.customerOptionArrowDown {
	transition: 400ms;
}

.cardText {
	margin-left: 0.2rem;
	@extend .fontSize12;
	@extend .fontWeight450;
}

.cardsContainer {
	@include flex;
	gap: 1.4rem;
	margin-bottom: 2.4rem;
}

.addIcon {
	margin-bottom: 0.2rem;
}
