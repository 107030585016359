@import 'src/constants/colors';
@import 'src/constants/mixins';
@import 'src/constants/global';

.container {
	@include flexColumn;
	height: 100%;
	width: 100%;
}

.tabContainer {
	@include horizontalDivider;
	display: flex;
	align-items: center;
}

.addModelButton {
	background-color: $neutral100;
	width: 2rem;
	height: 2rem;
	margin-left: 2rem;
	&:hover {
		background-color: $neutral200;
	}
}

.tabComponent {
	@extend .fontSize14;
	@extend .fontWeight250;
	padding: 0.8rem 2.4rem 0 2.4rem;

	&:hover {
		background-color: $neutral50;
	}

	border-image: linear-gradient(0deg, $neutral200 0%, rgba(229, 231, 235, 0)) 0 10 0 0;
	border-width: 1px;
	border-style: solid;
	width: fit-content;
	max-width: 16.5rem;
	max-height: 20rem;
}

.tabLabels {
	@extend .fontWeight250;
	padding-bottom: 0.8rem;
	border-bottom: 2px solid transparent;
	max-width: 10rem;
}

.tabActiveLabels {
	border-bottom: 2px solid $success;
}

.tabTitleLabel {
	color: $neutral400;
	text-transform: uppercase;
	@include overflowHiddenEllipsisNowrap;
}

.activeTabLabel {
	color: $success;
}

.label {
	max-width: 10rem;
	@include overflowHiddenEllipsisNowrap;
	color: $neutral;
}

.activeLabel {
	color: $neutral900;
}

.tabsContainer {
	border: none;
	overflow-x: scroll;
	max-width: 95%;
}
