@import '../../../../constants/colors.scss';
@import '../../../../constants/mixins.scss';
@import '../../../../constants/global.scss';

.container {
	height: 100%;
	padding: 0 2rem;
}

.subTitle {
	display: block;
	text-transform: uppercase;
	@extend .fontSize12;
	@extend .fontWeight650;
}

.productCount {
	padding: 0 0.5rem;
	border-radius: 0.3rem;
	border: 1px solid $neutral300;
	@extend .fontSize12;
	@extend .fontWeight450;
}

.grayText {
	@extend .fontSize14;
	color: $neutral400;
}

.footer {
	@include horizontalDivider;
	padding-bottom: 2.4rem;
	padding: 2.4rem;
}

.dataContainer {
	height: calc(100% - 10rem);
	padding: 2.4rem 0;
	overflow: scroll;
	@include flexColumn;
	gap: 1.4rem;
}

.headerOverviewContainer {
	@include flexCenter;
	justify-content: flex-start;
	gap: 0.4rem;
}

.capitalize::first-letter {
	text-transform: capitalize;
}

.cards {
	@include flexColumn;
	gap: 1.4rem;
}
