@import '../../../constants/colors.scss';
@import '../../../constants/mixins.scss';

.tagsRow {
	display: flex;
	height: 4rem;
	gap: 1rem;
}

.filterTag {
	display: flex;
	align-items: center;
	padding: 0.6rem;
	background-color: $neutral100;
	border-radius: 0.6rem;
	color: $neutral700;
	justify-content: center;
	border: 1px solid $neutral200;
	width: max-content;
}

.xButton {
	height: 1.3rem;
	padding: 0;
	margin: 0.3rem;
}
