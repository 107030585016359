@import '../../../../../constants/colors.scss';
@import '../../../../../constants/mixins.scss';
@import '../../../../../constants/global.scss';

.container {
	@include flex;
	background-color: $white;
	height: 100%;
	width: 100%;
}

.leftSection {
	width: 37.2rem;
	height: 100%;
	border: 1px solid $neutral200;
	z-index: 2;
}

.rightSection {
	height: 100%;
	width: calc(100% - 37.2rem);
}

.header {
	@include alignItemsCenter;
	padding: 2.4rem;
	height: 12.9rem;
	border-color: $neutral200;
}

.searchBar {
	border-radius: 0;
	border-right: 0;
	border-left: 0;
}

.listContainer {
	overflow: scroll;
	height: (calc(100% - 18rem));
	overflow: scroll;
}
