@import '../../../constants/colors.scss';
@import '../../../constants/global.scss';

.modalContainer {
	width: 47.2rem;
	height: 47.5rem;
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 2.4rem;
}

.icon {
	height: 19rem;
	width: 19rem;
	margin-bottom: 2.8rem;
}

.title {
	display: block;
}

.text {
	@extend .fontWeight250;
	text-align: center;
	color: $neutral;
	line-height: 2.4rem;
}

.buttonContainer {
	display: flex;
	width: 100%;
	justify-content: space-between;
	padding-top: 2.4rem;
}

.button {
	width: 20rem;
	height: 4.8rem;
}
