@import 'src/constants/colors';
@import 'src/constants/mixins';
@import 'src/constants/global';

.mainContainer {
	@extend .minScreenSize;
	@extend .appearAnimation;
	@include flexColumn;
	min-height: 700px;
	background-color: $white;
}

.pageHeader {
	display: flex;
	height: 14.4rem;
	justify-content: space-between;
	align-items: center;
	padding: 2.4rem;
	border-bottom: 1px solid $neutral200;
}

.contentContainer {
	display: block !important;
	background-color: $white;
	height: calc(100% - 14.6rem) !important;
	overflow: scroll;
}

.buttonsContainer {
	display: flex;
}

.goBack {
	@extend .fontSize14;
	width: 10.5rem;
	padding: 0;
	margin-bottom: 1.4rem;
	color: $neutral;
}

.publishButton {
	padding: 1.4rem;
}

.headerButtons {
	display: flex;
	height: 4.8rem;
	width: 15.5rem;
	margin-left: 1.4rem;
}

.modelNameContainer {
	@include overflowHiddenEllipsisNowrap;
	@include flex;
	align-items: center;
	gap: 0.8rem;
	cursor: pointer;
	border-bottom: 1px solid transparent;
	height: 4.4rem;
	padding: 0;
	margin: 0;
	max-width: 90rem;
}

.editModelNameContainer {
	border: none;
	box-shadow: none;
	border-radius: 0;
	padding: 0;
	margin: 0;
	min-width: 75rem;
	border-bottom: 1px solid $success;
	transition: 400ms;
	height: 4.4rem;
}

.modelName {
	@include overflowHiddenEllipsisNowrap;
	@extend .fontSize24;
	@extend .fontWeight650;
	color: $neutral900;
	text-align: left;
	padding: 0;
	margin: 0;
}

.selectTag {
	@include flexCenter;
	height: 2.4rem;
	border: 1px solid $black;
	width: fit-content;
	padding: 0 0.8rem;
}

.productName {
	@extend .selectTag;
	@extend .fontSize12;
}

.productNameInput {
	@extend .fontSize12;
	padding-bottom: 0;
	height: 2rem;
	&::placeholder {
		@extend .fontSize12;
		text-transform: none;
	}
}

.hamburgerButton {
	width: 4rem;
	@include flexCenter;
	margin-left: 1.4rem;
}

.titleContainer {
	@include flex;
	gap: 1.4rem;
}

.iconContainer {
	@include borderNeutral;
	@include flexCenter;
	height: 6rem;
	width: 6rem;
	border-radius: 0.4rem;
}
