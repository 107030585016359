@import 'src/constants/colors';
@import 'src/constants/mixins';
@import 'src/constants/global';

.container {
	@include flex;
	flex-direction: column;
	padding: 2.4rem;
	border: 1px solid $neutral200;
	background: $white;
}

.content {
	@include flexColumn;
	margin-top: 1rem;
	transition: 200ms;
	@extend .appearAnimation;
	gap: 1.4rem;
	flex-wrap: wrap;
}

.noteTitle {
	@extend .fontSize14;
	color: $neutral600;
}

.multiline {
	resize: none;
	width: 100%;
	height: 9.3rem;
	overflow-y: scroll;
}

.ellipsis {
	@include overflowHiddenEllipsisNowrap;
	max-width: 95%;
}

.currencySelect {
	padding: 0 1.3rem 0 0.6rem;
	margin-bottom: 1.4rem;
}

.title {
	@extend .fontSize18;
	@extend .fontWeight250;
	margin-bottom: 1.4rem;
}

.supplierContainer {
	@extend .flex;
	align-items: center;
	width: 100%;
	gap: 1.4rem;
}

.supplierSelect {
	padding: 0 1.3rem 0 0.6rem;
}

.supplierContainerSelect {
	width: inherit;
	min-width: 20rem;
}

.selectHeaderStyle {
	color: $neutral600;
}

.iconContainer {
	height: 4.8rem;
	width: 4.8rem;
	@include borderNeutral;
	@include flexCenter;
	box-shadow: 0 0.1rem 0.2rem rgba(16, 24, 40, 0.05);
	border-radius: 0.6rem;
	cursor: pointer;
	align-self: flex-end;
}

.iconContainer:active {
	opacity: 70%;
	transform: translateY(0.1rem) translateX(0.1rem);
}

.iconContainer:hover {
	background-color: $primary50;
	border: 1px solid $primary300;
}
