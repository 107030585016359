@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.container {
	@include flex;
	height: 6.8rem;
	width: 100%;
	gap: 2.4rem;
}

.avatarContainer {
	width: 6.8rem;
	height: 6.8rem;
	@include flexCenter;
	overflow: hidden;
}

.avatarContainerWithIcon {
	width: 100%;
	max-width: 3.5rem;
	height: auto;
}

.avatarLetter {
	@extend .fontSize30;
	@include flexCenter;
	height: 100%;
	width: 100%;
	background-color: $neutral50;
	color: $neutral400;
	border: 1px solid $neutral200;
}

.content {
	@include flexColumn;
	align-items: baseline;
	transition: 350ms;
}

.inputContainerClassName {
	width: 25rem;
	color: $neutral900;
	padding: 0 !important;
	border: none !important;
	box-shadow: none !important;
	border-radius: 0 !important;
	border-bottom: 1px solid $primary !important;
	height: 3rem !important;
	margin-top: 0.3rem;
}

.documentId {
	@extend .fontSize20;
	@extend .fontWeight650;
	color: $neutral900;
	padding: 0 !important;
	&::placeholder {
		font-size: 2rem !important;
	}
}

.title {
	@extend .fontSize20;
	@extend .fontWeight650;
	color: $neutral800;
	@include overflowHiddenEllipsisNowrap;
	max-width: 25rem;
}

.customTitle {
	@extend .fontSize14;
	@extend .fontWeight250;
	color: $neutral;
	@include overflowHiddenEllipsisNowrap;
	max-width: 20rem;
}

.contractConnectionStatus {
	position: absolute;
	height: 2.5rem;
	top: 7.5rem;
	left: 7.5rem;
	border-radius: 1rem;
}

.badge {
	padding: 0;
	min-width: max-content !important;
	border: none;
}

.badgeText {
	text-transform: uppercase;
	@extend .fontSize12;
	@extend .fontWeight650;
	color: $neutral;
}

.body {
	@include flexColumn;
	justify-content: space-around;
	align-items: baseline;
	transition: 350ms;
}

.prefix {
	@extend .fontSize24;
	@extend .fontWeight850;
	color: $neutral900;
	margin-top: 0.2rem;
}

.inputContainer {
	@include flexCenter;
	height: 2rem;
}

.titleContainer {
	height: 3.2rem;
	@include flexCenter;
}

.warningIcon {
	margin: 0rem 0.5rem 0 0;
}

.warningIconContainer {
	border: 1px solid $warning;
	border-radius: 0.3rem;
	height: 2rem;
	width: 2rem;
	@include alignItemsCenter;
	margin-right: 0.7rem;
}

.errorBorder {
	border-bottom: 1px solid $destructive !important;
}

.warningText {
	@extend .fontSize12;
	@extend .fontWeight250;
	@include overflowHiddenEllipsisNowrap;
	margin-top: 0.2rem;
	max-width: 40rem;
	color: $warning600;
}

.errorText {
	color: $destructive;
}

.appear {
	visibility: visible;
	transition: 350ms;
}

.disappear {
	height: 0;
	visibility: hidden;
	transition: 350ms;
}

.saveButton {
	margin-left: 2.4rem;
	padding: 0.8rem;
}
