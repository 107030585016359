@import '../../../../constants/colors.scss';

.glowingDot {
	position: relative;
	height: 1rem;
	width: 1rem;
	border-radius: 0.3rem;
}

.innerGlowingDot {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translateX(-50%) translateY(-50%);
	height: 0.5rem;
	width: 0.5rem;
	&:before {
		content: '';
		position: relative;
		display: block;
		width: 200%;
		height: 200%;
		box-sizing: border-box;
		margin-left: -50%;
		margin-top: -50%;
		border-radius: 45px;
		background-color: $primary;
		animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
	}

	&:after {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		display: block;
		width: 100%;
		height: 100%;
		background-color: $primary;
		border-radius: 15px;
		box-shadow: 0 0 8px $primary;
		animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
	}
}

@keyframes pulse-ring {
	0% {
		transform: scale(0.33);
	}
	100% {
		opacity: 0;
	}
}

@keyframes pulse-dot {
	0% {
		transform: scale(0.8);
	}
	50% {
		transform: scale(1);
	}
	100% {
		transform: scale(0.8);
	}
}
