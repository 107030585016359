$black: #151515;
$white: #fff;
$sideBarBlue: #4c6896;
$sideBarBackground: #f3f4f6;
$backgroundColor: $white;

// Neutral - gray
$neutral: #6b7280;
$neutral50: #f9fafb;
$neutral100: #f3f4f6;
$neutral200: #e5e7eb;
$neutral300: #d1d5db;
$neutral400: #9ca3af;
$neutral600: #4b5563;
$neutral700: #374151;
$neutral800: #1f2937;
$neutral900: #111827;

// Primary - blue
$primary: #168fff;
$primary50: #f8fbff;
$primary100: #e7f3ff;
$primary200: #c6e3ff;
$primary300: #93cbff;
$primary400: #60b2ff;
$primary600: #0e7fe7;
$primary700: #056dcd;
$primary800: #045bab;
$primary900: #024a8b;

// Success - green
$success: #22c55e;
$success50: #f0fdf4;
$success100: #dcfce7;
$success200: #bbf7d0;
$success300: #86efac;
$success400: #4ade80;
$success600: #16a34a;
$success700: #15803d;
$success800: #166534;
$success900: #14532d;

// Warning - yellow
$warning: #f59e0b;
$warning50: #fffbeb;
$warning100: #fef3c7;
$warning200: #fde68a;
$warning300: #fcd34d;
$warning400: #fbbf24;
$warning600: #d97706;
$warning700: #b45309;
$warning800: #92400e;
$warning900: #78350f;

// Destructive - red
$destructive: #ef4444;
$destructive50: #fef2f2;
$destructive100: #fee2e2;
$destructive200: #fecaca;
$destructive300: #fca5a5;
$destructive400: #f87171;
$destructive600: #dc2626;
$destructive700: #b91c1c;
$destructive800: #991b1b;
$destructive900: #7f1d1d;

// Calming - purple
$calming: #7d44ef;
$calming50: #f6f2fe;
$calming100: #ebe2fe;
$calming200: #dbcafe;
$calming300: #c2a5fc;
$calming400: #9e71f8;
$calming600: #6326dc;
$calming700: #501cb9;
$calming800: #451b99;
$calming900: #3e1d7f;

//orange
$orange: #ffa25f;

//blueSky
$blueSky: #5fc5ff;

//yellow
$eveningStar: #ffd25f;

//Pink Delight
$pinkDelight: #ff89e5;

//oceanInBowl
$oceanInBowl: #58e1b0;
