@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.selectOverlay {
	@extend .appearAnimation;
	animation-duration: 0.2s;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 50;
	background-color: rgba(17, 24, 39, 0.05);
}

.selectDropdownContainer {
	@extend .appearAnimation;
	@include borderNeutral;
	animation-duration: 0.2s;
	box-shadow: -10px 8px 40px -6px rgba(16, 24, 40, 0.03), 0px 20px 25px -5px rgba(16, 24, 40, 0.03);
	height: 40.2rem;
	width: 34.4rem;
	border-radius: 1rem;
	z-index: 91;
	background-color: $white;
	overflow: hidden;
}

.selectInputContainer {
	opacity: 1 !important;
	margin-bottom: 1.4rem;
	background-color: $white;
	color: $neutral;
}

.selectInput {
	color: $neutral;
}

.title {
	@extend .fontSize20;
}

.selectHeader {
	@include flexRowSpaceBetween;
	padding: 1.4rem 1.4rem 0 1.4rem;
}

.selectContainer {
	height: calc(100% - 10rem);
	overflow: scroll;
}

.noItemsFound {
	@extend .fontSize14;
	padding: 1.4rem;
	color: $neutral;
}

.selectItem {
	@extend .appearAnimation;
	@include flexRowSpaceBetween;
	@include horizontalDivider;
	@extend .fontSize14;
	@extend .fontWeight250;
	cursor: pointer;
	align-items: center;
	color: $neutral;
	width: calc(100% - 2.8rem);
	height: 4.8rem;
	transition: 400ms;
	margin: 0 1.4rem;

	&:hover {
		background-color: $primary50;
	}
	&:active {
		background-color: $primary100;
	}
}

.selectedItem {
	color: $primary;
}

.itemIcon {
	margin-right: 1.4rem;
}

.searchBar {
	@extend .appearAnimation;
	border-top: none !important;
	border-left: none !important;
	border-right: none !important;
	border-radius: 0 !important;
	border-bottom: 1px solid $neutral200 !important;
	border-color: $neutral200 !important;
	box-shadow: none;
}

.customFormulaContainer {
	padding: 2.4rem;
	height: 20rem;
}

.customFormulaInput {
	@include borderNeutral;
	@extend .fontSize14;
	border-radius: 0.6rem;
	color: $neutral600;
	padding: 1.4rem;
	min-height: 20.4rem;
	background-color: $neutral50;
	resize: vertical;
	max-height: 20rem;
	overflow: scroll;
}

.saveButton {
	padding-right: 0;
}
