@import 'src/constants/mixins';
@import 'src/constants/colors';
@import 'src/constants/global';

.pageContainer {
	@extend .appearAnimation;
	display: flex;
	flex-direction: column;
	height: 100%;
}

.pageHeader {
	padding: 2.4rem;
}

.horizontalDivider {
	@include horizontalDivider;
}

.pageContent {
	overflow: scroll;
	display: flex;
	flex-wrap: wrap;
	padding: 2.4rem;
	gap: 2.4rem;
}

.footerContainer {
	margin-top: auto;
}

.footer {
	@extend .fontSize14;
	display: flex;
	height: 6rem;
	background-color: $primary50;
	margin: 2.4rem;
	align-items: center;
	padding: 1.2rem;
	gap: 1.6rem;
	color: $primary700;
}

.tellButton {
	margin-left: auto;
}
