@import '../../../constants/colors.scss';
@import '../../../constants/mixins.scss';
@import '../../../constants/global.scss';

.indicatorContainer {
	width: 100%;
	height: 0.6rem;
	min-height: 0.6rem;
	background-color: $neutral100;
}

.indicator {
	background-color: $primary;
	height: 100%;
	width: 0;
	max-width: 100%;
	border-radius: 5rem;
	transition: 500ms;
}
