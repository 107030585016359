@import '../../../../constants/colors.scss';
@import '../../../../constants/mixins.scss';
@import '../../../../constants/global.scss';

.container {
	@include horizontalDivider;
	@include flexCenter;
	@extend .appearAnimation;
	height: 9.4rem;
	width: 100%;
	justify-content: space-between;
}

.iconContainer {
	@include flexCenter;
	width: 5.2rem;
	height: 6.2rem;
	background-color: $primary50;
	border-radius: 0.5rem;
	margin-right: 1.4rem;
}

.text {
	@extend .fontWeight250;
	text-align: left;
}

.delete {
	width: 3.2rem;
	height: 3.2rem;
	border-radius: 2rem;
}

.fileName {
	@include overflowHiddenEllipsisNowrap;
	max-width: 35rem;
}
