@import 'src/constants/colors';
@import 'src/constants/global';
@import 'src/constants/mixins';

.container {
	height: 73rem;
	width: 66.2rem;
	max-height: calc(100vh - 5rem);
}

.titleContainer {
	padding: 2.4rem 2.4rem 0;
	@include borderNeutral;
	@include flex;
	justify-content: space-between;
}

.title {
	@extend .fontSize20;
	@extend .fontWeight650;
	padding-left: 1rem;
	padding-bottom: 1.6rem;
}

.x_icon {
	height: 1rem;
}

.tabsContainer {
	border: 'none';
}

.details {
	@include flexColumn;
}

.button {
	align-self: flex-start;
}
