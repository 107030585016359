@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.container {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	padding-left: 2.4rem;
	cursor: pointer;
}

.container:hover {
	border: 1px solid $calming300;
}

.numberText {
	@extend .fontSize14;
	@extend .fontWeight650;
	color: $calming;
	width: 100%;
	@include flexColumn;
	padding-top: 0.5rem;
}

.label {
	@extend .fontSize12;
	@extend .fontWeight450;
	color: $neutral400;
}

.diamond {
	@extend .fontSize20;
	@extend .fontWeight650;
}

.valueContainer {
	@include flex;
	align-items: center;
	gap: 0.5rem;
}
