@import '../../../constants/colors.scss';
@import '../../../constants/mixins.scss';
@import '../../../constants/global.scss';

.mainContainer {
	@include defaultBoxShadow;
	height: 100%;
	width: 100%;
	overflow: hidden;
}

.tableHeader {
	display: flex;
	justify-content: space-between;
	padding: 2.4rem 2.4rem 1.4rem 2.4rem;
}

.tableTitle {
	@extend .fontSize20;
	display: block;
}

.tableSubTitle {
	@extend .fontSize12;
	color: $neutral400;
	display: block;
}

.tableFilters {
	display: flex;
}

.rowsContainer {
	height: calc(100% - 4.6rem);
	box-sizing: border-box;
	overflow: scroll;
}

.totalRow {
	height: 7.2rem;
	background-color: $neutral50;
	border-top: 1px solid $neutral200;
}

@keyframes fade {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.fadeIn {
	animation: fade 0.4s linear 1;
}

.fadeOut {
	@extend .appearAnimation;
}

.button {
	width: 24rem;
	margin-top: 1.4rem;
}

.invoiceTableEmpty {
	height: 100%;
}
