@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.container {
	border: 1px solid $neutral200;
}

.column {
	@include flexColumn;
	flex: 1;
}

.linearGradientLine {
	border-image: linear-gradient($white, $neutral200, $white) 0 10 0 0;
	border-width: 1px;
	border-style: solid;
}

.leftLinearGradientBorder {
	&:first-child {
		border-image: linear-gradient(to right, $white, $neutral200) 0 0 10 0;
		border-width: 1px;
		border-style: solid;

		&:hover {
			border-image: none !important;
			border-width: 1px !important;
			border-style: solid !important;
			cursor: pointer;
		}
	}

	&:nth-child(2) {
		border: none;
	}

	&:hover {
		border-width: 1px !important;
		border-style: solid !important;
		cursor: pointer;
	}
}

.rightLinearGradientBorder {
	&:first-child {
		border-image: linear-gradient(to right, $neutral200, $white) 0 0 10 0;
		border-width: 1px;
		border-style: solid;
	}
	&:nth-child(2) {
		border: none;
	}

	&:hover {
		border-image: none !important;
		border-width: 1px !important;
		border-style: solid !important;
		cursor: pointer;
	}
}
