@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 1.4rem;
}

.statusContainer {
	@extend .fontSize12;
	@extend .fontWeight650;
	height: 4rem;
	padding: 1rem;
	border-radius: 5rem;
	background-color: $neutral50;
	display: flex;
	align-items: center;
	color: $neutral;
	border: 1px solid $neutral300;
	pointer-events: none;
}

.dashedBorder {
	height: 3rem;
	width: 1px;
	background-color: $white !important;
	border-left: 1px dashed $neutral300;
}

.dot {
	height: 5px;
	width: 5px;
	border-radius: 3px;
	margin: 3px;
	background-color: $neutral300;
}

.MANUAL {
	color: $white;
	background-color: $success;
	border-color: $success;
}

.API {
	color: $white;
	background-color: $success;
	border-color: $success;
}

.NOT_CONNECTED {
	color: $white;
	background-color: $warning;
	border-color: $warning;
}

.icon {
	height: 14px;
	padding-left: 0.7rem;
}
