@import 'src/constants/colors.scss';
@import 'src/constants/global';
@import 'src/constants/mixins';

.inputContainer {
	@include horizontalDivider;
	height: 4rem;
	color: $neutral;
	padding: 1rem;
	display: flex;
	align-items: center;
}

.fullBorder {
	@include borderNeutral;
}

.input {
	@extend .globalInput;
	width: 100%;
}

.input:disabled {
	color: $neutral700;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px $white inset !important;
}

.inputContainer:hover {
	border-color: $success300;
}

.inputContainer:focus {
	outline: none;
	border: 1px solid $success;
	@include primary200BoxShadow;
}

.errorContainer {
	border-color: $destructive;
}

.errorContainer:hover {
	border-color: $destructive;
}

.formTitle {
	@extend .fontSize14;
	display: block;
	padding-bottom: 0.4rem;
	color: $neutral600;
}

.header {
	@include flex;
	justify-content: space-between;
}
