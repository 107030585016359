.container {
	position: relative;
}

.totalText {
	position: absolute;
	font-size: 200%;
	line-height: 4.8rem;
	right: 50%;
	bottom: 50%;
	transform: translate(50%, 100%);
}
