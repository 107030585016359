@import 'src/constants/colors.scss';
@import 'src/constants/global.scss';
@import 'src/constants/mixins.scss';

.modalContainer {
	height: calc(100% - 15rem);
	min-height: 40rem;
	width: 47.2rem;
}

.emptyContainer {
	@include flexCenter;
	height: 80%;
	padding: 5rem;
}

.header {
	@include horizontalDivider;
	@include flexCenter;
	justify-content: space-between;
	height: 12.6rem;
	padding: 2.4rem;
}

.iconContainer {
	@include flexCenter;
	@include borderNeutral;
	background-color: $neutral50;
	border-radius: 0.5rem;
	width: 7.2rem;
	height: 7.2rem;
	margin-right: 1.4rem;
}

.emptyIntegrationButton {
	@extend .flexCenter;
	margin-top: 2.4rem;
	height: 4rem;
}

.imgContainerStyle {
	width: 14.8rem;
}

.cardIcon {
	width: 6.3rem;
	height: 6.3rem;
}

.cardDescription {
	@extend .fontWeight250;
	max-width: 28rem;
	color: $neutral;
}

.content {
	@include flexColumn;
	height: calc(100% - 22rem);
	padding: 1.4rem 2.4rem;
	gap: 1.4rem;
	overflow: scroll;
}

.contentHeader {
	@extend .flexCenter;
	justify-content: space-between;
}

.footer {
	border: 1px solid $neutral200;
	height: 9.6;
	padding: 2.4rem;
}

.footerButton {
	height: 4.8rem;
	width: 100%;
}

.newEntityModel {
	background-color: rgba(17, 24, 39, 0.4);
	backdrop-filter: blur(7px);
	z-index: 50;
}