@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.usageContainer {
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	cursor: pointer;
}

.dataContainer {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	overflow: hidden;
	padding-left: 2.4rem;
	border: 1px solid transparent;
}

.numberText {
	@extend .fontSize14;
	@extend .fontWeight250;
	border: none;
	color: $calming200;
	width: 100%;
	display: flex;
	padding: 0.5rem 0 0 0.2rem;
}

.dataContainer:hover {
	border: 1px solid $calming300;
	transition: 300ms;
}

.isEditable {
	background-color: $calming50;
	color: $calming;
	transition: 300ms;
}

.editMode {
	border: 1px solid $calming400;
	background-color: $calming50;
	transition: 300ms;
}

.editMode:hover {
	border: 1px solid $calming400;
	background-color: $calming50;
	transition: 300ms;
}

.usageType {
	color: $calming;
	@extend .fontSize12;
	@extend .fontWeight450;
	line-height: 1.5rem;
	text-transform: uppercase;
	@include flexColumn;
	gap: 0.5rem;
}

.dashboardIcon {
	height: 10px;
	transform: rotate(45deg);
	margin-right: 0.3rem;
}

.hamburgerButton {
	margin: 0 1rem;
	padding: 0;
	display: none;
}

.hamburger {
	height: 15px;
}

.usageContainer:hover .hamburgerButton {
	position: absolute;
	right: 0;
	display: flex;
	height: 100%;
}

.usageTypeLabel {
	@include flexCenter;
	justify-content: flex-start;
	@extend .fontSize14;
	@extend .fontWeight650;
}

.aggregationLabel {
	@extend .fontSize12;
	@extend .fontWeight450;
	line-height: 1.5rem;
	@include overflowHiddenEllipsisNowrap;
	background-color: transparent;
	border: none;
	color: $neutral;
	text-transform: capitalize;
	margin: 0 0 0 0.3rem;
	width: 100%;
	cursor: pointer;
}

.usageContainer:hover .aggregationLabel {
	margin: 0 1.5rem 0 0.3rem;
}

.aggregationInputContainer {
	@include flex;
	gap: 0.6rem;
	align-items: center;
}

.cell {
	@include overflowHiddenEllipsisNowrap;
	@extend .fontSize14;
	@extend .fontWeight250;
	@include flex;
	padding-right: 1.4rem;
	color: $neutral700;
	caret-color: $primary400;
	border: 1px solid transparent;
	width: 100%;
}

.greenIcon {
	margin-left: 0.2rem;
}

.purpleDiamond {
	@extend .purpleDiamond;
	line-height: 0rem;
}
