@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.container {
	@extend .appearAnimation;
	@include flex;
	position: relative;
	height: 100%;
	width: 100%;
}

.listContainer {
	height: calc(100% - 3.6rem);
	overflow: scroll;
}

.fullView {
	width: calc(100%);
	transition: 400ms;
}

.smallView {
	border-right: 1px solid $neutral200;
	height: 100%;
	width: 40rem;
	transition: 400ms;
	min-width: 40rem;
}

.itemsSizes {
	display: grid;
	width: 100%;
	grid-template-columns: 20rem repeat(auto-fit, minmax(12rem, 1fr)) 3.4rem;
}

.itemsSizesClose {
	display: grid;
	width: 100%;
	grid-template-columns: 24rem repeat(auto-fit, minmax(12rem, 1fr)) 2.4rem;
}

.header {
	@extend .itemsSizes;
	@extend .fontSize12;
	@include horizontalDivider;
	align-items: center;
	height: 3.6rem;
	text-transform: uppercase;
}

.row {
	@extend .itemsSizes;
	border-left: 2px solid transparent;
	align-items: center;
	border-bottom: 1px solid $neutral200;
	height: 6.4rem;
	cursor: pointer;
	&:hover {
		background-color: $neutral50;
		transition: 400ms;
	}
}

.rowItems {
	animation-delay: 500ms;
	padding-left: 1.4rem;
	&:first-child {
		padding-left: 2.4rem;
	}
}

.rowItemsSmallView {
	display: none;
	&:first-child {
		display: flex;
	}
	&:nth-child(2) {
		display: flex;
	}
}

.listItem {
	@include flex;
	@include horizontalDivider;
	justify-content: space-between;
	height: 6.4rem;
	align-items: center;
	cursor: pointer;
	transition: 400ms;
	border-left: 4px solid transparent;
	&:hover {
		background-color: $neutral50;
		transition: 400ms;
	}
}

.text {
	@include flex;
	@extend .fontSize14;
	@extend .fontWeight250;
	align-items: center;
	width: 24rem;
	gap: 1.4rem;
}

.itemIconContainer {
	@include borderNeutral;
	@include flexCenter;
	height: 4rem;
	width: 4rem;
	min-width: 4rem;
	background-color: $white;
}

.itemCardIconContainer {
	@extend .itemIconContainer;
	height: 5rem;
	width: 5rem;
	min-width: 5rem;
}

.itemTag {
	@include overflowHiddenEllipsisNowrap;
	@extend .fontSize12;
	text-align: center;
	color: $neutral;
	height: 2.2rem;
	border: 1px solid $black;
	min-width: 4.5rem;
	width: fit-content;
	padding: 0 0.8rem;
}

.selectedRow {
	@extend .fontWeight650;
	border-left: 4px solid $black;
	transition: 400ms;
	.itemIconContainer {
		background-color: $neutral50;
		border-color: $neutral200;
		transition: 400ms;
	}
	.nameInTable {
		@extend .fontWeight650;
	}
}

.previewContainerOpen {
	background-color: $neutral50;
	padding: 2.4rem;
	width: calc(100% - 40rem);
}

.previewContainerClose {
	width: 0;
	overflow: hidden;
}

.nameInTable {
	@include overflowHiddenEllipsisNowrap;
	max-width: calc(100% - 7rem);
}

.productName {
	@include overflowHiddenEllipsisNowrap;
	max-width: calc(100% - 2.4rem);
}

.buttonsContainer {
	@include flex;
	gap: 1.4rem;
	padding: 1.4rem 0;
	justify-content: flex-end;
}
