@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.container {
	width: 100%;
	height: 7.4rem;
	@include borderNeutral;
	display: flex;
	border-radius: 1rem;
	overflow: hidden;
	position: relative;
}

.productColl {
	width: 26rem;
}

.usageCall {
	width: calc(100% - 26rem);
	display: flex;
	flex-direction: column;
}

.dataRow {
	@extend .fontSize12;
	@extend .fontWeight650;
	justify-content: center;
	@include flexColumn;
	height: 100%;
	background-color: $primary50;
	padding-left: 1.4rem;
}

.itemDescription {
	@extend .fontSize14;
}

.dashboardIcon {
	position: absolute;
	height: 10px;
	transform: rotate(45deg);
	top: 5.5rem;
	right: 1.2rem;
}

.usageCell {
	background-color: $calming50;
	border-width: 4px;
	border-style: solid;
	border-image: linear-gradient(to right, #c2a5fc 0%, rgba(194, 165, 252, 0) 100%);
	border-image-slice: 1;
}

.usageText {
	@include overflowHiddenEllipsisNowrap;
	max-width: 19rem;
	padding-left: 0.2rem;
	width: 100%;
	color: $calming;
	font-size: 1.1rem;
	line-height: 1.1rem;
}
