@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.modalContainer {
	height: 100%;
	width: 52rem;
	top: 0;
	right: 0;
	left: auto;
	background-color: $white;
	transform: none;
	border-radius: 0;
}

.container {
	height: 100%;
	width: 100%;
}

.contentContainer {
	height: calc(100% - 19.2rem);
	padding: 2.4rem;
}

.header {
	display: flex;
	justify-content: space-between;
	height: 9.6rem;
	padding: 2.4rem;
	align-items: center;
	@include horizontalDivider;
}

.title {
	@extend .fontSize20;
	@extend .fontWeight650;
}

.subTitle {
	@extend .fontWeight250;
	color: $neutral;
}

.xIcon {
	height: 10px;
}

.footer {
	display: flex;
	height: 9.6rem;
	border-top: 1px solid $neutral200;
	padding: 2.4rem;
}

.footerButtons {
	width: 100%;
	height: 4.8rem;
}

.contentTitle {
	@extend .fontSize14;
	padding-bottom: 0.4rem;
}

.usageProductsItems {
	@include borderNeutral;
	padding: 1.4rem;
	height: calc(100% - 35rem);
	justify-content: flex-start;
	overflow: scroll;
	background-color: $neutral50;
	border-radius: 1rem;
}

.iconContainer {
	width: 5.2rem;
	height: 5.2rem;
	border-radius: 3rem;
	background-color: $primary;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 0.5rem;
}

.addIcon {
	height: 14px;
}

.addUsageProductButton {
	width: 22.4rem;
	height: 10rem;
	text-align: left;
}
