@import '../../../constants/colors.scss';

.modalOverlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(17, 24, 39, 0.4);
	z-index: 40;
	backdrop-filter: blur(1px);
}

.modalContainer {
	position: fixed;
	top: 50%;
	left: 50%;
	min-height: 10rem;
	min-width: 10rem;
	z-index: 50;
	background-color: $white;
	transform: translate(-50%, -50%);
	overflow: hidden;
}
