@import '../../constants/colors.scss';
@import '../../constants/mixins.scss';
@import '../../constants/global.scss';

.dataSelectContainer {
	position: relative;
	width: 100%;
}

.selectListContainer {
	position: absolute;
	width: 100%;
	background-color: $white;
	border: 1px solid $neutral200;
	margin-top: 1.4rem;
	overflow: hidden;
	transition: 600ms;
	z-index: 300;
	box-shadow: -10px 8px 40px -6px rgba(16, 24, 40, 0.02), 0px 20px 25px -5px rgba(16, 24, 40, 0.02);
}

.selectListContainer:active {
	transition: 600ms;
}

.selectListContainerHidden {
	background-color: $white;
	position: absolute;
	height: 0rem;
	overflow: hidden;
}

.selectList {
	max-height: 29.5rem;
	overflow: auto;
}

.selectItem {
	padding: 2.4rem 1.6rem;
	@include horizontalDivider;
}

.selectItem:hover {
	background-color: $primary50;
	cursor: pointer;
}

.details {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.selectAddButton {
	display: flex;
	height: 7.6rem;
	background-color: $neutral50;
	color: $primary;
	align-items: center;
	padding: 0rem 2.4rem;
	border-top: 0.1rem solid $neutral200;
}

.selectAddButton:hover {
	background-color: $neutral100;
	color: $primary600;
	cursor: pointer;
}

.selectOverlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 40;
}

.searchIcon {
	display: flex;
	height: 6.8rem;
	width: 6.8rem;
	background-color: $neutral50;
	border: 1px dashed $neutral400;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.searchIconSmall {
	display: flex;
	height: 4rem;
	width: 4rem;
	background-color: $neutral50;
	border: 1px dashed $neutral400;
	border-radius: 0.5rem;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.inputContainer {
	@extend .fontSize24;
	height: 4.8rem;
	width: 100%;
	border-radius: 0.6rem;
	border: none;
	color: $neutral;
	padding: 0rem 2.4rem 0rem 2.4rem;
	display: flex;
	align-items: center;
}

.inputContainerSmall {
	height: 4rem;
	width: calc(100% - 5rem);
	border-radius: 0.6rem;
	border: none;
	color: $neutral;
	padding: 0rem 2.4rem 0rem 2.4rem;
	display: flex;
	align-items: center;
}

.inputContainerSmall:focus {
	outline: none;
}

.inputContainer:focus {
	outline: none;
}

.ellipsis {
	@include overflowHiddenEllipsisNowrap;
	max-width: 90%;
}

.ellipsisSelected {
	@include overflowHiddenEllipsisNowrap;
	max-width: 65%;
}

.arrow {
	width: 2;
	margin-right: 1.4rem;
}

.arrowUp {
	transform: rotate(180deg);
	transition: 400ms;
}

.arrowDown {
	transition: 400ms;
}
