@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.paginationContainer {
	height: 7.2rem;
	width: 37rem;
	background-color: $black;
	bottom: 0;
	position: absolute;
	padding: 1.4rem;
	@include flexCenter;
	justify-content: space-between;
	gap: 1rem;
	align-self: center;
}

.actionButton {
	@include flexCenter;
	height: 4.4rem;
	width: 4.4rem;
	border-radius: 1rem;
	align-self: center;
	border: 1px solid $neutral800;
	&:hover {
		cursor: pointer;
	}
}

.left {
	transform: rotate(0deg);
}

.right {
	transform: rotate(180deg);
}

.pageNumber {
	@include flex;
	@extend .fontSize14;
	@extend .fontWeight450;
	color: $white;
	width: max-content;
}

.actionsContainer {
	@include flexCenter;
	gap: 1rem;
}
