@import 'src/constants/colors';
@import 'src/constants/global';

.emptyPricingModels {
	height: calc(100% - 2.4rem);
	@include flexColumnCenter;
	flex: 1;
	background-color: $neutral50;
	border: 1px dashed $neutral300;
	overflow: scroll;
}

.title {
	@extend .fontSize32;
	@extend .fontWeight850;
	padding-bottom: 0.4rem;
}

.subTitle {
	@extend .fontWeight250;
	padding-bottom: 1.4rem;
	color: $neutral;
	width: 47.2rem;
	text-align: center;
}

.icon {
	width: 17.8rem;
	margin-bottom: 1.4rem;
}

.templateButton {
	width: 24rem;
}

.buttonContainer {
	display: flex;
}

.commonPlans {
	@extend .fontSize12;
	@extend .fontWeight650;
	text-transform: uppercase;
	color: $neutral;
	padding: 2.4rem 0 1.4rem;
}

.catalogContainer {
	@include flexCenter;
	width: 100%;
	padding-top: 1rem;
}

.catalogCard {
	margin: 0 1rem 1rem 1rem;
	width: 25%;
	height: 24rem;
}
