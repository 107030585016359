@import 'src/constants/colors';
@import 'src/constants/mixins';
@import 'src/constants/global';

.tableCard {
	@include borderNeutral;
	background-color: $white;
	max-height: calc(100% - 5rem);
	overflow: scroll;
	padding-right: 2.2rem;
}

.itemCardIconContainer {
	@include borderNeutral;
	@include flexCenter;
	height: 5rem;
	width: 5rem;
}

.tableCardHeader {
	@include flex;
	padding: 2.4rem 2.4rem 0 2.4rem;
	align-items: center;
	width: 100%;
	justify-content: space-between;
}

.cardName {
	@include flex;
	gap: 1.4rem;
	align-items: center;
}

.switchContainer {
	@extend .fontSize12;
	@include flex;
	gap: 0.4rem;
	color: $neutral;
}

.itemTag {
	@include overflowHiddenEllipsisNowrap;
	@extend .fontSize12;
	height: 2.1rem;
	border: 1px solid $black;
	padding: 0rem 0.8rem;
	min-width: 4.5rem;
	max-width: 50rem;
	width: fit-content;
	color: $neutral;
}

.tableContainer {
	max-height: calc(100% - 5rem);
	min-width: 70rem;
	padding-right: 0.4rem;
}

.table {
	max-height: calc(100% - 5rem);
}
