@import '../../constants/colors.scss';
@import '../../constants/mixins.scss';
@import '../../constants/global.scss';

.container {
	@include flexColumn;
	gap: 1.4rem;
	overflow: scroll;
}

.locked {
	pointer-events: none;
	opacity: 0.5;
}

.listHeader {
	@include flex;
	gap: 5.4rem;
}

.headerItem {
	@include alignItemsCenter;
	@include borderNeutral;
	background-color: $white;
	width: 40rem;
	height: 7.6rem;
	padding: 0 1.4rem;
}

.headerItemTitles {
	@include alignItemsCenter;
	flex: 1;
}

.title {
	@extend .fontSize12;
	color: $neutral;
	text-transform: uppercase;
}

.iconContainer {
	@include flexCenter;
	@include borderNeutral;
	width: 4.8rem;
	height: 4.8rem;
	border-radius: 0.2rem;
	margin-right: 1.4rem;
	background-color: $neutral100;
	overflow: hidden;
}

.cardIcon {
	width: 4.8rem;
	height: 4.8rem;
}

.addButton {
	height: 4.4rem;
}

.refreshButton {
	color: $success;
}

.button {
	height: 2rem;
	width: 5.5em;
}

.buttonSyncing {
	border: none;
}

.titleWrapper {
	@include flex;
	justify-content: space-between;
}

.rightSide {
	@include flexColumn;
	width: 100%;
}
