@import '../../../../constants/colors.scss';
@import '../../../../constants/mixins.scss';
@import '../../../../constants/global.scss';

.container {
	@include flexColumn;
	align-items: center;
	padding: 3.4rem;
	height: 100%;
	width: calc(100% - 24.8rem);
	min-width: 800px;
	overflow: scroll;
}
