@import '../../../constants/colors.scss';
@import '../../../constants/mixins.scss';
@import '../../../constants/global.scss';

.container {
	width: 24.8rem;
	max-height: 24.8rem;
	@include borderNeutral;
	overflow: hidden;
	position: relative;
	cursor: pointer;
	background-color: $white;
	transition: 0.5s;
	outline: 2px solid transparent;
	&:hover {
		outline: 2px solid $success200;
		border: 1px solid $white;
		transition: 0.5s;
		.icon {
			transition: 0.5s;
			height: 73%;
		}
	}
	&:active {
		border: 1px solid $success;
	}
}

.container:hover .topBlank {
	background-color: $neutral100;
	.icon {
		transition: none;
		transform: none;
	}
}

.higher {
	height: 29.2rem;
}

.disable {
	pointer-events: none;
}

.topContainer {
	@include flex;
	justify-content: space-between;
	padding: 0.6rem;
	height: 58%;
}

.topRegular {
	border-bottom: 1px solid $neutral100;
	background-color: $neutral100;
}

.topBlank {
	background-color: $neutral100;
}

.dataContainer {
	padding: 1.4rem;
	height: 42%;
	min-height: 9.2rem;
}

.productTitle {
	@extend .fontSize12;
	@extend .fontWeight650;
	text-transform: uppercase;
	color: $neutral;
	@include overflowHiddenEllipsisNowrap;
}

.blankProductTitle {
	@extend .fontWeight650;
	line-height: 2.5rem;
}

.title {
	@extend .fontWeight650;
	@include overflowHiddenEllipsisNowrap;
}

.description {
	@extend .fontSize12;
	color: $neutral400;
}

.lastChange {
	@extend .fontSize12;
	color: $neutral400;
}

.icon {
	height: 70%;
	align-self: center;
	margin-left: 2rem;
	cursor: pointer;
	transition: 0.8s;
}

.hamburgerButton {
	visibility: hidden;
	height: 4rem;
	width: 4rem;
}

.container:hover .hamburgerButton {
	visibility: visible;
}

.hamburgerIcon {
	height: 14px;
}

.favoriteButton {
	padding: 0;
	height: 2.5rem;
	width: 2.5rem;
	@include flexCenter;
}

.hide {
	visibility: hidden;
}
