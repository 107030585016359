@import 'src/constants/colors';
@import 'src/constants/mixins';
@import 'src/constants/global';

.container {
	@include flexCenter;
	justify-content: space-between;
	height: 100%;
}

.emptyContainer {
	@include flexCenter;
	width: 100%;
}

.graphContainer {
	height: 85%;
	width: 50%;
	max-width: 50%;
	max-height: 49rem;
}

.totalText {
	@include flexColumn;
	position: absolute;
	line-height: 3.5rem;
	right: 27%;
	bottom: 25%;
	transform: translate(0%, 5%);
}

.subTitle {
	color: $neutral400;
	display: flex;
}

.titleContainer {
	@include flexCenter;
	max-width: 50%;
	width: 50%;
	justify-content: flex-start;
}

.title {
	@include overflowHiddenEllipsisNowrap;
	max-width: 90%;
	width: 90%;
}

.summaryContainer {
	@include flexColumn;
	gap: 1.4rem;
	width: 45%;
	max-width: 45%;
}

.row {
	@include flex;
	@extend .fontSize12;
	@extend .fontWeight450;
	gap: 1rem;
}

.dot {
	height: 4px;
	width: 4px;
	border-radius: 2px;
	margin-right: 0.4rem;
}

.purestValue {
	color: $neutral700;
	&:after {
		content: '\00a0';
	}
}

.purest {
	&:before {
		content: '\00a0';
	}
}
