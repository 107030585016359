@import '../../constants/colors.scss';
@import '../../constants/global.scss';

.modal {
	overflow: unset !important;
	z-index: 40;
}

.overlayModal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(17, 24, 39, 0.4);
	z-index: 41;
	backdrop-filter: blur(2px);
}

.formContainer {
	max-height: calc(100vh - 5rem);
	width: 72rem;
	transition: 0.4s;
}

.body {
	height: calc(100% - 19rem);
	max-height: calc(100vh - 24rem);
	overflow: scroll;
}

.collapsible {
	padding: 0;
}

.collapsibleHeader {
	padding: 2.4rem 2.4rem 2.4rem 0;
}

.header {
	padding: 2.4rem;
	@include horizontalDivider;
	@include flexCenter;
	justify-content: space-between;
}

.mainTitle {
	@extend .fontSize20;
	@extend .fontWeight650;
}

.subTitle {
	@extend .fontWeight250;
	color: $neutral;
}

.x_icon {
	height: 1.2rem;
}

.wrapper {
	@include flexColumn;
	gap: 1.4rem;
	padding: 0 2.4rem 2.4rem 2.4rem;
	@include horizontalDivider;
}

.headerContainer {
	@include flex;
	gap: 1.4rem;
	align-items: center;
	padding: 0 2.4rem;
}

.headerIcon {
	width: 2.2rem;
	height: 2.2rem;
}

.iconWrapper {
	padding: 1rem 1rem 0.5rem 1rem;
	border: 1px solid $neutral200;
}

.logoNameContainer {
	@include flex;
	gap: 1.4rem;
	position: relative;
}

.content {
	@include flexColumn;
	gap: 1.4rem;
}

.integrationIdsContent {
	@include flex;
	flex-wrap: wrap;
	gap: 1rem;
}

.inputsInRow {
	@include flex;
	gap: 1.4rem;
}

.phoneInputs {
	@include flex;
}

.title {
	@extend .fontSize18;
	@extend .fontWeight650;
}

.lightText {
	@extend .fontSize14;
	@extend .fontWeight450;
	color: $neutral;
}

.titles {
	@include flexColumn;
}

.footer {
	padding: 2.4rem;
	@include flexCenter;
	justify-content: space-between;
	border-top: 0.1rem solid $neutral200;
}

.addButton {
	height: 4.8rem;
	width: 24rem;
	@extend .fontSize14;
	@extend .fontWeight250;
	color: $white;
}

.titleStyle {
	@extend .fontSize14;
	@extend .fontWeight450;
	color: $neutral900 !important;
}

.lightGreyText {
	&::placeholder {
		@extend .fontSize14;
		@extend .fontWeight250;
		color: $neutral400;
	}
}

.fullWidth {
	width: 100%;
}

.multiline {
	resize: none;
	&::placeholder {
		@extend .fontSize14;
		@extend .fontWeight250;
		color: $neutral400;
	}
}

.phonePrefix {
	width: 7.3rem;
	background-color: $neutral50;
	@include flexCenter;
	padding: 1.4rem;
}

.phone {
	border-radius: 0px 0.6rem 0.6rem 0px;
}

.textCenter {
	text-align: center;
}

.hide {
	display: none;
}

.addressCheckBoxContainer {
	@include flexCenter;
	margin-bottom: 0.5rem;
}

.checkboxContainer {
	@include flex;
	@extend .fontSize14;
	gap: 2.4rem;
}

.checkboxItem {
	@include flex;
	color: $neutral600;
	gap: 1rem;
}

.addressCheckboxText {
	@extend .fontSize12;
	@extend .fontWeight450;
	color: $neutral400;
	margin-right: 0.2rem;
}

.addressToggle {
	height: 1.6rem;
	width: 2.6rem;
}

.phoneContainer {
	@include flexColumn;
	gap: 0.4rem;
}

.integrationIdBox {
	width: 16.1rem;
	max-width: 16.1rem;
	height: 3.2rem;
	padding: 0.6rem;
	border: 1px solid $neutral200;
	@include flex;
	justify-content: space-between;
	gap: 0.6rem;
	@extend .fontSize14;
	@extend .fontWeight450;
	color: $neutral700;
	background-color: $white;
	&:hover {
		.copy {
			display: flex;
		}
	}
}

.integrationIdInput {
	&::placeholder {
		@extend .fontSize14;
		@extend .fontWeight450;
		color: $neutral700;
	}
}

.integrationIdBox {
	width: 16.1rem;
}

.copyButton {
	padding: 0;
	background-color: $white;
	box-shadow: -10px 0px 10px 1px $white;
}

.copy {
	display: none;
}

.leftIntegrationContentBox {
	@include flex;
	gap: 0.4rem;
	max-width: 12.5rem;
}

.integrationIcon {
	width: fit-content;
	max-width: 2rem;
	max-height: 2rem;
}

.rowItem {
	@include flexColumn;
	gap: 1.4rem;
	width: 100%;
}

.iconContainer {
	height: 4rem;
	width: max-content;
	@include borderNeutral;
	@include flexCenter;
	box-shadow: 0 0.1rem 0.2rem rgba(16, 24, 40, 0.05);
	border-radius: 0.6rem;
	cursor: pointer;
	@extend .fontSize14;
	@extend .fontWeight450;
}

.tooltipContainer {
	&:nth-child(2) {
		margin: 0 1rem;
	}
}

.iconContainer:active {
	opacity: 70%;
	transform: translateY(0.1rem) translateX(0.1rem);
}

.iconContainer:hover {
	background-color: $success50;
	border: 1px solid $success300;
}

.selected {
	background-color: $success100;
	color: $success900;
	border: 1px solid $success;
}

.pasteButton {
	padding: 0;
}

.cryptoWalletInputContainer {
	width: 100%;
	transition: 300ms;
	gap: 0.8rem;
}

.stripeAccountItem {
	@include flexCenter;
	gap: 0.5rem;
}

.appearAnimation {
	@include flexColumn;
	@extend .appearAnimation;
}

.hidden {
	display: none;
	transition: 350ms;
}

.partnerToggleWrapper {
	@include flexCenter;
	@extend .fontSize12;
	@extend .fontWeight450;
	color: $neutral;
	gap: 0.3rem;
	position: absolute;
	right: 0;
}

.partnerToggle {
	height: 1.6rem;
	width: 2.6rem;
}

.selectsContainer {
	@include flex;
	position: relative;
	flex: 1;
	transition: transform 0.3s ease;
	@extend .appearAnimation;
}

.tooltipBlackLabel {
	@include flexColumn;
	@extend .neutralFontSize12;
	@extend .fontWeight450;
}

.tooltipLabel {
	@extend .tooltipBlackLabel;
	color: $white;
}

.tooltip {
	bottom: calc(100% + 8rem) !important;
	left: 95% !important;
}

.autoCharge {
	@include flex;
	color: $neutral400;
	@extend .fontSize12;
	@extend .fontWeight450;
	gap: 0.4rem;
	position: absolute;
	top: 0.2rem;
	right: 0rem;
	width: max-content;
}

.autoChargeToggle {
	width: 2.6rem;
	height: 1.6rem;
}

.autoChargeToggleDisabled {
	background-color: $success;
	opacity: 0.5;
}

.greyTitle {
	@extend .fontSize14;
	@extend .fontWeight450;
	color: $neutral600;
}

.inputsInRow {
	@include flex;
	justify-content: space-between;
}

.flexInRow {
	flex: 1;
}

.iconContainer {
	height: 4rem;
	width: max-content;
	@include borderNeutral;
	@include flexCenter;
	box-shadow: 0 0.1rem 0.2rem rgba(16, 24, 40, 0.05);
	border-radius: 0.6rem;
	cursor: pointer;
	@extend .fontSize14;
	@extend .fontWeight450;
}

.section {
	@include flexColumn;
	gap: 2.4rem;
}

.error {
	border: 1px solid $destructive;
	width: fit-content;
}

.tagsContainerClassName {
	max-height: 5rem;
	overflow: scroll;
}

.tagsContainerClassName::-webkit-scrollbar {
	width: 5px;
}
