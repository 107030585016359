@import 'src/constants/colors';
@import 'src/constants/global';
@import 'src/constants/mixins';

.content {
	height: 52.5rem;
	max-height: calc(100% - 20rem);
	overflow: scroll;
}

.buttonContainer {
	@include flexCenter;
	gap: 2.4rem;
	width: 100%;
	height: 9rem;
	border-top: 1px solid $neutral200;
}

.button {
	height: 4.8rem;
	width: 29.5rem;
}
