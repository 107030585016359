@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.pageContainer {
	@extend .appearAnimation;
	height: 100%;
	width: 100%;
	background-color: $neutral50;
	@include flex;
	gap: 1.4rem;
	padding: 2.4rem;
}

.pageHeader {
	@include flex;
	justify-content: space-between;
	align-items: center;
	padding: 3.4rem 2.4rem 2.4rem 2.4rem;
}

.buttonsContainer {
	@include flex;
	height: 4.8rem;
	gap: 0.8rem;
}

.content {
	background-color: $neutral50;
	@include flex;
	gap: 1.4rem;
	padding: 2.4rem;
}

.box {
	background-color: $white;
	border: 1px solid $neutral200;
}

.leftContainer {
	@extend .box;
	width: 33%;
	background-color: $white;
}

.leftContainerHeader {
	@include flex;
	justify-content: space-between;
	padding: 2.4rem;
	border-bottom: 1px solid $neutral200;
}

.leftContainerLabels {
	@include flexColumn;
}

.leftContainerTitle {
	@extend .fontSize18;
	@extend .fontWeight650;
}

.leftContainerTitle {
	@extend .fontSize18;
	@extend .fontWeight650;
}

.leftContainerSubtitle {
	@extend .fontSize12;
	@extend .fontWeight450;
	color: $neutral400;
}

.rightContainer {
	@include flexColumn;
	gap: 1.4rem;
	width: 67%;
}

.summaryBar {
	@include flex;
	gap: 1.4rem;
}

.summaryCard {
	min-width: 15.5rem;
	width: max-content;
	border: 1px solid $neutral200;
	padding: 1.8rem;
	background-color: $white;
}

.analyticsGraphContainer {
	align-self: flex-end;
	height: 80%;
	width: 100%;
	padding: 0 1.4rem 1.4rem 1.4rem;
}

.graphs {
	@include flex;
	gap: 1.4rem;
	height: 33.4rem;
}

.analyticsContainer {
	@include defaultBoxShadow;
	@include flexColumn;
	padding: 1.4rem;
	height: 33.4rem;
	justify-content: space-between;
	width: 49.5%;
}

.table {
	height: calc(100% - 2rem);
	overflow: scroll;
	position: relative;
}

.scrollable {
	height: calc(100% - 9.5rem);
	overflow-y: scroll;
	position: relative;
}
