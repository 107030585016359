@import '../../../../../constants/colors.scss';
@import '../../../../../constants/mixins.scss';
@import '../../../../../constants/global.scss';

.warningModalOverlay {
	@extend .appearAnimation;
	position: fixed;
	top: 11rem;
	right: 0;
	width: 50%;
	height: 100%;
	z-index: 40;
	background-color: rgba(17, 24, 39, 0.4);
}

.container {
	@extend .appearAnimation;
	@include borderNeutral;
	transition: 400ms;
	position: fixed;
	top: 11rem;
	right: 0;
	z-index: 50;
	transition: 400ms;
	width: 50%;
}

.header {
	@include flexRowSpaceBetween;
	@include horizontalDivider;
	padding: 0 2.4rem;
	height: 6.8rem;
	background-color: $neutral50;
	max-height: 50%;
	overflow: scroll;
}

.errorMassageContainer {
	@extend .fontSize14;
	@include horizontalDivider;
	@include alignItemsCenter;
	background-color: $warning50;
	gap: 0.5rem;
	color: $warning700;
	min-height: 4.8rem;
	padding: 1rem 2.4rem;
	line-clamp: 2;
}

.downloadWarningsButton {
	width: 17rem;
	padding: 0;
	height: 4rem;
}

.warningNumbers {
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
}
