@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';
@import 'src/constants/colors.scss';

.container {
	@extend .appearAnimation;
	height: 100%;
	width: 100%;
}

.boxBorder {
	border: none;
	border-bottom: 1px solid $neutral200;
}
