@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.container {
	width: 100%;
	height: 100%;
	overflow: hidden;
	background-color: $white;
}

.titleContainer {
	@include flex;
	justify-content: space-between;
	height: 8.6rem;
	padding: 2.4rem;
}

.filters {
	@include flex;
	gap: 00.8rem;
}

.tableContainer {
	@extend .appearAnimation;
	@include flexColumn;
	width: 100%;
	height: 100%;
	overflow: scroll;
	position: relative;
}

.header {
	@include flex;
	@extend .fontSize12;
	@include horizontalDivider;
	text-transform: uppercase;
	height: 4.5rem;
	min-height: 4.5rem;
	background-color: $white;
	z-index: 6;
	position: sticky;
	top: 0;
	display: grid;
}

.headerCell {
	@include flex;
	position: sticky;
	top: 0;
	left: 0;
	align-items: center;
	padding-left: 2.4rem;
	position: relative;
	grid-row: 1;
	background-color: $white;
	&:first-child {
		position: sticky;
		top: 0;
		left: 0;
		min-width: 21rem;
		width: calc(21rem + 1px);
		background-color: $white;
		z-index: 5;
		border: 10px solid;
		border-image-slice: 1;
		border-width: 1px;
		border-image-source: linear-gradient(to bottom, $white, $neutral200);
		border-top: 0;
		border-left: 0;
		border-bottom: 0;
	}
	&:not(:first-child) {
		min-width: 13rem;
		z-index: 1;
	}
}

.rowsContainer {
	flex: 1;
}

.row {
	@extend .fontSize14;
	@extend .fontWeight250;
	@include horizontalDivider;
	display: grid;
	height: 7.3rem;
	width: 100%;
}

.cell {
	@include flex;
	align-items: center;
	padding-left: 2.4rem;
	height: 7.3rem;
	grid-row: 1;
	border: 1px solid $neutral200;
	border-top: 0;
	border-left: 0;
	border-right: 0;
	&:first-child {
		border-right: 1px solid $neutral200;
		position: sticky;
		top: 0;
		left: 0;
		min-width: 21rem;
		width: calc(21rem + 1px);
		padding-right: 1rem;
		background-color: $white;
		z-index: 2;
	}
	&:not(:first-child) {
		min-width: 13rem;
		z-index: 1;
	}
}

.wrap {
	@include overflowHiddenEllipsisNowrap;
	width: 100%;
}

.footer {
	@include flex;
	@extend .fontSize14;
	@extend .fontWeight250;
	border-top: 1px solid $neutral200;
	justify-self: end;
	height: 7.3rem;
	background-color: $neutral50;
	position: sticky;
	bottom: 0;
	z-index: 5;
	display: grid;
}

.footerCell {
	background-color: $neutral50;
	grid-row: 1;

	&:first-child {
		width: 21rem;
		background-color: $neutral50;
		text-transform: uppercase;
	}
}

.emptyStateCard {
	margin-top: 10.4rem !important;
}
