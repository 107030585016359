@import '../../../../../constants/colors.scss';
@import '../../../../../constants/mixins.scss';
@import '../../../../../constants/global.scss';

.header {
	@include horizontalDivider;
	width: 82rem;
	padding-bottom: 3.4rem;
	margin-bottom: 2.4rem;
}

.subTitle {
	@extend .fontWeight250;
	color: $neutral600;
}

.selectItem {
	@include flex;
	justify-content: space-between;
	width: 100%;
	align-items: center;
	max-width: 38rem;
}

.tag {
	@extend .fontSize12;
	@include flexCenter;
	@include borderNeutral;
	color: $neutral;
	border-radius: 0.6rem;
	padding: 0rem 0.8rem;
}

.selectItemLabelWithTag {
	@include overflowHiddenEllipsisNowrap;
	max-width: 30rem;
}

.selectItemLabel {
	@include overflowHiddenEllipsisNowrap;
	max-width: 37rem;
}
