@import 'src/constants/colors';
@import 'src/constants/global';
@import 'src/constants/mixins';

.container {
	padding: 2.4rem 2.4rem 0rem 2.4rem;
	overflow: hidden;
}

.detailsContainer {
	min-height: 9.2rem;
	background-color: $white;
	@include borderNeutral;
	padding: 1.4rem;
	align-items: center;
	transition: 1s;
}

.openedDetailsContainer {
	background-color: $primary50;
	transition: 1s;
	border-bottom: 0;
}

.square {
	height: 6.4rem;
	width: 6.4rem;
	background-color: $white;
	margin-right: 0.8rem;
	@include flexCenter;
}

.menuList {
	align-self: baseline;
}

.hamburgerButton {
	height: 4.8rem;
	width: 4.8rem;
	border: none;
	color: $neutral700;
	background-color: $white;
	padding: 0;
	&:hover {
		cursor: default;
	}
}

.details {
	margin-top: 0.1rem;
	word-wrap: break-word;
	max-width: 50rem;
}

.bankNameContainer {
	@extend .fontSize14;
	@extend .fontWeight650;
	@include flex;
}

.bankName {
	@include overflowHiddenEllipsisNowrap;
	max-width: 80%;
}

.default {
	@extend .fontSize14;
	@extend .fontWeight650;
	color: $warning;
}

.delimiter {
	color: $neutral300;
}

.arrowIcon {
	margin: 0.5rem;
	color: $neutral700;
}

.arrowDown {
	transition: 400ms;
}

.arrowUp {
	transform: rotate(180deg);
	transition: 400ms;
}

.account {
	@extend .neutralFontSize12;
	@extend .fontWeight250;
	display: block;
}
