@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.tabComponent {
	@extend .fontSize14;
	padding: 0.8rem 2.4rem 1.4rem 2.4rem;
	width: fit-content;
	border-bottom-width: 2px;
	padding-bottom: 0;
	&:first-child {
		padding-left: 0;
	}
	&:hover {
		background-color: $neutral50;
	}

	border-image: linear-gradient(0deg, $neutral200 0%, rgba(229, 231, 235, 0)) 0 10 0 0;
	border-width: 1px;
	border-style: solid;
}

.tabLabels {
	border-bottom: 2px solid transparent;
	padding-bottom: 0.8rem;
}

.tabActiveLabels {
	border-bottom: 2px solid $primary;
}

.tabTitleLabel {
	color: $neutral400;
	text-transform: uppercase;
}

.activeTabLabel {
	color: $primary;
}

.label {
	color: $neutral;
}

.activeLabel {
	color: $neutral900;
	padding-bottom: 0.5rem;
}

.tabsContainer {
	margin: 0 10rem;
}

.tabsContent {
	padding: 2.4rem 9rem !important;
}

.activeTab {
	border-bottom: 2px solid $primary;
}

@media only screen and (max-width: 1400px) {
	.tabsContainer {
		margin: 0 10rem;
	}
	.tabsContent {
		padding: 2.4rem 9rem !important;
	}
}
