@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.container {
	@include flex;
	height: 9.6rem;
	border-bottom: 1px solid $neutral200;
	padding: 1.8rem 2.4rem;
	gap: 0.8rem;
	cursor: pointer;

	&:hover {
		background-color: $neutral50;
		.menu {
			visibility: visible;
		}
	}
	&:active {
		border-left: 4px solid $success;
	}
}

.isActive {
	border-left: 2px solid $success;
	&:hover {
		border-left: 3px solid $success;
	}
}

.iconWrapper {
	@include flexCenter;
	height: 5.2rem;
	width: 5.8rem;
	border-radius: 50%;
	background-color: $success50;
}

.dataWrapper {
	@include flex;
	width: 100%;
}

.labels {
	@include flexColumn;
	width: 70%;
}

.amounts {
	@include flexColumn;
	width: 30%;
	height: max-content;
	padding: 0 0 0 1.5rem;
}

.neutralLabel {
	color: $neutral400;
	text-transform: uppercase;
	@extend .fontSize12;
	@extend .fontWeight450;
}

.tooltip {
	width: min-content !important;
}

.neutralSubLabel {
	color: $neutral;
	@extend .fontSize12;
	@extend .fontWeight450;
	line-height: 1.2rem;
}

.mainLabel {
	@extend .fontWeight650;
	color: $neutral800;
}

.divider {
	border: 0.05px solid $neutral200;
	height: 3rem;
	margin: 2.3rem 0;
}

.menuButtons {
	transform: rotate(90deg);
	height: 2rem;
	width: 5rem;
	@include flexCenter;
	align-self: flex-end;
	width: 100%;
	padding: 0 !important;
}

.menu {
	@include flex;
	justify-content: flex-end;
	visibility: hidden;
	height: 100%;
	width: 100%;
}
