@import '../../constants/colors.scss';
@import '../../constants/global.scss';

.modalContainer {
	max-height: calc(100vh - 10rem);
	width: 51rem;
	@include flexColumn;
}

.header {
	@include flexCenter;
	@include horizontalDivider;
	height: 10rem;
	width: 100%;
	justify-content: space-between;
	padding: 2.4rem;
}

.text {
	@extend .fontWeight250;
	color: $neutral;
}

.fileContainer {
	height: calc(100% - 20rem);
	overflow: scroll;
	flex: 1;
	width: 100%;
	padding: 2.4rem;
	text-align: center;
}

.footer {
	height: 10rem;
	width: 100%;
	@include flexCenter;
	border-top: 1px solid $neutral200;
	gap: 2.4rem;
	justify-content: flex-end;
	padding: 2.4rem;
}

.importButton {
	width: 16rem;
	height: 4.8rem;
}

.cancelButton {
	height: 4.8rem;
	width: 10.6rem;
}

.fileUploader {
	@include flexColumnCenter;
	background-color: $neutral50;
	border: 1px dashed $neutral200;
	border-radius: 0.6rem;
	padding: 2rem;
	margin-bottom: 2.4rem;
	height: 32.6rem;
	justify-content: space-between;
}

.download {
	color: $primary;
	cursor: pointer;
}
