@import '../../../constants/colors.scss';
@import '../../../constants/global.scss';
@import '../../../constants/mixins.scss';

.container {
	@include flex;
	justify-content: space-between;
}

.listCard {
	background-color: $neutral50;
	@include borderNeutral;
	padding: 2.4rem;
	border-radius: 0.8rem;
	height: 100%;
	transition: 400ms;
	margin-bottom: 2.4rem;
	width: 29.6rem;
}

.listCardClose {
	width: 29.6rem;
	background-color: $neutral50;
	@include borderNeutral;
	padding: 2rem;
	border-radius: 0.8rem;
	height: 7rem;
	transition: 400ms;
	overflow: hidden;
}

.titleIcon {
	@include flexCenter;
	width: 3.6rem;
	height: 3.6rem;
	background: $neutral200;
	border-radius: 0.2rem;
}

.listTitle {
	@include flex;
	@extend .fontSize14neutral;
	@extend .fontWeight650;
	margin-bottom: 1.4rem;
	align-items: center;
	gap: 0.8rem;
	text-transform: uppercase;
}

.arrowList {
	@include flexColumnCenter;
	padding: 0 1.4rem;
}

.arrowListClose {
	padding: 2.4rem 2.4rem;
}

.cardItem {
	@include borderNeutral;
	@extend .fontSize12;
	@include flex;
	align-items: center;
	background-color: $white;
	padding: 0 1.3rem;
	width: 25rem;
	height: 4.4rem;
	border-radius: 0.6rem;
	margin-bottom: 1.4rem;
}

.arrowItem {
	@include flexCenter;
	border: 1px solid $neutral300;
	height: 2rem;
	width: 2rem;
	border-radius: 1rem;
}

.arrowLine {
	height: 3.7rem;
	width: 1px;
	background-color: $neutral300;
}

.arrowLineLong {
	height: 7.5rem;
	width: 1px;
	background-color: $neutral300;
	transition: 400ms;
}

.arrowLineLongClose {
	transition: 400ms;
	height: 2.4rem;
	width: 1px;
	background-color: $neutral300;
}

.open {
	transition: 400ms;
}

.close {
	transform: rotate(180deg);
	transition: 400ms;
}

.dot {
	width: 0.7rem;
	height: 0.7rem;
	border-radius: 0.5rem;
	background-color: $neutral300;
}

.dotLine {
	height: 2.4rem;
	width: 1px;
	background-color: $neutral300;
}

.innerIcon {
	margin-right: 1rem;
}
