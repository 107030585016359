@import 'src/constants/colors';
@import 'src/constants/mixins';
@import 'src/constants/global';

.pageContainer {
	@extend .appearAnimation;
	@extend .minScreenSize;
	background-color: $white;
	padding: 0rem 2.4rem;
	height: 100%;
	overflow: scroll;
}

.pageHeader {
	display: flex;
	justify-content: space-between;
	margin-bottom: 2.4rem;
	align-items: center;
	padding-top: 3.4rem;
}

.description {
	@extend .fontWeight250;
	color: $neutral400;
}

.settingsContainer {
	display: flex;
	flex: 1;
	height: calc(100% - 14rem);
	justify-content: space-between;
}

.settingsList {
	@include borderNeutral;
	background-color: $neutral50;
	height: 100%;
	background-color: 'pink';

	width: 24.8rem;
}

.listContainer {
	&:first-child {
		// tTODO return when next section ready
		// @include horizontalDivider;
	}
	padding-bottom: 2.4rem;
}

.settingsCard {
	@include borderNeutral;
	height: fit-content;
	float: left;
	margin-left: 2.4rem;
	width: calc(100% - 27.2rem);
}

.listHeader {
	padding: 2.4rem;
}

.listTitle {
	@extend .fontSize20;
	@extend .fontWeight650;
	display: block;
}

.listDescription {
	@extend .fontSize14;
	@extend .fontWeight250;
	color: $neutral400;
	display: block;
}

.settingsButton {
	@extend .fontSize14;
	@include flex;
	gap: 1.4rem;
	align-items: center;
	height: 6.8rem;
	padding: 2.4rem;
	cursor: pointer;
	background-color: transparent;
	transition: 300ms;
}

.settingsButton:hover {
	background-color: $neutral100;
	transition: 300ms;
}

.settingsButton:active {
	color: $neutral800;
	transition: 300ms;
}

.settingsButtonSelected {
	background-color: $neutral200;
	color: $neutral600;
}

.emptyContainer {
	@include flexCenter;
	height: 100%;
	width: 100%;
}
