@import './colors.scss';

$minScreenResolution: 1320px;
$sideBarCollapse: 1400px;
$minPricingModelTableColumnWidth: 14rem;

@mixin flexColumn {
	display: flex;
	flex-direction: column;
}

@mixin flex {
	display: flex;
}

@mixin flexColumnAlignItemsCenter {
	@include flexColumn;
	align-items: center;
}

@mixin alignItemsCenter {
	display: flex;
	align-items: center;
}

@mixin justifyContentCenter {
	display: flex;
	justify-content: center;
}

@mixin flexCenter {
	display: flex;
	align-items: center;
	justify-content: center;
}

@mixin flexRowSpaceBetween {
	@include flex;
	align-items: center;
	justify-content: space-between;
}

@mixin flexColumnSpaceBetween {
	@include flexColumn;
	justify-content: space-between;
}

@mixin flexColumnCenter {
	@include flexColumn;
	align-items: center;
	justify-content: center;
}

@mixin overflowHiddenEllipsisNowrap {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

@mixin borderNeutral {
	border: 1px solid $neutral200;
}

@mixin borderPrimary {
	border: 1px solid $primary;
}

@mixin borderSuccess {
	border: 1px solid $success;
}

@mixin verticalDivider {
	border-left: 1px solid $neutral200;
}

@mixin horizontalDivider {
	border-bottom: 1px solid $neutral200;
}

@mixin notEditable {
	opacity: 0.8;
	pointer-events: none;
}

@mixin wordBreak {
	word-break: keep-all;
	word-wrap: break-word;
}

@mixin primaryTag {
	@extend .fontSize14;
	color: $primary;
	padding: 0.2rem 1rem;
	background-color: $primary100;
	margin-right: 0.5rem;
	border-radius: 0.03rem;
}

@mixin defaultBoxShadow {
	@include borderNeutral;
	background-color: $white;
}

@mixin primary200BoxShadow {
	box-shadow: 0rem 0.1rem 0.5rem 0rem $success200;
}
