@import '../../constants/colors.scss';
@import '../../constants/global.scss';
@import '../../constants/mixins.scss';

.modalContainer {
	width: 120rem;
	height: 73rem;
	max-height: calc(100vh - 10rem);
	height: 64rem;
	max-width: 120rem;
}

.header {
	@include flexCenter;
	@include horizontalDivider;
	height: 10rem;
	width: 100%;
	justify-content: space-between;
	padding: 2.4rem;
}

.headerButtons {
	@include flexCenter;
	gap: 1rem;
}

.text {
	color: $neutral;
	@extend .fontWeight250;
}

.fileScrollingContainer {
	height: calc(100% - 20rem);
	width: 100%;
	overflow-x: scroll;
	overflow-y: hidden;
	display: grid;
	grid-template-rows: 5rem 5rem 100%;
}

.fileContainer {
	height: 100%;
	min-width: 1185px;
}

.footer {
	height: 10rem;
	width: 100%;
	@include flexCenter;
	border-top: 1px solid $neutral200;
	gap: 2.4rem;
	justify-content: space-between;
	padding: 2.4rem;
}

.importButton {
	width: 16rem;
	height: 4.8rem;
}

.cancelButton {
	height: 4.8rem;
	width: 10.6rem;
}

.customerOptionsColumns {
	@include flex;
	@include horizontalDivider;
	@extend .neutralFontSize12;
	height: 4.4rem;
	display: flex;
	width: 100%;
}

.errorSwitch {
	width: 23rem;
}

.customerOptionsColumnsItems {
	@include flex;
	border-right: 1px solid $neutral200;
	padding: 1.2rem;
	align-items: center;
	min-width: 13rem;
	&:nth-child(2) {
		color: transparent;
		min-width: 9rem;
	}
	&:nth-child(3) {
		min-width: 12rem;
	}
}

.emptyBox {
	border-right: 1px solid $neutral200;
	min-width: 6rem;
	@include flex;
	align-items: center;
}

.matchYourColumns {
	@extend .fontSize12;
	@extend .fontWeight650;
	padding: 2.4rem 2.4rem 1rem 2.4rem;
	width: 100%;
	text-transform: uppercase;
}

.hideButton {
	height: 4.8rem;
}

.rowsFound {
	@extend .fontSize14;
	color: $primary;
}

.dot {
	@include flexCenter;
	@extend .fontSize14;
	color: $neutral;
	width: 4.2rem;
}

.rowsMissingData {
	@extend .fontSize14;
	color: $destructive;
}

.switch {
	height: 2.4rem;
	width: 3.9rem;
	margin-right: 0.4rem;
}

.rowsMissingDataAppear {
	@include flexCenter;
	width: 17rem;
	opacity: 1;
	@include overflowHiddenEllipsisNowrap;
	transition: 400ms;
}

.rowsMissingDataDespair {
	@include flexCenter;
	@include overflowHiddenEllipsisNowrap;
	width: 0;
	opacity: 0;
	overflow: hidden;
	transition: 400ms;
}

.title {
	@extend .fontWeight650;
}
