@import '../../../constants/colors.scss';
@import '../../../constants/mixins.scss';
@import '../../../constants/global.scss';

.container {
	@extend .appearAnimation;
	height: 100%;
}

.tableContainer {
	height: 100%;
	border: none;
}

.avatarContainer {
	width: 2rem;
	max-width: 2rem;
}
