@import '../../../../constants/colors.scss';
@import '../../../../constants/mixins.scss';
@import '../../../../constants/global.scss';

.container {
	height: calc(100% - 6rem);
}

.searchBar {
	border-radius: 0;
	border-color: $white $white $neutral200 $white;
	width: 100%;
}

.header {
	@include flex;
	height: 4.4rem;
	width: 100%;
	@include horizontalDivider;
}

.headerItem {
	@include flex;
	@extend .fontSize12;
	text-transform: uppercase;
	color: $neutral700;
	padding: 1.4rem;

	&:nth-child(1) {
		width: 20rem;
		max-width: 20rem;
	}
	&:nth-child(2) {
		width: 13.2rem;
	}

	&:nth-child(3) {
		width: 12.4rem;
	}
	&:nth-child(4) {
		width: 12.2rem;
	}
}

.secondaryHeader {
	color: $neutral400;
	@extend .fontSize12;
}

.periodCell {
	flex: 3.7;
}

.cell {
	flex: 2.5;
}

.lastCell {
	flex: 2.5;
}

.listContainer {
	height: calc(100% - 5rem);
	overflow: scroll;
}

.emptyState {
	height: 60%;
}
