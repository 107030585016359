@import '/src/constants/colors';
@import '/src/constants/mixins';
@import '/src/constants/global';

.pricingModelSetting {
	margin-bottom: 1.4rem;
}

.settingsTitle {
	@extend .fontSize18;
	@extend .fontWeight450;
}

.cellProperty {
	@include flex;
	gap: 0;
	margin-top: 0.6rem;
}

.columnPropertySelect {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.input {
	width: 100%;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-left: none;
}

.inputClassName {
	overflow: auto;
	text-overflow: unset;
	white-space: unset;
}
