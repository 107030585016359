@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.sidebar {
	position: fixed;
	right: 0;
	background-color: $white;
	z-index: 30;
	top: 0.1rem;
	@extend .sidebarTransition;
	@extend .sidebarSize;
}

.sidebarContent {
	min-height: 700px;
	height: 100%;
}

.sidebar:active {
	@extend .sidebarTransition;
}

.sidebarHidden {
	width: 0;
	height: 100%;
	position: fixed;
	right: -100%;
	overflow: hidden;
	z-index: 50;
	@extend .sidebarTransition;
}

.sidebarHidden:active {
	@extend .sidebarTransition;
}

.secondarySidebar {
	right: 60.2rem;
	height: 100%;
	position: fixed;
	background-color: $white;
	z-index: 20;
	top: 0.1rem;
	@extend .sidebarTransition;
	@extend .sidebarSize;
}

.header {
	padding: 2.4rem;
	height: 11rem;
	@include flex;
	align-items: center;
	justify-content: space-between;
}

.detailsRowContainer {
	padding: 0 2.4rem;
}

.title {
	@extend .fontSize28;
	@extend .fontWeight850;
	color: $neutral800;
	display: block;
	padding-bottom: 1.4rem;
}

.subTitle {
	@extend .fontSize14;
	color: $neutral900;
	display: block;
}

.detailsRow {
	margin-bottom: 1.4rem;
}

.invoiceNumberDetailsRow {
	@include alignItemsCenter;
	margin-bottom: 1.4rem;
}

.invoiceNumberWarningMessage {
	@include alignItemsCenter;
	color: $warning600;
	background-color: $warning50;
	border-color: $warning50;
	height: 6.8rem;
	padding: 1rem;
}

.invoiceNumberErrorMessage {
	@include alignItemsCenter;
	color: $destructive600;
	background-color: $destructive50;
	border-color: $destructive50;
	height: 6.8rem;
	padding: 1rem;
}

.invoiceNumberWarningText {
	@extend .fontSize12;
	margin: 0 1rem 0 1rem;
	align-items: center;
}

.detailsText {
	@extend .neutralFontSize12;
	display: inline-block;
	width: 9rem;
}

.tabsSection {
	height: calc(100% - 35rem);
	min-height: calc(100% - 35rem);
	overflow: hidden;
}

.tabsContent {
	height: 100%;
}

.grayText {
	@extend .fontSize12;
	@extend .fontWeight250;
	color: $neutral400;
}

.blackText {
	@extend .fontSize12;
	color: $neutral800;
}

.detailsRowStatus {
	display: flex;
	align-items: center;
}

.ellipsis {
	@include overflowHiddenEllipsisNowrap;
	max-width: 33rem;
}

.hamburgerButton {
	width: 2rem;
	transition: 400ms;
}

.hamburgerButtonWrapper {
	margin-left: 0.7rem;
	width: 4rem;
	height: 4rem;
}

.hamburgerRotate {
	transform: rotate(90deg);
	transition: 400ms;
}

.downloadButton {
	margin-right: 0.5rem;
}

.link {
	cursor: pointer;
	&:hover {
		color: $success;
	}
}

.invoiceTabContainer {
	height: calc(100% - 12rem);
}

.greyText {
	@extend .fontSize12;
	color: $neutral400;
}

.tabComponent {
	&:first-child {
		padding-left: 0;
	}
}

.tabsContainer {
	padding: 0px 2.4rem;
}

.statusContainer {
	@include flexCenter;
	gap: 0.4rem;
}

.button {
	min-width: 7.2rem;
	height: 4rem;
	padding: 1rem 1.2rem;
	@extend .fontSize14;
	@extend .fontWeight650;
}

.buttonText {
	margin-top: 0.2rem;
}
