@import '../../../../constants/colors.scss';
@import '../../../../constants/mixins.scss';

.container {
	@include flexCenter;
}

.mainCircle {
	display: flex;
	height: 3.6rem;
	width: 3.6rem;
	border-radius: 2.4rem;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.mainCircle:hover {
	opacity: 80%;
	background-color: $white;
	box-shadow: 0rem 0rem 0rem 0.2rem $primary200;
}

.mainCircle:active {
	box-shadow: 0rem 0rem 0rem 0.5 $primary200;
}

.activeButton {
	@include borderNeutral;
	border-width: 2px;
}

.innerCircle {
	height: 1.2rem;
	width: 1.2rem;
	border-radius: 0.7rem;
	background-color: $neutral200;
}

.activeInnerCircle {
	background-color: $primary;
}

.activeLastButton {
	border-color: $primary;
}

.doneImg {
	width: 1.4rem;
	opacity: 0.2;
}
