@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.container {
	@include flex;
	width: 100%;
	height: 100%;
}

.emptyContainer {
	@include flexCenter;
	width: 100%;
	height: 41rem;
}

.uppercase {
	text-transform: uppercase;
}

.horizontalHeaders {
	@include flexColumn;
	border-right: 2px solid $neutral200;
}

.horizontalHeader {
	@include flex;
	align-items: center;

	padding: 1.6rem 2.4rem;
	@include horizontalDivider;
	&:last-child {
		border-bottom: none;
	}
}

.cell {
	width: 20rem;
	height: 7.3rem;
	@include flex;
	align-items: center;
}

.header {
	@extend .fontSize14;
}

.verticalColumn {
	@include flexColumn;
	background-color: $neutral100;
	width: 20rem;
	padding: 1.2rem 2.4rem;
}

.verticalColumnText {
	@extend .fontSize12;
	color: $neutral600;
	text-transform: uppercase;
}

.columns {
	@include flex;
	border: 1px solid $neutral200;
	border-left: none;
	overflow-x: auto;
	width: calc(100% - 20rem);
}

.data {
	@extend .fontSize14;
	@extend .fontWeight250;
	padding: 1.6rem 2.4rem;
	border-bottom: 1px solid $neutral200;
}

.columns::-webkit-scrollbar {
	width: 3px;
	height: 8px;
	border-radius: 1rem;
	background-color: $neutral200;
}

.tableEmpty {
	@include flex;
	flex: 1;
}
