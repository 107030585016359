@import 'src/constants/colors';
@import 'src/constants/global';
@import 'src/constants/mixins';

.container {
	padding: 2.4rem 0;
	width: 47.2rem;
	box-shadow: 5px 10px 15px 5px rgba(16, 24, 40, 0.3), 0px 4px 6px -4px rgba(16, 24, 40, 0.3);
}

.closeButton {
	padding: 0 2.4rem;
	@include flex;
	justify-content: flex-end;
	align-items: center;
}

.x_icon {
	height: 1rem;
}

.image {
	width: 15rem;
	height: 15rem;
	margin-bottom: 4rem;
}

.body {
	@include flexColumnCenter;
	padding: 0 2.9rem;
}

.title {
	@extend .fontWeight850;
}

.message {
	@extend .fontWeight250;
	color: $neutral;
}

.messageContainer {
	padding-top: 1rem;
	text-align: center;
}

.buttonsContainer {
	@include alignItemsCenter;
	justify-content: space-around;
	margin-top: 2.4rem;
	width: 100%;
	gap: 1.4rem;
}

.button {
	width: 20rem;
	height: 4.8rem;
}
