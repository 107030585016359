@import '../../../constants/colors.scss';
@import '../../../constants/mixins.scss';
@import '../../../constants/global.scss';

.customersContainer {
	@extend .minScreenSize;
	@extend .appearAnimation;
}

.customersHeaderContainer {
	background-color: $white;
	padding: 0rem 2.4rem;
	transition: 400ms;
}

.customersHeader {
	display: flex;
	justify-content: space-between;
	margin-bottom: 0.5rem;
}

.buttonsContainer {
	display: flex;
	height: 4.8rem;
	gap: 1.4rem;
}

.filterButton {
	color: $neutral700;
}

.customerButtons {
	padding: 1.4rem;
}

.goBackContainer {
	@extend .fontSize14;
	@extend .fontWeight250;
	display: flex;
	align-items: center;
	padding: 1.5rem 0;
	color: $neutral;
}

.goBack {
	@extend .fontSize14;
	padding: 0rem;
	padding-right: 0.5rem;
}

.tabsContent {
	transition: 400ms;
	overflow: hidden;
}

.emptyStateContainer {
	height: calc(100% - 8rem);
}

.ellipsis {
	@include overflowHiddenEllipsisNowrap;
	max-width: 90%;
}

.hamburgerButton {
	width: 4rem;
	@include flexCenter;
}

.tabComponent {
	&:first-child {
		padding-left: 0;
	}
}
