@import '../../../constants/colors.scss';
@import '../../../constants/mixins.scss';
@import '../../../constants/global.scss';

.mainContainer {
	@extend .appearAnimation;
	height: 100%;
}

.tableContainer {
	height: calc(100% - 12.6rem);
}

.blockClassName {
	min-width: 24rem;
}
