@import 'src/constants/colors';
@import 'src/constants/global';
@import 'src/constants/mixins';

.content {
	@extend .minScreenSize;
	@extend .appearAnimation;
	min-width: 1200px;
	background-color: $white;
	position: relative;
	width: 100%;
	height: 100%;
	overflow: scroll;
}

.creditNoteContainer {
	@include flexColumn;
	width: 100%;
	height: 100%;
}

.headerContainer {
	padding: 0 10rem !important;
}

.topContainer {
	@include flexCenter;
	height: 11.6rem;
	border-top: 1px solid $neutral200;
	justify-content: space-between;
}

.customerDetails {
	@include flex;
	height: 6.8rem;
	margin-left: 2.4rem;
}

.primaryButton {
	width: 15.9rem;
	height: 4.8rem;
}

.goBack {
	height: 6.6rem;
	width: 6.6rem;
	background-color: $neutral100;
	border: none;
}

.metadata {
	@include flex;
	padding: 3rem 0 2.2rem 0;
	flex-wrap: wrap;
	row-gap: 1.4rem;
	gap: 2.4rem;
}

.bottomContainer {
	@include flexColumn;
	overflow: hidden;
	height: 100%;
}

.tabsContainer {
	margin: 0 20rem;
}

.tabsContent {
	padding: 2.4rem 20rem !important;
}

.select {
	border: none;
	padding: 0;
	height: 2.5rem;
	input {
		@extend .fontSize18;
		@extend .fontWeight650;
		color: $neutral900;
		height: 2.8rem;
	}
}

.documentNumber {
	width: max-content;
}

.badge {
	margin-top: 0.3rem;
	width: fit-content;
	padding: 0.5rem 1.5rem;
	border-radius: 0.5rem;
}

.actions {
	@include flex;
	gap: 1rem;
}

.goBack {
	height: 6.6rem;
	width: 6.6rem;
	background-color: $neutral100;
	border: none;
}

.eyeButton {
	width: 4.8rem;
	height: 4.8rem;
}

.previewContainerOpen {
	transition: 650ms;
	width: calc(100% - 50rem);
}

.actions {
	@include flex;
	gap: 1.4rem;
}

.downloadButtonStyle {
	margin-right: 6rem;
	height: 4.8rem;
}

.locked {
	pointer-events: none;
	height: inherit;
}

.notAllowedCursor {
	cursor: not-allowed !important;
}

.sidebarHeader {
	margin-bottom: 2rem;
}

.tabComponent {
	color: $neutral700;
	padding: 0 1.6rem;
	width: 12rem;
	height: 4rem;
	@extend .fontSize12;
	@extend .fontWeight450;
}

.mainTabsContainer {
	@include flex;
	justify-content: center;
	padding: 0 0 1.4rem 0;
	@extend .editable;
}

.settingClassName {
	background-color: $neutral50;
	padding: 2.4rem 9rem !important;
}

@media only screen and (max-width: 1400px) {
	.headerContainer {
		padding: 0 9rem;
	}
	.tabsContainer {
		margin: 0 9rem;
	}
	.tabsContent {
		padding: 2.4rem 9rem !important;
	}
	.settingClassName {
		padding: 0 9rem !important;
	}
}
