@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.input {
	position: relative;
	height: 4rem;
	border: 1px solid $neutral200;
	color: $neutral;
	display: flex;
	align-items: center;
	background-color: $white;
	cursor: pointer;
	transition: 100ms;
	padding: 1rem;
}

.inputText {
	@extend .globalInput;
	background-color: $white;
	width: 90%;
	cursor: pointer;
	width: 100%;

	&::placeholder {
		margin-left: 1.5rem;
	}
}

.input:hover {
	border-color: $success300;
	transition: 100ms;
}

.input:focus {
	outline: none;
	border: 1px solid $success;
	@include primary200BoxShadow;
	transition: 100ms;
}

.selectItem {
	@include overflowHiddenEllipsisNowrap;
	height: 3.5rem;
	min-height: 3.5rem;
	display: flex;
	align-items: center;
	padding: 0 1.4rem;
}

.selectItem:hover {
	color: $success;
	background-color: $success50;
	transition: 100ms;
}

.focusedItem {
	color: $success700;
	background-color: $success100;
	transition: 100ms;
}

.selectItem:active {
	color: $success600;
	transition: 100ms;
}

.selected {
	color: $success;
	transition: 100ms;
}

.selectDropdownContainer {
	@extend .fontSize14;
	color: $neutral700;
	z-index: 91;
	background-color: $white;
	box-shadow: 0px 10px 15px -3px rgba(16, 24, 40, 0.1), 0px 4px 6px -4px rgba(16, 24, 40, 0.1);
	border-radius: 0.6rem;
	max-height: 30rem;
	overflow: scroll;
}

.selectDropdownContainerOpen {
	border: 1px solid $neutral200;
	transition: 300ms;
	opacity: 1;
	transition-delay: 100ms;
	transition-property: opacity;
	margin: 0.5rem 0;
}

.selectDropdownClose {
	opacity: 0;
	height: 0;
	overflow: hidden;
	transition: 300ms;
}

.arrowDown {
	width: 15px;
	transition: 400ms;
}

.arrowUp {
	width: 15px;
	transform: rotate(180deg);
	transition: 400ms;
}

.selectOverlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 90;
	opacity: 0.5;
}

.errorContainer {
	border-color: $destructive;
}

.errorContainer:hover {
	border-color: $destructive;
}

.inputIcon {
	width: 20px;
	height: 20px;
	margin-right: 0.5rem;
}

.removeSelectedOption {
	margin-right: 1rem;
	width: 0.5rem;
	height: 0.5rem;
	border-radius: 50%;
	background-color: $neutral100;
}

.empty {
	padding: 2rem;
}

.stripeCardErrors {
	@include flex;
	gap: 0.5rem;
}

.stripeCardError {
	height: 2rem;
	width: fit-content;
	max-width: 25rem;
	min-width: 0rem;
	@include overflowHiddenEllipsisNowrap;
	color: $destructive;
	background-color: $destructive100;
	border-radius: 0.3rem;
	padding: 0 0.8rem;
	@extend .fontSize12;
	@extend .fontWeight450;
}

.stripeIcon {
	width: 100%;
	height: 100%;
}

.iconContainer {
	width: 2.9rem;
	height: 2rem;
	margin-right: 0.5rem;
}

.hidden {
	display: none;
	transition: 350ms;
}

.error {
	@extend .fontSize12;
	@extend .fontWeight450;
	transition: 350ms;
}

.errors {
	margin-right: 0.5rem;
}

.stripeCardItem {
	@include flexCenter;
	justify-content: space-between;
	width: 100%;
}
