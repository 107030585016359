@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.pageContainer {
	@extend .appearAnimation;
	background-color: $neutral50;
	height: 100%;
	width: 100%;
}

.pageHeader {
	width: 100%;
	@include flexCenter;
	justify-content: space-between;
}

.tableContainer {
	height: calc(100% - 19.5rem) !important;
	border: 0;
}

.emptyStateCard {
	height: 100%;
	width: 100%;
	margin-top: 15%;
	@include flex;
	justify-content: center;
}

.emptyState {
	@include borderNeutral;
	width: 30rem;
	height: 30rem !important;
	@include flexCenter;
	cursor: pointer;

	&:hover {
		@include borderPrimary;
	}

	&:active {
		border-color: $primary700;
	}
}

.emptyIcon {
	width: 20rem;
	height: 20rem;
}

.tableRowClassName {
	grid-template-columns: minmax(10rem, 1fr) minmax(10rem, 1fr) minmax(10rem, 1fr) minmax(10rem, 1fr) minmax(18rem, 1fr) minmax(14rem, 1fr) repeat(
			auto-fit,
			minmax(10rem, 1fr)
		);
}
