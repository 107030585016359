@import './constants/colors.scss';
@import './fonts.scss';

.app {
	width: 100vw;
	height: 100vh;
	color: $neutral900;
}

h1 {
	font-family: TWKLausanne650;
	font-size: 3.2rem;
	line-height: 4rem;
}

h2 {
	font-family: TWKLausanne450;
	font-size: 2rem;
	line-height: 2.8rem;
}

::-webkit-scrollbar {
	width: 0;
	height: 0;
	transition: 400ms
}

::-webkit-scrollbar-thumb {
	background: $neutral300;
	border-radius: 1rem;
}

::placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	color: $neutral300;
	opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: $neutral300;
}

::-ms-input-placeholder {
	/* Microsoft Edge */
	color: $neutral300;
}

::-moz-selection {
	/* Code for Firefox */
	color: $neutral600;
	background: $primary200;
}

::selection {
	color: $neutral600;
	background: $primary200;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}
