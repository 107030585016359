@import 'src/constants/colors';
@import 'src/constants/mixins';
@import 'src/constants/global';

.container {
	height: 100%;
	width: 100%;
	overflow: hidden;
}

.title {
	@extend .fontSize18;
  @extend .fontWeight650;
	color: $neutral800;
}

.titleContainer {
	padding: 2.4rem 0 0.4rem 2.4rem;
}

.headerButtons {
	width: 13.1rem;
	height: 4.8rem;
}
.emptyStateContainer {
	@include flexColumn;
	padding: 8rem 2.4rem;
	align-items: center;
}

.addModelIcon {
	height: 14.2rem;
	margin-bottom: 2.8rem;
}

.emptyStateButton {
	width: 48rem;
	height: 4.8rem;
	padding: 1rem;
	margin-bottom: 2.4rem;
}

.emptyTitle {
  @extend .fontSize32;
  @extend .fontWeight850;
	padding-bottom: 0.4rem;
}

.emptySubTitle {
	@extend .fontWeight250;
	color: $neutral;
	width: 47.2rem;
	text-align: center;
	padding-bottom: 1.4rem;
	padding: 2.4rem;
}
