@import '../../../constants/colors.scss';
@import '../../../constants/global.scss';

.buttonsContainer {
	display: flex;
}

.generalGroupButton {
	@extend .fontSize14;
	@extend .fontWeight250;
	@include flexCenter;
	width: fit-content;
	height: 4rem;
	padding: 0 1.4rem;
	cursor: pointer;
	border: 1px solid $neutral300;
}

.activeButtonStyle {
	background-color: $neutral50;
	border-color: $black;
	color: $black;
}

.generalGroupButton:hover {
	border: 2px solid $neutral300;
}

.generalGroupButton:active {
	opacity: 50%;
	box-shadow: 0rem 0rem 0.5rem 0rem $neutral200;
}

.disabledButton {
	opacity: 50%;
	cursor: not-allowed;
	&:hover {
		opacity: 50%;
		background-color: $white;
	}
	&:active {
		opacity: 50%;
		box-shadow: none !important;
	}
}
