@import '../../../constants/colors.scss';

.paginationContainer {
	display: flex;
	align-items: center;
}

.prevNextButton {
	justify-content: center;
	margin: 1.5rem;
}

.arrow {
	width: 0.7rem;
	margin: 0 1rem;
}

.dotsIcon {
	height: 12px;
	width: 12px;
	transform: rotate(90deg);
	margin: 0 1rem;
}
