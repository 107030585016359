@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.modalContainer {
	width: 52rem;
	height: 68.4rem;
	box-shadow: 18px 15px 85px 0px rgba(17, 24, 39, 0.75);
	-webkit-box-shadow: 18px 15px 85px 0px rgba(17, 24, 39, 0.75);
	-moz-box-shadow: 18px 15px 85px 0px rgba(17, 24, 39, 0.75);
	z-index: 52;
}

.modalOverlay {
	z-index: 51;
}

.contentContainer {
	height: calc(100% - 23.2rem);
	padding: 2.4rem;
}

.header {
	display: flex;
	justify-content: space-between;
	height: 11.6rem;
	padding: 2.4rem;
	align-items: center;
	@include horizontalDivider;
}

.groupButton {
	width: 50%;
	margin-bottom: 3.5rem;
}

.title {
	@extend .fontSize20;
	@extend .fontWeight850;
}

.subTitle {
	color: $neutral;
}

.xIcon {
	height: 10px;
}

.footer {
	display: flex;
	justify-content: space-between;
	height: 11.6rem;
	border-top: 1px solid $neutral200;
	padding: 2.4rem;
}

.footerButtons {
	width: 47.2rem;
	height: 4.8rem;
}

.contentTitle {
	@extend .fontSize14;
	padding-bottom: 0.4rem;
}

.snippetContainer {
	position: relative;
	width: 100%;
	min-height: 17rem;
	background-color: $neutral200;
	border-radius: 0.6rem;
	padding: 1.4rem;
}

.snippetContainer:hover {
	color: $neutral;
	@extend .clipboardCursor;
}

.snippetContainer:active {
	color: $neutral700;
	@extend .clipboardCursor;
}

.copyButton {
	position: absolute;
	right: 1rem;
	padding: 0.2rem;
}

.copyButton:active {
	transform: translateY(0.1rem) translateX(0.1rem);
}

.snippetBody {
	margin-left: 1rem;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.nameInput {
	width: 22.4rem;
}

.inputs {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 2.4rem;
}

.snippetHeader {
	@include flex;
	justify-content: space-between;
}

.notConnectedButton {
	padding: 0;
	padding-left: 1rem;
	border-radius: 3rem;
	@extend .fontSize12;
	@extend .clipboardCursor;
}

.connected {
	color: $success;
	@extend .fontSize12;
}

.copyHoverContainer {
	position: relative;
	width: 15rem;
	display: flex;
	justify-content: flex-end;
}

.copied {
	position: absolute;
	color: $white;
	padding: 1rem;
	background-color: $neutral;
	@extend .fontSize12;
	border-radius: 1rem;
	opacity: 0;
	top: 49%;
	right: 50%;
	animation: appear 1.3s ease 1;
	z-index: 200;
}

@keyframes appear {
	0% {
		opacity: 0;
		top: 49%;
	}
	50% {
		opacity: 0.8;
	}
	100% {
		top: 50%;
		opacity: 0;
	}
}
