@import 'src/constants/mixins';
@import 'src/constants/global';

.content {
	@extend .minScreenSize;
	@extend .appearAnimation;
	min-width: 1200px;
	background-color: $white;
	position: relative;
	width: 100%;
	height: 100vh;
	overflow: scroll;
}

.invoiceContainer {
	@include flexColumn;
	width: 100%;
	height: 100%;
}

.topContainer {
	@include flex;
	height: 6.8rem;
	margin-left: 2.4rem;
}

.headerContainer {
	padding: 0 10rem;
}

.bottomContainer {
	@include flexColumn;
	height: inherit;
}

.header {
	@include flex;
	height: 11.6rem;
	border-top: 1px solid $neutral200;
	justify-content: space-between;
	align-items: center;
}

.metadata {
	@include flex;
	padding: 3rem 0 2.2rem 0;
	flex-wrap: wrap;
	row-gap: 1.4rem;
	gap: 2.4rem;
}

.eyeButton {
	width: 4.8rem;
	height: 4.8rem;
}

.invoiceEditor {
	margin-right: 1.4rem;
}

.actions {
	display: flex;
	gap: 1rem;
}

.publishButton {
	height: 4.8rem;
	width: 11.4rem;
	@include flexCenter;
}

.documentNumber {
	@extend .fontSize20;
	@extend .fontWeight650;
}

.hamburgerButton {
	height: 4.8rem;
	width: 4.8rem;
}

.buttonText {
	color: $neutral700;
	@extend .fontSize14;
}

.actionButton {
	width: 12rem;
	height: 4.8rem;
	transition: 350ms;
}

.downloadButton {
	margin-right: 6rem;
}

.goBack {
	height: 6.6rem;
	width: 6.6rem;
	background-color: $neutral100;
	border: none;
}

.locked {
	pointer-events: none;
}

.notAllowedCursor {
	cursor: not-allowed !important;
}

.select {
	border: none !important;
	box-shadow: none !important;
	padding: 0 !important;
	height: 2.5rem !important;
	input {
		@extend .fontSize18;
		@extend .fontWeight650;
		color: $neutral900;
		height: 2.8rem;
	}
}

.hidden {
	display: none;
	transition: 350ms;
}

.tooltip {
	bottom: -6rem;
	right: -5rem;
}

.tooltipContainer {
	width: max-content !important;
	height: max-content !important;
	left: calc(100% - 20.5rem) !important;
	top: calc(100% + 1rem) !important;
	&::before {
		top: -30% !important;
		left: 90%;
	}
}

.tabComponent {
	color: $neutral700;
	padding: 0 1.6rem;
	width: 12rem;
	height: 4rem;
	@extend .fontSize12;
	@extend .fontWeight450;
}

.actionModalSecondaryButton {
	border: 1px solid $neutral200;
}

.mainTabsContainer {
	@include flex;
	justify-content: center;
	padding: 0 0 1.4rem 0;
	@extend .editable;
}

.settingClassName {
	background-color: $neutral50;
	padding: 2.4rem 9rem 0 9rem !important;
}

@media only screen and (max-width: 1400px) {
	.settingClassName {
		padding: 0 9rem;
	}
}

@media only screen and (max-width: 1400px) {
	.headerContainer {
		padding: 0 9rem;
	}
}
