@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.container {
	@include flexColumn;
	height: 100%;
	width: 100%;
	padding: 2.4rem;
}

.box {
	border: 1px solid $neutral200;
}

.wrapper {
	padding: 2.4rem;
	@extend .fontSize18;
	@extend .fontWeight250;
	@include horizontalDivider;
	@include flex;
	justify-content: space-between;
	width: 100%;
}

.label {
	@extend .fontSize14;
	@extend .fontWeight450;
}

.multiline {
	resize: none;
	width: 66rem;
	height: 9.3rem;
	overflow-y: scroll;
	&::placeholder {
		@extend .fontSize14;
		@extend .fontWeight250;
		color: $neutral300;
	}
}

.dunningInputs {
	@include flex;
	gap: 1rem;
	width: fit-content;
	width: 66rem;
}

.playButton {
	width: 4rem;
	height: 4rem;
}

.selectSequence {
	width: 45rem !important;
	height: 4rem !important;
}

.selectSequenceClassName {
	height: 4rem !important;
}

.locked {
	pointer-events: none;
}

.notAllowedCursor {
	cursor: not-allowed !important;
}
