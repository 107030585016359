@import '../../constants/colors.scss';
@import '../../constants/mixins.scss';
@import '../../constants/global.scss';

.container {
	@include flex;
	align-items: center;
	gap: 1rem;
}

.defaultIconStyle {
	@include borderNeutral;
	background-color: $neutral50;
}

.imageIcon {
	width: 4.4rem;
	max-width: 4.4rem;
	height: auto;
}

.logo {
	flex: 0.1;
}

.logoContent {
	@include flexCenter;
	background-color: $neutral50;
	padding: 1rem;
}

.imageIconSmall {
	width: 2rem;
	max-width: 2rem;
}

.logoContainer {
	width: 4.4rem;
	max-width: 4.4rem;
	height: auto;
}

.logoContainerSmall {
	height: 4rem;
	width: 4rem;
}

.name {
	@extend .fontWeight650;
	display: block;
	line-height: 2rem;
}

.description {
	@extend .fontWeight250;
	@extend .fontSize12;
	color: $neutral;
}

.nameSmall {
	@extend .fontSize14;
	display: block;
}

.descriptionSmall {
	@extend .fontWeight250;
	color: $neutral;
}

.noAvatarContainer {
	@extend .fontSize32;
	@extend .fontWeight650;
	color: $white;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: $primary400;
}

.syncedIcon {
	position: absolute;
	height: 2.8rem;
	top: 8.5rem;
	left: 8.5rem;
	border-radius: 6rem;
}

.emails {
	display: inline;
	color: $neutral;
	@extend .fontWeight250;
}

.arrow {
	align-self: center;
	width: 2.4;
	margin-left: 0.8rem;
}

.arrowUp {
	transform: rotate(180deg);
	transition: 400ms;
}

.arrowDown {
	transition: 400ms;
}
