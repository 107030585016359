@import '/src/constants/colors';
@import '/src/constants/mixins';
@import '/src/constants/global';

.container {
	@include flexColumn;
	height: 100%;
	width: 100%;
}

.tableContainer {
	display: flex;
	flex: 1;
	flex-direction: column;
}

.pricingWrapper {
	display: flex;
	padding-top: 2.4rem;
}

.dateContainer {
	display: flex;
	justify-content: space-between;
}

.rightContainer {
	@include flexCenter;
	transition: 100ms;
	@extend .appearAnimation;
	gap: 1.4rem;
}

.leftContainer {
	@include flex;
	transition: 100ms;
	@extend .appearAnimation;
	gap: 1.4rem;
}

.issueDatePicker {
	border-right-color: transparent;
	width: 14rem;
	padding: 1.4rem;
}

.periodSelect {
	padding: 1.4rem 0.8rem;
}

.billingDateSelect {
	width: 14rem;
}

.billingPeriodSelectTitle {
	width: 11.5rem;
}

.activationDatePickerTitle {
	width: 11.5rem;
}

.datePickerContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.tooltipText {
	@extend .fontSize12;
	color: $neutral900;
}

.isCollective {
	@extend .fontSize12;
	color: $neutral;
	margin-right: 0.5rem;
}

.periodContainer {
	@include flexColumn;
	height: 9rem;
	justify-content: center;
}

.isCollectiveContainer {
	@include flexCenter;
	align-self: flex-end;
	margin-bottom: 0.8rem;
}

.rangeDatePicker {
	width: 18rem;
	transition: 300ms;
}

.simpleDatePicker {
	width: 9rem;
	transition: 300ms;
}

.activation {
	margin-right: 1.4rem;
}

.margin {
	margin-right: 0.5rem;
	width: fit-content;
}

.settingsBarContainer {
	@include flexColumn;
	@include horizontalDivider;
	width: 100%;
	justify-content: space-between;
	padding: 2.4rem;
	min-width: 33rem;
	min-height: 16.5rem;
	height: max-content;
	gap: 1.4rem;
}

.sectionContainer {
	border-top: none !important;
	border-right: none !important;
	border-left: none !important;
	@include horizontalDivider;
}

.tableSectionContainer {
	@extend .sectionContainer;
	padding: 0 !important;
}

.tableHeaderContainer {
	padding: 2.4rem 2.4rem 1.4rem 2.4rem !important;
}

.settingsBarContent {
	@include flex;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 1.4rem;
}

.settingsBarTitle {
	@extend .fontSize18;
	@extend .fontWeight250;
	color: $neutral900;
}

.currencyInput {
	padding: 1.4rem;
}

.netTermsSelect {
	padding: 1.4rem 1.6rem;
}

.header {
	@include flexCenter;
	justify-content: space-between;
}

.collectiveButton {
	@extend .fontSize12;
	@extend .fontWeight450;
	@include flexCenter;
	color: $neutral;
	position: absolute;
	right: 0rem;
	bottom: 4.2rem;
	padding: 0.4rem !important;
	border-radius: 0;
	gap: 0.5rem;
}

.rightSideInputs {
	position: relative;
	@include flex;
	align-items: flex-end;
}

.pricingSettings {
	@include flexColumn;
	width: 100%;
	gap: 2.4rem;
	margin-top: 2.4rem;
}

.switchContainer {
	@include flexCenter;
	gap: 0.4rem;
	padding-bottom: 0.4rem;
}

.autoRenewalText {
	@extend .fontSize12;
	color: $neutral;
}

.notEditableContract {
	pointer-events: none;
}

.notAllowedCursor {
	cursor: not-allowed !important;
}
