* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	font-size: 62.6%;
}

body {
	font-family: TWKLausanne450, sans-serif;
	font-size: 1.6rem;
	line-height: 2.4rem;
}

ul {
	list-style-position: inside;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@media only screen and (max-width: 1600px) {
	html {
		font-size: 58%;
	}
}

@media only screen and (max-width: 1300px) {
	html {
		font-size: 56%;
	}
}
