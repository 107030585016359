@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.pageContainer {
	@extend .minScreenSize;
	@extend .appearAnimation;
	@include flexColumn;
	padding: 2.4rem;
	gap: 1.4rem;
	overflow: scroll;
	background-color: $white;
}

.reportSelectClassName {
	background-color: $neutral100;
	height: 2.7rem !important;
	border: 1px solid $neutral300;
}

.reportSelectInputClassName {
	background-color: $neutral100;
	height: 2.4rem !important;
}

.pageHeader {
	@include flexColumn;
	gap: 1.4rem;
}

.rowContainer {
	@include flex;
	gap: 1.4rem;
	height: 36rem;
}

.productPerfRowContainer {
	@extend .rowContainer;
	height: 40rem;
}

.box {
	border: 1px solid $neutral200;
}

.analyticsRowContainer {
	@include flex;
	gap: 1.4rem;
	justify-content: space-between;
}

.analyticsLeftCards {
	@extend .box;
	@include flex;
	width: 40%;
	gap: 2.4rem;
	padding: 1.4rem;
	align-items: center;
}

.analyticsRightCards {
	@include flex;
	width: calc(60% - 1.4rem);
	gap: 1.4rem;
}

.analyticsCard {
	@extend .box;
	flex: 1;
	padding: 1.4rem;
}

.avgPerCustomer {
	@extend .analyticsCard;
	min-width: 22rem;
}

.left {
	width: 40%;
}

.right {
	width: calc(60% - 1.4rem);
}

.headerCol {
	@include flexColumn;
	padding: 2.4rem 2.4rem 1.4rem 2.4rem;
	gap: 1.2rem;
}

.title {
	@extend .fontSize18;
	@extend .fontWeight650;
}

.stackBarGraph {
	height: 19.6rem !important;
}

.productPerformance {
	@extend .box;
	width: 100%;
}

.graphContainer {
	height: 26rem;
	padding: 1.4rem;
}

.divider {
	border: 1px solid $neutral300;
	height: 2.6rem;
	margin: 0 1.4rem;
}

.headerRow {
	padding: 2.4rem 2.4rem 1.4rem 2.4rem;
	border-bottom: 1px solid $neutral200;
	height: 12rem;
}

.filterRow {
	@include flex;
	justify-content: space-between;
	margin-bottom: 0.4rem;
}

.headerCol {
	border-bottom: 1px solid $neutral200;
}

.leftCard {
	width: 50%;
}

@media only screen and (max-width: 1450px) {
	.analyticsLeftCards {
		width: 35%;
	}

	.analyticsRightCards {
		width: calc(65% - 1.4rem);
	}
}
