@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.generalBadge {
	cursor: auto;
	@include flexColumnCenter;
	border-radius: 0;
	padding: 0 0.8rem;
	min-height: 2rem;
	min-width: 9rem;
	width: min-content;
	@extend .fontSize12;
	pointer-events: none;
}
