@import '../../../../../constants/colors.scss';
@import '../../../../../constants/mixins.scss';
@import '../../../../../constants/global.scss';

.container {
	@extend .appearAnimation;
	@include flexColumn;
	justify-content: space-between;
	width: 100%;
	background-color: $white;
	box-shadow: 0px 20px 25px -5px rgba(16, 24, 40, 0.1), -10px 8px 40px -6px rgba(16, 24, 40, 0.1);
	border-radius: 20px;
	min-width: 400px;
	background-color: $white;
}

.errorContainer {
	@include flexCenter;
	width: 100%;
	height: 100%;
	background-color: rgba(17, 24, 39, 0.5);
	border-radius: 20px;
	cursor: pointer;
}

.pdf {
	width: 100% !important;
	height: 100% !important;
	border-radius: 20px;
}

.pagePdf {
	width: 100% !important;
	height: 100% !important;
	min-height: 120px !important;
	min-width: 400px !important;
	border-radius: 20px;
	margin-bottom: 20rem;
	canvas {
		border-radius: 20px;
		width: 100% !important;
		height: 100% !important;
	}
}

@media (max-width: 1300px) {
	.pagePdf {
		canvas {
			border-radius: 20px;
			width: 100% !important;
		}
	}
}
