@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.tabsSection {
	@include flexColumn;
	justify-content: space-between;
	height: calc(100% - 5rem);
	transition: 150ms;
}

.container {
	height: 100%;
	padding: 2.4rem;
	overflow: scroll;
}

.header {
	@include flexCenter;
	width: 100%;
	justify-content: space-between;
	padding-bottom: 2.4rem;
}

.footer {
	padding: 1rem 2.4rem;
}

.cards {
	@include flexColumn;
	gap: 1.4rem;
}
