@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.pageContainer {
	@extend .minScreenSize;
	@extend .appearAnimation;
	background-color: $white;
}

.header {
	padding: 1.5rem 2.4rem;
}

.goBackContainer {
	@extend .fontSize14;
	@extend .fontWeight250;
	display: flex;
	align-items: center;
	color: $neutral;
}

.goBack {
	@extend .fontSize14;
	padding: 0rem;
	padding-right: 0.5rem;
}

.tableContainer {
	height: calc(100% - 9rem);
	border-radius: 0;
}
