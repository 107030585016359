@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.container {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.icon {
	width: 16.7rem;
	margin-bottom: 2.5rem;
}

.title {
	margin-bottom: 2.4rem;
	@extend .fontWeight650;
}

.inputContainerClassName {
	width: 100%;
}

.input {
	@extend .fontSize32;
	@extend .fontWeight450;
	height: 4.8rem;
	border-radius: 0;
	border: none;
	border-bottom: 1px solid $neutral300;
	box-shadow: none;
	text-align: center;
	&::placeholder {
		height: 4.8rem;
		@extend .fontSize32;
		@extend .fontWeight450;
	}
}

.footerButtons {
	width: 100%;
	height: 4.8rem;
	margin-top: 1.8rem;
}
