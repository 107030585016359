@import '../../../constants/colors.scss';
@import '../../../constants/mixins.scss';
@import '../../../constants/global.scss';

.container {
	@extend .minScreenSize;
	@extend .appearAnimation;
	background-color: $white;
}

.header {
	@include flexCenter;
	justify-content: space-between;
	padding: 0rem 2.4rem 2.4rem 2.4rem;
}

.buttonsContainer {
	@include flex;
	height: 4.8rem;
	gap: 1.4rem;
}

.arAging {
	height: calc(100% - 12rem);
}

.goBackContainer {
	@extend .fontSize14;
	@extend .fontWeight250;
	display: flex;
	align-items: center;
	padding: 1.5rem 2.5rem;
	color: $neutral;
}

.goBack {
	@extend .fontSize14;
	padding: 0rem;
	padding-right: 0.5rem;
}

.headerCellClassName {
	&:first-child {
		width: calc(27rem + 1px);
	}
}

.button {
	@extend .fontSize14;
	width: 23rem;
}
