@import 'src/constants/colors';
@import 'src/constants/mixins';
@import 'src/constants/global';

.container {
	@include flex;
	height: calc(100% - 13rem);
	width: 100%;
}

.avatarContainer {
	width: 2rem;
	max-width: 2rem;
}

.tableRowClassName {
	grid-template-columns: minmax(25rem, 1fr) repeat(auto-fit, minmax(1rem, 1fr));
}

.subAccessorClassName {
	@extend .fontSize14;
	color: $neutral;
}

.createButton {
	margin-top: 1.4rem;
}

.greenText {
	color: $success;
}

.graphsCell {
	height: 90%;
	width: 95%;
}
