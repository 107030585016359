@import '../../../constants/colors.scss';
@import '../../../constants/global.scss';
@import '../../../constants/mixins.scss';

.container {
	padding: 0 1.4rem 1.4rem 1.4rem;
}

.productsSubTitle {
	@include flex;
	@extend .fontSize14;
	align-items: center;
	justify-content: space-between;
	margin: 1.4rem 0;
}

.tabContainer {
	@include flex;
	@extend .fontSize14;
	@extend .fontWeight250;
	align-items: center;
	justify-content: space-between;
	height: 5rem;
	border-left: 2px solid $neutral200;
	padding: 0.4rem 1.4rem;
	gap: 1.4rem;
	margin-bottom: 0.8rem;
}

.product {
	@include flex;
	align-items: center;
	gap: 1.4rem;
}

.cube {
	@include flexCenter;
	height: 4rem;
	width: 4rem;
	border-radius: 0.4rem;
	background-color: $primary100;
}

.billingCycle {
	color: $neutral400;
}

.rightColl {
	text-align: end;
}

.stripeTag {
	@include flex;
	align-items: center;
	height: 2rem;
	gap: 0.4rem;
	background-color: $neutral200;
	padding: 0.8rem 0.4rem;
	text-align: end;
}

.stripeIcon {
	height: 1.8rem;
}

.stripeText {
	@extend .fontWeight250;
	padding-top: 0.2rem;
}
