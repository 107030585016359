@import '../../constants/colors.scss';
@import '../../constants/mixins.scss';
@import '../../constants/global.scss';

.footer {
	@include flexColumn;
	gap: 1.4rem;
	margin-top: auto;
	margin-bottom: 1.4rem;
}

.avatar {
	position: relative !important;
}

.userContainer {
	@include overflowHiddenEllipsisNowrap;
	@include flex;
	align-items: center;
	height: 6rem;
	gap: 0.8rem;
	padding-left: 2.3rem;
}

.userName {
	@extend .fontSize14;
	@extend .fontWeight250;
	@include overflowHiddenEllipsisNowrap;
	color: $neutral600;
	display: block;
	width: 11.4rem;
}

.currency {
	@include flexCenter;
	@extend .fontWeight650;
	background-color: $neutral50;
	color: $neutral700;
	height: 2rem;
	width: 4.1rem;
	border-radius: 0.6rem;
	margin-left: 2rem;
}
