@import 'src/constants/colors';
@import 'src/constants/mixins';
@import 'src/constants/global';

.container {
	@include flex;
	height: calc(100% - 13rem);
	width: 100%;
}

.categoriesContainer {
	border-right: 1px solid $neutral200;
	height: 100%;
	width: 25rem;
	min-width: 23.6rem;
}

.pricingListContainer {
	@extend .appearAnimation;
	border-right: 1px solid $neutral200;
	height: 100%;
	width: 40rem;
	min-width: 40rem;
}

.tableContainer {
	background-color: $neutral50;
	width: calc(100% - 65rem);
	padding: 2.4rem;
}

.buttonsContainer {
	@include flex;
	gap: 1.4rem;
	padding: 1.4rem 0;
	justify-content: flex-end;
}
