@import 'src/constants/colors.scss';
@import 'src/constants/global';
@import 'src/constants/mixins';

.container {
	@include flexCenter;
}

.inputContainer {
	width: 11.3rem;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.prefixSign {
	@extend .fontSize18;
	@include borderNeutral;
	@include flexCenter;
	overflow: hidden;
	width: 4.3rem;
	height: 4rem;
	background-color: $neutral50;
	color: $neutral;
	border: 1px solid $neutral200;
	border-right: 0;
}
