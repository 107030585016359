@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.item {
	@include flex;
	height: 7.2rem;
	width: 100%;
	@include horizontalDivider;
}

.item:hover {
	background-color: $success50;
}

.item:active {
	background-color: $success100;
	border-left: 0.5rem $success solid;
}

.activeItem {
	background-color: $success100;
	border-left: 0.5rem $success solid;
}

.cell {
	@include flexCenter;
	@extend .fontSize14;
	@extend .fontWeight250;
	@include flexColumn;
}

.hamburgerContainer {
	width: 3rem;
	@include flexCenter;
}

.periodCell {
	@extend .cell;
	width: 24.6rem;
}

.usageProductCell {
	@extend .cell;
	width: 14.8rem;
}

.usageEllipsis {
	@include overflowHiddenEllipsisNowrap;
	max-width: 14.8rem;
}

.quantityCell {
	@extend .cell;
	width: 15.6rem;
}

.itemCellSubValue {
	@extend .fontSize12;
	@extend .fontWeight250;
	color: $neutral400;
	align-self: flex-start;
	padding-left: 2.4rem;
}

.itemCellValue {
	align-self: flex-start;
	padding-left: 2.4rem;
}
