@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.sidebar {
	position: fixed;
	right: 0;
	background-color: $white;
	z-index: 30;
	top: 0.1rem;
	@extend .sidebarTransition;
	width: 54rem;
	height: 100vh;
	overflow: scroll;
}

.sidebar:active {
	@extend .sidebarTransition;
}

.sidebarHidden {
	width: 0;
	height: 100%;
	position: fixed;
	right: -100%;
	overflow: hidden;
	@extend .sidebarTransition;
}

.sidebarHidden:active {
	@extend .sidebarTransition;
}

.header {
	@include horizontalDivider;
	@include flexCenter;
	justify-content: space-between;
	padding: 2.4rem;
}

.body {
	padding: 1.4rem 2.4rem;
	@include flexColumn;
	gap: 2.4rem;
	overflow: scroll;
	height: calc(100% - 17rem);
	position: relative;
}

.footer {
	height: 6.8rem;
	padding: 1.4rem 2.4rem;
	@include flex;
	gap: 1.4rem;
	border-top: 1px solid $neutral200;
	justify-content: space-between;
}

.duplicate {
	padding: 1rem;
	width: 10rem;
	height: 4rem;
	@extend .fontSize12;
	@extend .fontWeight650;
	color: $neutral700;
	gap: 0.6rem;
}

.actions {
	gap: 1.4rem;
	@include flex;
	height: 4rem;
}

.cancel {
	@extend .fontSize12;
	@extend .fontWeight650;
	color: $neutral700;
}

.save {
	@extend .fontSize12;
	@extend .fontWeight650;
}

.nameInputContainer {
	border-radius: 0.6rem;
	&::placeholder {
		color: $neutral400;
		@extend .fontSize14;
	}
}

.defaultBadge {
	@include flex;
	gap: 0.6rem;
	justify-content: space-between;
	@extend .fontSize12;
}

.defaultLabel {
	width: max-content;
	@extend .fontSize12;
}

.defaultContainer {
	@include flex;
	gap: 0.5rem;
}

.itemList {
	@include flexColumn;
	gap: 1.4rem;
	transition: 400ms;
}

.addButton {
	@include flexCenter;
	@extend .fontSize12;
	@extend .fontWeight650;
	color: $neutral700;
	padding: 0.6rem;
	height: 3.2rem;
	position: relative;
}

.addButtonLabel {
	margin-top: 0.2rem;
}

.arrow {
	transform: rotate(90deg);
	margin-left: 1.3rem;
	@include flex;
}

.itemContainer {
	&:last-child .arrow {
		display: none;
	}
}

.itemContainer {
	@include flexColumn;
	gap: 1.4rem;
}

.hidden {
	visibility: hidden;
}
