@import '../../../constants/colors.scss';
@import '../../../constants/mixins.scss';
@import '../../../constants/global.scss';

.generalContainer {
	cursor: pointer;
	position: relative;
}

.inputContainer {
	display: none;
}

.disable {
	cursor: auto;
}

.activeDrag {
	outline-color: $success700;
	border: 1px dashed $success;
	border-radius: 1rem;
	@include primary200BoxShadow;
}

.generalContainer:focus {
	@include primary200BoxShadow;
	outline-color: $success700;
	border: 1px solid $success;
}

.FileUploaderContainer {
	@extend .fontWeight250;
	@include borderNeutral;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 1.6;
	border-radius: 0.6rem;
	color: $neutral900;
}

.uploadIcon {
	height: 2.6rem;
	padding-bottom: 1.5rem;
}

.fileTypes {
	@extend .fontSize14;
	@extend .fontWeight250;
	color: $neutral;
}

.uploadText {
	color: $success;
}

.imgDragAndDrop {
	color: $neutral;
}

.uploadImgContainer {
	@extend .fontSize12;
	@extend .fontWeight250;
	display: flex;
	align-items: center;
	gap: 1rem;
}

.imgContainer {
	@include borderNeutral;
	@include flexCenter;
	width: 7.8rem;
	height: 7.8rem;
	background-color: $neutral50;
	overflow: hidden;
}

.imgIcon {
	max-width: 7.5rem;
	max-height: 7.5rem;
	width: 7.5rem;
	height: auto;
}

.noImgIcon {
	width: 7.8rem;
	height: 7.8rem;
}

.fileNotSupported {
	position: absolute;
	color: $destructive;
	padding: 1rem;
	border-radius: 1rem;
	opacity: 0;
	bottom: 0%;
	animation: appear 3s ease 1;
	z-index: 200;
}

@keyframes appear {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

.fileWrapper {
	width: fit-content;
}
