.xButton {
	height: 4rem;
	width: 4rem;
}

.xButton:hover .xButtonIcon {
	animation: loop 0.5s linear 1;
	transition: 400ms;
}

.xButton:not(:hover) .xButtonIcon {
	animation: loopBack 0.5s linear 1;
	transition: 400ms;
}

@keyframes loop {
	0% {
		transform: rotate(0deg);
	}
	50% {
		transform: rotate(150deg);
	}
	100% {
		transform: rotate(90deg);
	}
}

@keyframes loopBack {
	0% {
		transform: rotate(90deg);
	}
	50% {
		transform: rotate(-50deg);
	}
	100% {
		transform: rotate(0deg);
	}
}
