@import 'src/constants/colors';
@import 'src/constants/mixins';
@import 'src/constants/global';

.container {
	@include borderNeutral;
	@include flexRowSpaceBetween;
	width: 32.4rem;
	height: 4.8rem;
	border-radius: 0.6rem;
	background-color: $neutral100;
	padding: 1.4rem;
	transition: 400ms;
	margin-bottom: 1rem;
	cursor: grab;
}

.container:hover {
	background-color: $white;
	box-shadow: 0px 10px 15px -3px rgba(16, 24, 40, 0.1), 0px 4px 6px -4px rgba(16, 24, 40, 0.1);
	transition: 400ms;
}

.dot {
	height: 10px;
	width: 10px;
	border-radius: 2rem;
	background-color: $success;
	border: 3px solid $success300;
	margin-right: 0.5rem;
}

.container:active {
	box-shadow: 0px 10px 15px -3px rgba(16, 24, 40, 0.2), 0px 4px 6px -4px rgba(16, 24, 40, 0.2);
}

.addButton {
	padding: 0;
	margin-left: 1rem;
	height: 0;
	width: 0;
	overflow: hidden;
	transition: 400ms;
}

.addButton:hover {
	background-color: $primary200;
}

.container:hover .addButton {
	height: 2.7rem;
	width: 2.7rem;
	border-radius: 1.4rem;
	transition: 400ms;
}

.name {
	@include overflowHiddenEllipsisNowrap;
	width: 19rem;
}
