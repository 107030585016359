@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.button {
	width: fit-content;
	padding: 0.2rem 1rem;
	@extend .fontSize12;
	@extend .fontWeight450;
	@include flexCenter;
	transition: 400ms;
	height: unset !important;
}

.iconContainer {
	opacity: 0;
	transition: 400ms;
	display: none;
}

.button:hover .iconContainer {
	opacity: 1;
	transition: 400ms;
	@include flex;
}

.isShortContainer {
	@include flex;
	@include overflowHiddenEllipsisNowrap;
	width: fit-content;
	min-height: 1.8rem;
	padding: 0.2rem 1rem;
}
