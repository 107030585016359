@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.header {
	@include alignItemsCenter;
	justify-content: space-between;
	@extend .fontSize12;
	color: $neutral700;
	overflow: hidden;
	height: 4rem;
	padding: 0 2.4rem;
	margin-bottom: 0.5rem;
}

.headerWrapper {
	text-transform: uppercase;
	display: flex;
	align-items: center;
	gap: 1.4rem;
}

.addNewItems {
	height: 2rem;
	width: 6rem;
	padding: 0;
	cursor: pointer;
	pointer-events: all;

	&:hover {
		border-color: $success;
		color: $success;
		background-color: $success100;
		transition: 0.4s;
	}
	&:focus {
		border: 0.9px $success300;
		color: $success300;
	}
}

.addNewItemsText {
	@extend .fontSize12;
	line-height: 0.16rem;
}
