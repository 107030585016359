@import '/src/constants/colors';
@import '/src/constants/mixins';
@import '/src/constants/global';

.settingsBarContainer {
	background-color: $white;
	width: 100%;
	@include flexColumn;
	justify-content: space-between;
	border: 1px solid $neutral200;
	padding: 2.4rem;
	min-width: 33rem;
	min-height: 16.5rem;
	height: max-content;
	gap: 1.4rem;
}

.header {
	@include flexCenter;
	justify-content: space-between;
}

.settingsBarTitle {
	@extend .fontSize18;
	@extend .fontWeight250;
}

.needLicenseButton {
	@include flexCenter;
	width: 10rem;
	height: 3.6rem;
}

.settingsBarContent {
	@include flex;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 1.4rem;
}

.disabledFeature {
	opacity: 0.4;
}

.leftContainer {
	@include flex;
	transition: 100ms;
	@extend .appearAnimation;
	gap: 1.4rem;
}

.billingPeriodSelectTitle {
	width: 11.5rem;
}

.periodInput {
	padding: 1.4rem;
}

.rightSideInputs {
	position: relative;
	@include flex;
	align-items: flex-end;
}

.issueDatePicker {
	border-radius: 6px 0 0 6px;
	border-right-color: transparent;
	width: 14rem;
	padding: 1.4rem;
}

.tooltipText {
	@extend .fontSize12;
	color: $neutral900;
}

.periodSelect {
	border-radius: 0px 6px 6px 0px;
	padding: 1.4rem 0.8rem;
}
