@import '../../../../constants/colors.scss';
@import '../../../../constants/mixins.scss';
@import '../../../../constants/global.scss';

.pageContainer {
	@extend .minScreenSize;
	@extend .appearAnimation;
}

.pageHeader {
	padding: 1.4rem 2.4rem;
	gap: 1.4rem;
	width: 100%;
	display: flex;
	justify-content: space-between;
	transition: 650ms;
}

.description {
	@extend .fontWeight250;
	color: $neutral400;
	display: block;
	margin-bottom: 1.4rem;
}

.tableContainer {
	height: calc(100% - 24.6rem);
	border: 0;
	transition: 650ms;
}

.tableWithTagsContainer {
	height: calc(100% - 23rem);
	border: none;
	transition: 650ms;
}

.rightButtonsContainer {
	display: flex;
	flex: 1;
	gap: 0.8rem;
	margin-top: 2.4rem;
	justify-content: flex-end;
}

.filterButton {
	width: 9.7rem;
	color: $neutral700;
}

.search {
	width: 30rem;
}

.summarySection {
	overflow-x: scroll;
	width: 100%;
	height: 14.6rem;
	padding-left: 2.4rem;
	padding-right: 2rem;
}

.blockClassName {
	min-width: 25rem;
	width: 25rem;
}

.mainButton {
	@extend .fontWeight250;
	width: 25rem;
}

.arrowDown {
	width: 15px;
	transition: 400ms;
}

.arrowUp {
	width: 15px;
	transform: rotate(180deg);
	transition: 400ms;
}

.changeStatusTooltipStyle {
	width: 20rem;
}
