@import '../../../../constants/colors.scss';
@import '../../../../constants/global.scss';

.footerContainer {
	@extend .fontSize14;
	@extend .fontWeight250;
	height: 8rem;
	display: flex;
	padding: 0rem 2.4rem;
	border-top: 1px solid $neutral200;
	justify-content: space-between;
	color: $neutral700;
	align-items: center;
	overflow: hidden;
}
