@import '../../../constants/colors.scss';
@import '../../../constants/mixins.scss';
@import '../../../constants/global.scss';

.container {
	@include flex;
	width: 85.5rem;
}

.syncContainer {
	@include flexCenter;
}

.item {
	width: 40rem !important;
	height: 4.4rem;
	transition: 400ms;
	max-width: 40rem !important;
}

.rightItem {
	width: 40rem !important;
	height: 4.4rem;
	max-width: 40rem !important;
}

.deleteButton {
	width: 0;
	opacity: 0;
	padding: 0;
	overflow: auto;
}

.container:hover .deleteButton {
	@include flex;
	overflow: auto;
	opacity: 1;
	background-color: $neutral;
	width: 2.8rem;
	height: 2.8rem;
	border-radius: 3rem;
	z-index: 20;
	left: auto;
	position: absolute;
}

.dot {
	height: 4px;
	width: 4px;
	border-radius: 2px;
	background: $neutral400;
}

.line {
	height: 1px;
	width: 4.6rem;
	background: $neutral400;
}

.SYNCED {
	background: $success;
	box-shadow: 0px 0px 0.5px 2px rgba(34, 197, 94, 0.3);
}

.OUT_OF_SYNC {
	background: $warning;
	box-shadow: 0px 0px 0.5px 2px rgba(255, 204, 0, 0.3);
}
