@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.container {
	@include flexCenter;
	gap: 0.8rem;
	position: relative;
}

.tag {
	@include flex;
	gap: 0.4rem;
}

.dropdownContainer {
	@include flex;
	gap: 0.4rem;
	padding: 1.4rem;
}

.icon {
	height: 2rem;
	width: 2rem;
}

.label {
	color: $neutral700;
	@extend .fontSize12;
	@extend .fontWeight450;
}

.addButton {
	height: 2rem;
	width: 2.4rem;
}

.menuList {
	@include flexColumn;
	position: fixed;
	background-color: $white;
	width: max-content;
	border-radius: 0.6rem;
	box-shadow: 0px 10px 15px -3px rgba(16, 24, 40, 0.1), 0px 4px 6px -4px rgba(16, 24, 40, 0.1);
	overflow: hidden;
	z-index: 90;
	@include borderNeutral;
	@include flex;
	gap: 0.4rem;
	padding: 1.4rem;
}

.menuListHidden {
	border-color: $neutral200;
	background-color: $white;
	position: absolute;
	height: 0rem;
	overflow: hidden;
}

.selectOverlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 5rem;
	width: 100%;
	height: 100%;
	z-index: 50;
}

.errorContainer {
	border: 1px solid $destructive;
}
