@import '../../constants/colors.scss';
@import '../../constants/mixins.scss';
@import '../../constants/global.scss';

.summaryBlockContainer {
	@include defaultBoxShadow;
	display: flex;
	justify-content: space-between;
	height: 15.2rem;
	min-width: 33rem;
	width: 33rem;
	overflow: hidden;
	cursor: pointer;
	padding: 2.4rem;
}

.summaryBlockContainer:hover {
	border: 1px solid $success400;
	background-color: $success50;
}

.summaryBlockContainer:active {
	border: 2px solid $success;
}

.summaryBlockContainerActive {
	border: 2px solid $success;
	&:hover {
		border: 3px solid $success;
		background-color: $success50;
	}
}

.textContainer {
	@include flexColumn;
	justify-content: center;
}

.title {
	@include overflowHiddenEllipsisNowrap;
	@extend .fontSize20;
	@extend .fontWeight250;
	max-width: 23rem;
	display: block;
	color: $neutral700;
}

.subTitle {
	@extend .fontSize14;
	@extend .fontWeight250;
	display: block;
	color: $neutral400;
}

.total {
	@extend .fontSize24;
	@extend .fontWeight250;
	display: block;
	color: $neutral700;
}

.summaryIconContainer {
	display: flex;
	height: 5.2rem;
	width: 5.2rem;
	align-items: center;
	justify-content: center;
	background-color: $success100;
}

.summaryIconContainer:hover {
	border: 1px solid;
}

.connectedProducts {
	@extend .fontSize12;
	@include overflowHiddenEllipsisNowrap;
	max-width: 22rem;
	text-transform: uppercase;
	gap: 0.5rem;
}

.summaryBlockContainer:hover .summaryIcon {
	display: none;
}

.summaryBlockContainer:hover .kebabIconHidden {
	display: flex;
}

.summaryIcon {
	display: flex;
}

.kebabIconHidden {
	display: none;
}

.kebabIconVisible {
	display: flex;
	transform: rotate(90deg);
	transition: 400ms;
}
