@import 'src/constants/colors';
@import 'src/constants/mixins';
@import 'src/constants/global';

.mainContainer {
	height: 100vh;
	width: 100vw;
	z-index: 100;
}

.header {
	@extend .fontSize20;
  @extend .fontWeight850;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 3.6rem;
}

.logoImg {
	height: 3.2rem;
	width: 3.2rem;
}

.icon {
	width: 20.8rem;
	height: 16.3rem;
}

.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 15rem;
}

.title {
	@extend .fontSize32;
  @extend .fontWeight850;
}

.description {
	@extend .fontWeight250;
	color: $neutral;
	width: 47.2rem;
	max-width: 47.2rem;
	text-align: center;
	margin-bottom: 2.4rem;
}

.customer {
	color: $neutral900;
	margin-left: 0.4rem;
	display: inline-flex;
	@include overflowHiddenEllipsisNowrap;
	max-width: 47.2rem;
}

.link {
	color: $success;
	cursor: pointer;
	margin-left: 0.4rem;
}

.buttonsContainer {
	display: flex;
	margin-bottom: 2.6rem;
}

.button {
	width: 16rem;
	height: 4.8rem;
	margin: 0 0.7rem;
}
