@import '../../../../constants/colors.scss';
@import '../../../../constants/mixins.scss';
@import '../../../../constants/global.scss';

.container {
	height: 100%;
}

.searchBar {
	border-radius: 0;
	border-color: $white $white $neutral200 $white;
	width: 100%;
	height: 4.8rem;
}

.header {
	@include flex;
	height: 4.4rem;
	width: 100%;
	@include horizontalDivider;
	padding: 0 5rem 0 2.4rem;
}

.headerItem {
	@include flex;
	@extend .fontSize12;
	@extend .fontWeight650;
	color: $neutral700;
	align-items: center;
	text-transform: uppercase;

	&:first-child {
		width: 27rem;
		justify-content: start;
	}

	&:nth-child(2) {
		width: 18rem;
		justify-content: center;
	}
	&:nth-child(3) {
		width: 19rem;
		justify-content: center;
	}
}

.secondaryHeader {
	@extend .fontSize12;
	@extend .fontWeight650;
	color: $neutral400;
}

.listContainer {
	height: calc(100% - 10rem);
	overflow: scroll;
}
