@import 'src/constants/colors';
@import 'src/constants/mixins';
@import 'src/constants/global';

.container {
	@include flexColumn;
	min-width: 33rem;
	padding: 2.4rem;
	border: 1px solid $neutral200;
	background: $white;
	height: fit-content;
	transition: 400ms;
}

.content {
	@include flex;
	flex-wrap: wrap;
	justify-content: space-between;
	gap: 1.4rem;
	@extend .appearAnimation;
	transition: 400ms;
}

.iconContainer {
	height: 4rem;
	width: max-content;
	@include borderNeutral;
	@include flexCenter;
	box-shadow: 0 0.1rem 0.2rem rgba(16, 24, 40, 0.05);
	cursor: pointer;
	@extend .fontSize14;
	@extend .fontWeight450;
}

.tooltipContainer {
	&:nth-child(2) {
		margin: 0 1rem;
	}
}

.iconContainer:active {
	opacity: 70%;
	transform: translateY(0.1rem) translateX(0.1rem);
}

.iconContainer:hover {
	background-color: $success50;
	border: 1px solid $success300;
}

.selected {
	background-color: $success100;
	color: $success900;
	border: 1px solid $success;
}

.autoChargeLabel {
	@extend .neutralFontSize12;
	@extend .fontWeight450;
	color: $neutral400;
}

.tooltipBlackLabel {
	@include flexColumn;
	@extend .neutralFontSize12;
	@extend .fontWeight450;
}

.tooltipLabel {
	@extend .tooltipBlackLabel;
	color: $white;
}

.subTitle {
	@extend .fontSize14;
	color: $neutral600;
	padding-bottom: 0.8rem;
}

.rowItem {
	width: 100%;
}

.fullWidthContainer {
	width: inherit;
	min-width: 20rem;
}

.tooltip {
	bottom: calc(100% + 8rem) !important;
	left: 95% !important;
}

.tooltip::before {
	top: 100% !important;
	border-top-color: $black !important;
}

.selectHeaderStyle {
	color: $neutral600;
}

.title {
	@extend .fontSize18;
	@extend .fontWeight250;
	margin-bottom: 2.4rem;
}

.noteTitle {
	@extend .fontSize14;
	color: $neutral600;
}

.multiline {
	resize: none;
	width: 100%;
	height: 9.3rem;
	overflow-y: scroll;
}

.paymentMethodSelect {
	height: 4rem;
}

.selectContainer {
	flex: 1;
	@extend .appearAnimation;
}

.appearAnimation {
	@include flexColumn;
	@extend .appearAnimation;
}

.hidden {
	display: none;
	transition: 350ms;
}

.autoCharge {
	@include flex;
	color: $neutral400;
	@extend .fontSize12;
	@extend .fontWeight450;
	gap: 0.4rem;
	position: absolute;
	top: -6.5rem;
	right: 0rem;
	width: max-content;
}

.autoChargeToggle {
	width: 2.6rem;
	height: 1.6rem;
}

.autoChargeToggleDisabled {
	background-color: $success;
	opacity: 0.5;
}

.selectsContainer {
	@include flex;
	position: relative;
	flex: 1;
	transition: transform 0.3s ease;
	@extend .appearAnimation;
}

.fullWidthInput {
	width: 100%;
	min-width: 20rem;
}

.pasteButton {
	padding: 0;
}

.cryptoWalletInputContainer {
	width: 100%;
	transition: 300ms;
	gap: 0.8rem;
}

.stripeAccountItem {
	@include flexCenter;
	gap: 0.5rem;
}
