@import 'src/constants/colors';
@import 'src/constants/mixins';
@import 'src/constants/global';

.header {
	@include horizontalDivider;
	@include flex;
	@extend .appearAnimation;
	padding: 2.4rem;
	justify-content: space-between;
}

.content {
	@extend .appearAnimation;
	@include flexColumn;
	padding: 2.4rem;
}

.entityContainer {
	position: relative;
	border: 1px solid $neutral200;
	width: calc(100% - 0rem);
}

.selectContainer {
	position: unset !important;
}

.detailsClassName {
	padding: 1.4rem;
}

.section {
	@include horizontalDivider;
	@include flex;
	padding: 3.8rem 0;
	gap: 2.4rem;
}

.documentCCSection {
	@extend .section;
	height: 17.8rem;
	align-items: center;
	padding: 1.4rem 0;
}

.descriptionContent {
	@include flexColumn;
	width: 52%;
}

.title {
	@extend .fontWeight650;
}

.desc {
	@extend .fontSize14;
	@extend .fontWeight250;
	color: $neutral;
	margin-top: 0.8rem;
}

.inputTitle {
	@extend .fontSize14;
}

.inputDesc {
	@extend .fontSize12;
	@extend .fontWeight250;
	color: $neutral400;
	margin-top: 0.8rem;
}

.update {
	width: 7.8rem;
}

.inputContentInRow {
	@include flex;
	padding-top: 0.5rem;
	width: 100%;
	justify-content: flex-start;
}

.documentPrefix {
	width: 6rem;
	margin-right: 0.5rem;
	padding: 0 1.4rem;
}

.entityPrefix {
	width: 7rem;
	margin-right: 1rem;
	padding: 0 1.4rem;
}

.inputs {
	@include flexColumn;
	width: 48%;

	input {
		&::placeholder {
			@extend .fontSize14;
			@extend .fontWeight250;
		}
	}
}

.documentCCInput {
	@extend .inputs;
	height: 100%;
}

.documentNumberInput {
	width: inherit;
}

.separator::after {
	content: '-';
	@extend .fontSize14;
	@extend .fontWeight250;
	color: $neutral400;
	margin-right: 0.5rem;
}

.inputBorder {
	border-radius: 0.6rem 0.6rem 0 0;
	border-bottom: 0;
}

.emailRowItem {
	@include borderNeutral;
	@include flex;
	height: 5.2rem;
	justify-content: space-between;
	padding: 0 1.4rem;
}
.userEmailContainer {
	@include flexCenter;
	gap: 1.4rem;
}

.userIconContainer {
	@include flexCenter;
	@include borderNeutral;
	height: 3.6rem;
	width: 3.6rem;
	border-radius: 2rem;
	background-color: $neutral50;
}

.emailRowItemButton {
	width: 3rem;
}

.lastEmailRowItem {
	border-radius: 0 0 0.6rem 0.6rem;
}

.addButton {
	height: 3rem;
}
