@import 'src/constants/colors.scss';
@import 'src/constants/global.scss';
@import 'src/constants/mixins.scss';

.button {
	width: fit-content;
	transition: 400ms;
	height: unset !important;
	padding: 0.2rem 1rem;
	@extend .fontSize12;
	@extend .fontWeight450;
	@include flex;
}

.iconContainer {
	opacity: 0;
	transition: 400ms;
	display: none;
}

.button:hover .iconContainer {
	opacity: 1;
	transition: 400ms;
	@include flex;
}

.isShortContainer {
	display: block;
	@include overflowHiddenEllipsisNowrap;
	width: 7.5rem;
	height: 3.5rem;
	padding: 0.7rem 1rem 1rem 1rem;
}
