@import 'src/constants/colors';
@import 'src/constants/mixins';
@import 'src/constants/global';

.mainContainer {
	display: flex;
	flex: 1;
	height: 76rem;
	width: 122rem;
	max-height: 85vh;
	max-width: 90vw;
}

.leftContainer {
	flex: 1;
	@include flexColumn;
	width: 24.8rem;
}

.rightContainer {
	@extend .appearAnimation;
	@include flexColumn;
	@include verticalDivider;
	flex: 4;
	overflow: hidden;
}

.titleContainer {
	padding: 2.4rem;
	height: 7.6rem;
	@include horizontalDivider;
}

.title {
	@extend .fontSize20;
	@extend .fontWeight650;
	color: $neutral900;
}

.subTitle {
	color: $neutral700;
}

.searchBar {
	width: unset;
	margin: 1.4rem 2.4rem;
}

.pricingModelContainer {
	@include flexColumn;
	height: calc(100% - 8rem);
}

.listContainer {
	height: 100%;
	overflow: scroll;
}

.pricingTableHeader {
	@extend .title;
	@include flex;
	align-items: center;
	justify-content: space-between;
	padding: 2.4rem;
	height: 7.6rem;
	border-bottom: 1px solid $neutral200;
}

.templateList {
	margin-top: 2.4rem;
}

.templateListContainer {
	@include flex;
	height: 100%;
	padding-right: 2.4rem;
}

.pricingListContainer {
	height: calc(100% - 7.6rem);
}

.pricingList {
	overflow: scroll;
}

.tableContainer {
	background-color: $neutral50;
	width: calc(100% - 36rem);
	padding: 2.4rem;
	border-left: 1px solid $neutral200;
}

.buttonsContainer {
	@include flex;
	gap: 1.4rem;
	padding: 1.4rem 0;
	justify-content: flex-end;
}
