@import '../../../constants/colors.scss';
@import '../../../constants/mixins.scss';
@import '../../../constants/global.scss';

.generalButton {
	@extend .fontSize14;
	display: flex;
	padding: 1rem;
	align-items: center;
	justify-content: center;
	text-align: center;
	cursor: pointer;
	gap: 0.6rem;
}

.generalButton:active {
	opacity: 70%;
	transform: translateY(0.1rem) translateX(0.1rem);
	box-shadow: 0rem 0rem 0.5rem 0rem $neutral200;
}

.primary {
	height: 4rem;
}

.secondary {
	height: 4rem;
}

.outlined {
	height: 4rem;
}

// primary button options
.primaryprimaryBackground {
	color: $white;
	background-color: $primary;
	&:hover {
		background-color: $primary600;
	}
	&:focus {
		box-shadow: 0px 0px 0px 4px $primary100;
	}
}

.neutralprimaryBackground {
	color: $white;
	background-color: $neutral;
	&:hover {
		background-color: $neutral600;
	}
	&:focus {
		box-shadow: 0px 0px 0px 4px $neutral100;
	}
}

.successprimaryBackground {
	color: $white;
	background-color: $success;
	&:hover {
		background-color: $success600;
	}
	&:focus {
		box-shadow: 0px 0px 0px 4px $success100;
	}
}

.warningprimaryBackground {
	color: $white;
	background-color: $warning;
	&:hover {
		background-color: $warning600;
	}
	&:focus {
		box-shadow: 0px 0px 0px 4px $warning100;
	}
}

.calmingprimaryBackground {
	color: $white;
	background-color: $calming;
	&:hover {
		background-color: $calming600;
	}
	&:focus {
		box-shadow: 0px 0px 0px 4px $calming100;
	}
}

.destructiveprimaryBackground {
	color: $white;
	background-color: $destructive;
	&:hover {
		background-color: $destructive600;
	}
	&:focus {
		box-shadow: 0px 0px 0px 4px $destructive100;
	}
}

// secondary button options
.primarysecondaryBackground {
	color: $primary;
	background-color: $primary100;
	&:hover {
		background-color: $primary200;
	}
	&:focus {
		box-shadow: 0px 0px 0px 4px $primary50;
	}
}

.neutralsecondaryBackground {
	color: $neutral;
	background-color: $neutral100;
	&:hover {
		background-color: $neutral200;
	}
	&:focus {
		box-shadow: 0px 0px 0px 4px $neutral50;
	}
}

.successsecondaryBackground {
	color: $success700;
	background-color: $success50;
	&:hover {
		background-color: $success200;
	}
	&:focus {
		box-shadow: 0px 0px 0px 4px $success50;
	}
}

.warningsecondaryBackground {
	color: $warning;
	background-color: $warning100;
	&:hover {
		background-color: $warning200;
	}
	&:focus {
		box-shadow: 0px 0px 0px 4px $warning50;
	}
}

.calmingsecondaryBackground {
	color: $calming;
	background-color: $calming100;
	&:hover {
		background-color: $calming200;
	}
	&:focus {
		box-shadow: 0px 0px 0px 4px $calming50;
	}
}
.destructivesecondaryBackground {
	color: $destructive;
	background-color: $destructive100;
	&:hover {
		background-color: $destructive200;
	}
	&:focus {
		box-shadow: 0px 0px 0px 4px $destructive50;
	}
}

// outlined button options
.primaryoutlinedBackground {
	color: $primary;
	background-color: $white;
	border: 1px solid $primary200;
	&:hover {
		background-color: $primary50;
	}
	&:focus {
		color: $primary900;
		background-color: $primary50;
		border: 1px solid $primary300;
	}
}

.neutraloutlinedBackground {
	color: $neutral;
	background-color: $white;
	border: 1px solid $neutral200;
	&:hover {
		background-color: $neutral50;
	}
	&:focus {
		color: $neutral900;
		background-color: $neutral50;
		border: 1px solid $neutral300;
	}
}

.successoutlinedBackground {
	color: $success;
	background-color: $white;
	border: 1px solid $success200;
	&:hover {
		background-color: $success50;
	}
	&:focus {
		color: $success900;
		background-color: $success50;
		border: 1px solid $success300;
	}
}

.warningoutlinedBackground {
	color: $warning;
	background-color: $white;
	border: 1px solid $warning200;
	&:hover {
		background-color: $warning50;
	}
	&:focus {
		color: $warning900;
		background-color: $warning50;
		border: 1px solid $warning300;
	}
}

.calmingoutlinedBackground {
	color: $calming;
	background-color: $white;
	border: 1px solid $calming200;
	&:hover {
		background-color: $calming50;
	}
	&:focus {
		color: $calming900;
		background-color: $calming50;
		border: 1px solid $calming300;
	}
}

.destructiveoutlinedBackground {
	color: $destructive;
	background-color: $white;
	border: 1px solid $destructive200;
	&:hover {
		background-color: $destructive50;
	}
	&:focus {
		color: $destructive900;
		background-color: $destructive50;
		border: 1px solid $destructive300;
	}
}

// link button options
.primarylinkBackground {
	color: $primary600;
	&:hover {
		color: $primary700;
	}
	&:focus {
		color: $primary900;
	}
}

.neutrallinkBackground {
	color: $neutral600;
	&:hover {
		color: $neutral700;
	}
	&:focus {
		color: $neutral900;
	}
}

.successlinkBackground {
	color: $success600;
	&:hover {
		color: $success700;
	}
	&:focus {
		color: $success900;
	}
}

.warninglinkBackground {
	color: $warning600;
	&:hover {
		color: $warning700;
	}
	&:focus {
		color: $warning900;
	}
}

.calminglinkBackground {
	color: $calming600;
	&:hover {
		color: $calming700;
	}
	&:focus {
		color: $calming900;
	}
}

.destructivelinkBackground {
	color: $destructive600;
	&:hover {
		color: $destructive700;
	}
	&:focus {
		color: $destructive900;
	}
}
.disabled {
	pointer-events: none;
	opacity: 50%;
}
