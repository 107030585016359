@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.container {
	@include flexCenter;
	height: 100%;
	width: 100%;
	animation: appear 0.3s linear 1;
}

@keyframes appear {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.textContainer {
	@include flexColumnCenter;
	position: absolute;
	text-align: center;
}

.emptyContainer {
	position: relative;
	@include flexCenter;
	height: 100%;
	width: 100%;
}

.icon {
	width: 100%;
	height: auto;
}

.textContainer {
	position: absolute;
	background-color: rgba($color: #fff, $alpha: 0.3);
}

.emptyGraph {
	width: 12.5rem;
	height: auto;
}

.emptyContainer {
	position: relative;
	@include flexCenter;
	height: 100%;
}

.icon {
	width: 100%;
	height: auto;
}
