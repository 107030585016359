@import '../../../constants/colors.scss';

.checkBox {
	display: flex;
	height: 1.8rem;
	width: 1.8rem;
	font: inherit;
	border: 1.5px solid $neutral400;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.checkBox:hover {
	box-shadow: 0rem 0rem 0.5rem 0rem $success300;
}

.checkBoxActive {
	background-color: $success;
	border-color: $success;
}

.checkBoxIcon {
	width: 90%;
}
