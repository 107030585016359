@import 'src/constants/colors';
@import 'src/constants/global';
@import 'src/constants/mixins';

.input {
	padding-bottom: 1.4rem;
}

.accountInput {
	width: 44rem;
}

.branchInput {
	width: 13.5rem;
}

.accountContainer {
	@include flex;
	justify-content: space-between;
}

.formContent {
	border-radius: 0 0 0.6rem 0.6rem;
	border: 1px solid $white;
	overflow: hidden;
	padding: 0 1.4rem 0 1.4rem;
	height: 0;
}

.isClosed {
	transition: 0.4s;
	border: 1px solid $white;
	height: 0;
}

.isOpen {
	height: 63rem;
	@include borderNeutral;
	padding-top: 2.4rem;
	margin-bottom: 2.4rem;
	transition: 0.4s;
}
