@import '../../../../constants/colors.scss';
@import '../../../../constants/mixins.scss';
@import '../../../../constants/global.scss';

.container {
	height: 45px;
	@include horizontalDivider;
	margin-bottom: 20px;
	margin-right: 10px;
	width: 300px;
	margin: 0 0 15px 15px;
}

.rowContainer {
	display: flex;
	justify-content: space-between;
}

.buttonContainer {
	display: flex;
	height: 20px;
}

.monthName {
	@extend .fontSize20;
	@extend .fontWeight450;
	margin-right: 10px;
}

.yearSelectButton {
	@extend .fontSize20;
	@extend .fontWeight450;
	height: 25px;
	min-height: 25px;
}

.yearSelectButton:focus {
	color: $neutral800;
	padding-right: 4px;
}

.yearSelectOpen {
	display: flex;
	justify-content: space-between;
	height: 305px;
	width: 300px;
	position: absolute;
	top: 54px;
	overflow: hidden;
	background-color: $white;
	transition: 500ms;
}

.yearSelectClose {
	height: 0;
	width: 300px;
	position: absolute;
	top: 54px;
	overflow: hidden;
	background-color: $white;
	transition: 500ms;
}

.optionList {
	width: 50%;
	overflow: scroll;
	height: 305px;
}

.optionItem {
	@extend .fontSize14;
	@extend .fontWeight450;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 133px;
	height: 40px;
	color: $neutral;
	margin-bottom: 2px;
}

.optionItem:hover {
	background-color: $neutral100;
	color: $success800;
}

.selectedOption {
	background-color: $success100;
	color: $success;
	border: 2px solid $success200;
}

.arrowIcons {
	height: 15px;
}

.startText {
	@extend .fontSize12;
	@extend .fontWeight250;
	display: block;
	color: $neutral800;
	margin-top: 15px;
	text-align: start;
}

.downIcon {
	width: 15px;
	margin: 0 0 3px 3px;
}
