@import 'src/constants/colors';
@import 'src/constants/mixins';
@import 'src/constants/global';

.settingsContainer {
	@include flexColumn;
	width: 100%;
	border-bottom: 1px solid $neutral200;
	padding: 2.4rem;
}

.settingsTitle {
	@extend .fontSize18;
	@extend .fontWeight450;
	color: $neutral900;
	padding-bottom: 1.4rem;
}

.select {
	width: 100%;
	height: 4rem;
	background-color: $white;
	margin-bottom: 1.4rem;
}

.settingsContent {
	@include flexColumn;
}

.hidden {
	display: none;
}

.zero {
	color: $neutral400;
}

.radioButtonContainer {
	@include flex;
	gap: 1.4rem;
	align-items: center;
	margin-bottom: 1.4rem;
}

.sameAsBillingContainer {
	@include flexCenter;
	gap: 0.4rem;
	padding-bottom: 0.4rem;
}

.sameAsBilling {
	@extend .fontSize12;
	color: $neutral;
}

.showSelect {
	height: 13.4rem;
	overflow: hidden;
	transition: 400ms;
	opacity: 1;
}

.hideSelect {
	height: 0;
	overflow: hidden;
	opacity: 0;
	transition: 400ms;
}
