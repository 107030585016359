@import '../../../constants/colors.scss';
@import '../../../constants/mixins.scss';
@import '../../../constants/global.scss';

.container {
	@extend .minScreenSize;
	@extend .appearAnimation;
	background-color: $white;
}

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0rem 2.4rem 2.4rem 2.4rem;
}

.buttonsContainer {
	display: flex;
	height: 4.8rem;
}

.tableContainer {
	height: calc(100% - 11rem);
	@extend .appearAnimation;
}

.goBackContainer {
	@extend .fontSize14;
	@extend .fontWeight250;
	display: flex;
	align-items: center;
	padding: 1.5rem 2.5rem;
	color: $neutral;
}

.goBack {
	@extend .fontSize14;
	padding: 0rem;
	padding-right: 0.5rem;
}