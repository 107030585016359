@import '../../constants/colors.scss';
@import '../../constants/mixins.scss';
@import '../../constants/global.scss';

.container {
	@extend .minScreenSize;
	@extend .appearAnimation;
	@include flex;
	overflow: scroll;
	height: 100vh;
}
