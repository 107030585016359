@import '../../../../constants/colors.scss';
@import '../../../../constants/mixins.scss';
@import '../../../../constants/global.scss';

.leftContainer {
	width: 50%;
	background-color: $white;
	padding: 2.4rem;
}

.header {
	@include flexRowSpaceBetween;
	padding-bottom: 2.4rem;
}

.leftContainerHeader {
	@include alignItemsCenter;
}

.headerIcon {
	@include flexCenter;
	@include borderNeutral;
	background-color: $neutral50;
	width: 6.2rem;
	height: 6.2rem;
	border-radius: 0.5rem;
	margin-right: 1.3rem;
}

.subTitle {
	color: $neutral;
}

.formatContainer {
	height: calc(100% - 22rem);
	overflow: scroll;
	padding-bottom: 2.4rem;
}

.formatContainerSubTitles {
	@include flexRowSpaceBetween;
	height: 4rem;
	margin-top: 3.2rem;
}

.buttonsContainer {
	@include flexColumn;
	gap: 1.4rem;
}

.buttons {
	height: 4.8rem;
}

.columnsContainer {
	@include flex;
}

.columns {
	@include borderNeutral;
	background-color: $neutral50;
	border-radius: 0.8rem;
	padding: 2.4rem;
	width: calc(100% - 30rem);
}

.columnsSeparator {
	@include flexColumn;
	align-items: center;
	width: 6.4rem;
}

.dot {
	background-color: $neutral200;
	height: 0.5rem;
	width: 0.5rem;
	border-radius: 0.3rem;
}

.line {
	width: 1px;
	background-color: $neutral200;
}

.circle {
	@include borderNeutral;
	@include flexCenter;
	height: 3.4rem;
	width: 2rem;
	border-radius: 1rem;
	transition: 200ms;
}

.circle:hover {
	width: 3.6rem;
	transition: 200ms;
}

.circle:hover .arrowRight {
	display: none;
}

.arrowsContainer {
	display: none;
	justify-content: center;
	width: 100%;
	padding: 0.2rem;
	transition: 400ms;
	gap: 0.2rem;
}

.circle:hover .arrowsContainer {
	display: flex;
}

.arrowUp {
	transform: rotate(270deg);
}

.arrowDown {
	transform: rotate(90deg);
}

.arrowButton {
	padding: 0;
}

.columnsTitle {
	@extend .fontSize14;
	color: $neutral600;
	text-transform: uppercase;
	padding-bottom: 1.4rem;
}

.selectInputContainer {
	opacity: 1 !important;
	margin-bottom: 1.4rem;
	background-color: $white;
	color: $neutral;
	width: 100%;
	transition: 400ms;
}

.input {
	color: $neutral400;
}

.addItemButton {
	@extend .fontSize12;
	padding: 0;
	width: fit-content;
}

.inputContainer {
	@include flex;
}

.deleteItemButton {
	padding: 1rem 0 0 0;
	height: 4rem;
	width: 0;
	overflow: hidden;
	transition: 400ms;
}

.inputContainer:hover .deleteItemButton {
	display: flex;
	width: 3.5rem;
	padding: 1rem 0 0 1.4rem;
	transition: 400ms;
}

.selectItem {
	@include flexRowSpaceBetween;
	height: 100%;
	width: 100%;
}

.selectItemButton {
	opacity: 0;
	padding: 0;
	transition: 300ms;
}

.selectItem:hover .selectItemButton {
	opacity: 1;
	transition: 300ms;
}

.actionsButtons {
	@include flexCenter;
	color: $neutral300;
	font-size: 1.4rem;
}

.moreButton {
	color: $neutral900;
}

.actionModalSecondaryButton {
	color: $destructive;
}

.errorText {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	max-width: 50rem;
	white-space: break-spaces;
}
