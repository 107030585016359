@import 'src/constants/colors';
@import 'src/constants/global';
@import 'src/constants/mixins';

.integrationIdBox {
	width: 16.1rem;
	max-width: 16.1rem;
	height: 3.2rem;
	padding: 0.6rem;
	border: 1px solid $neutral200;
	@include flex;
	justify-content: space-between;
	@extend .fontSize14;
	@extend .fontWeight450;
	color: $neutral700;
	background-color: $white;
	&:hover {
		.copy {
			display: flex;
		}
		.editIconWrapper {
			display: none;
		}
	}
}

.integrationIdInput {
	&::placeholder {
		@extend .fontSize14;
		@extend .fontWeight450;
		color: $neutral700;
	}
}

.integrationIdBox {
	width: 16.1rem;
}

.copyButton {
	padding: 0.1rem;
	background-color: $white;
	box-shadow: -10px 0px 10px 1px $white;
}

.copy {
	display: none;
}

.leftIntegrationContentBox {
	@include flex;
	gap: 0.4rem;
	max-width: 12.5rem;
	width: 12.5rem;
}

.editIconWrapper {
	background-color: $white;
	padding: 0 0.2rem;
}

.value {
	max-width: 10.5rem;
}
