@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.input {
	@include horizontalDivider;
	position: relative;
	height: 4rem;
	color: $neutral;
	display: flex;
	align-items: center;
	background-color: $white;
	cursor: pointer;
	transition: 100ms;
	padding: 1rem;
}

.fullBorder {
	@include borderNeutral;
}

.inputMediumInputContainer {
	height: 4rem !important;
}

.inputText {
	@extend .globalInput;
	background-color: $white;
	caret-color: transparent;
	width: 90%;
	cursor: pointer;
	width: 100%;

	&::placeholder {
		margin-left: 1.5rem;
	}
}

.input:hover {
	border-color: $success300;
	transition: 100ms;
}

.input:focus {
	outline: none;
	border: 1px solid $success;
	@include primary200BoxShadow;
	transition: 100ms;
}

.selectItem {
	@include overflowHiddenEllipsisNowrap;
	height: 3.5rem;
	min-height: 3.5rem;
	display: flex;
	align-items: center;
	padding: 0 1.4rem;
}

.selectInputContainer {
	@extend .selectItem;
	border: none !important;
	box-shadow: none !important;
}

.selectItem:hover {
	color: $success;
	background-color: $success50;
	transition: 100ms;
}

.focusedItem {
	color: $success700;
	background-color: $success100;
	transition: 100ms;
}

.selectItem:active {
	color: $success600;
	transition: 100ms;
}

.selected {
	color: $success;
	transition: 100ms;
}

.selectDropdownContainer {
	@extend .fontSize14;
	color: $neutral700;
	z-index: 91;
	background-color: $white;
	box-shadow: 0px 10px 15px -3px rgba(16, 24, 40, 0.1), 0px 4px 6px -4px rgba(16, 24, 40, 0.1);
	border-radius: 0.6rem;
	max-height: 30rem;
	overflow: scroll;
}

.selectDropdownContainerOpen {
	border: 1px solid $neutral200;
	transition: 300ms;
	opacity: 1;
	transition-delay: 100ms;
	transition-property: opacity;
}

.selectDropdownClose {
	opacity: 0;
	height: 0;
	overflow: hidden;
	transition: 300ms;
}

.arrowDown {
	width: 15px;
	transition: 400ms;
}

.arrowUp {
	width: 15px;
	transform: rotate(180deg);
	transition: 400ms;
}

.selectOverlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 90;
	opacity: 0.5;
}

.addButton {
	height: 4.2rem;
	width: 100%;
	justify-content: flex-start;
}

.errorContainer {
	border-color: $destructive;
}

.errorContainer:hover {
	border-color: $destructive;
}

.disabledInput {
	pointer-events: none;
	caret-color: transparent;
}

.inputIcon {
	width: 20px;
	height: 20px;
	margin-right: 0.5rem;
}

.net {
	margin-right: 0.4rem;
}
