@import 'src/constants/colors';
@import 'src/constants/mixins';
@import 'src/constants/global';

.containerOpen {
	height: 27.4rem;
	overflow: hidden;
	transition: 400ms;
	background-color: $neutral50;
	border-bottom: 4px solid $neutral300;
}

.containerClose {
	height: 7.8rem;
	overflow: hidden;
	transition: 400ms;
	background-color: $white;
	border-bottom: 1px solid $neutral300;
}

.header {
	@include flexRowSpaceBetween;
	height: 7.8rem;
	padding: 0 2.4rem 0 2.4rem;
	cursor: pointer;
}

.iconContainer {
	@include flexCenter;
	width: 5rem;
	height: 5rem;
	background-color: $neutral200;
	border: 1px solid $neutral300;
	border-radius: 0.6rem;
	margin-right: 1.4rem;
}

.containerOpen .header:hover {
	background-color: $neutral100;
	transition: 400ms;
}
.containerOpen .header:hover .iconContainer {
	background-color: $neutral100;
	transition: 400ms;
}

.arrowDown {
	transition: 600ms;
	transform: rotateX(180deg);
}

.arrowUp {
	transition: 600ms;
}

.list {
	padding: 0.8rem 2.4rem 2.4rem 2.4rem;
}
