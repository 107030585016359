@import 'src/constants/colors';
@import 'src/constants/global';
@import 'src/constants/mixins';

.container {
	@include flexColumn;
	height: 100%;
	height: fit-content;
	width: 100%;
	padding: 2.4rem;
	align-items: center;
	gap: 2.4rem;
	@extend .editable;
	cursor: auto;
}

.revRecContainer {
	min-height: 22rem;
}

.contractDetails {
	@include flex;
	width: 100%;
	gap: 2.4rem;
}

.box {
	@include flex;
	background-color: $white;
	border: 1px solid $neutral200;
	height: fit-content;
	padding: 2.4rem;
	gap: 2.4rem;
}

.fullBox {
	width: 100%;
	@include flexColumn;
	padding: 0;
	gap: 0;
	max-height: 50rem;
}

.leftBox {
	@include flex;
	flex: 1.2;
	height: 100%;
	flex-wrap: wrap;
}

.rightBox {
	@include flex;
	flex: 1.8;
	height: 100%;
	flex-wrap: wrap;
}

.boxContent {
	@include flexColumn;
	width: fit-content;
}

.minWidthBox {
	@include flexColumn;
	width: fit-content;
	min-width: 10rem;
}

.title {
	color: $neutral;
	@extend .fontWeight450;
}

.text {
	@extend .fontSize24;
	@extend .fontWeight250;
	line-height: 3.2rem;
}

.decimalNumber {
	position: relative;
}

.decimalNumber::after {
	@extend .fontSize12;
	@extend .fontWeight250;
	opacity: 0.6;
}

.bold {
	@extend .fontWeight650;
	color: $neutral900;
}

.boldTitle {
	@extend .fontSize24;
}

.topInfo {
	@include flex;
	justify-content: space-between;
	width: 100%;
	align-items: center;
}

.topInfoContainer {
	@include flexColumn;
	justify-content: space-between;
	row-gap: 2.4rem;
	width: 100%;
	border-bottom: 1px solid $neutral200;
	padding: 2.4rem 2.4rem 1.4rem 2.4rem;
}

.bottomInfoContainer {
	width: 100%;
	padding: 0 2.4rem 0 2.4rem;
	@include flex;
}

.timeFilter {
	@include flexCenter;
	gap: 1.4rem;
}

.dates {
	@extend .fontSize14;
	@extend .fontWeight650;
	color: $neutral;
}

.leftBottomInfo {
	@include flexColumn;
	border-right: 1px solid $neutral200;
	width: 70%;
	padding: 2.4rem 2.4rem 2.4rem 0;
	height: 100%;
	justify-content: space-between;
}

.emptyState {
	justify-content: start;
	gap: 3rem;
}

.rightBottomInfo {
	width: 30%;
	padding: 2.4rem 0 2.4rem 2.4rem;
}

.productDataContainer {
	@include flex;
	width: 100%;
	height: 6rem;
	max-height: 6rem;
	gap: 2.4rem;
}

.select {
	padding: 1.4rem;
}

.notSelectedProduct {
	.productName {
		color: $neutral400;
	}
	.productDescription {
		color: $neutral400;
	}
}

.middleLeft {
	width: 50%;
	border-right: 1px solid $neutral200;
	padding: 2.4rem 2.4rem 2.4rem 0;
	@include flexColumn;
}

.middleRight {
	width: 50%;
	padding: 2.4rem 0 2.4rem 2.4rem;
	@include flexColumn;
}

.needLicense {
	align-self: center;
	@include flexColumnCenter;
	z-index: 10;
	position: absolute;
	width: max-content;
	gap: 1.4rem;
	padding: 7.2rem 0;
	.needLicenseButton {
		width: 24rem;
		height: 4.8rem;
		margin-top: 1.4rem;
	}
}

.noDataImage {
	width: 17rem;
	height: 17rem;
}

.noDataTitle {
	@extend .fontSize32;
}

.noDataDesc {
	@extend .fontWeight250;
	color: $neutral;
}

.needLicenseBox {
	opacity: 0.1;
}

.emptyState {
	@include flexColumn;

	.topEmptyState {
		padding: 2.4rem;
		border-bottom: 1px solid $neutral200;
	}

	.emptyStateBody {
		@include flexColumnCenter;
		padding: 10rem 2.4rem;
	}
}

.hidden {
	visibility: hidden;
}

.linearGradient {
	background: linear-gradient(90deg, #b0bacc 0%, rgba(176, 186, 204, 0) 100%);
	border-radius: 0.3rem;
	width: 13.9rem;
	color: transparent;
}

@media only screen and (max-width: 1400px) {
	.container {
		padding: 2.4rem 13rem;
	}
}

@media only screen and (max-width: $minScreenResolution) {
	.container {
		padding: 2.4rem 9rem;
	}
}
