@import 'src/constants/colors';
@import 'src/constants/mixins';
@import 'src/constants/global';

.mainContainer {
	@extend .minScreenSize;
	@extend .appearAnimation;
	background-color: $white;
}

.pageHeader {
	padding: 3.4rem 2.4rem 0 2.4rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.newProduct {
	width: 7.5rem;
	gap: 0.6rem;
}

.name {
	@extend .fontWeight650;
	color: $destructive;
}

.tabsContainer {
	padding: 0 2.4rem;
	gap: 1.4rem;
}

.tabComponent {
	padding: 1.4rem 0.4rem;
}

.headerButtons {
	@include flex;
	gap: 1.4rem;
}

.addIcon {
	margin-bottom: 0.2rem;
}
