@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.box {
	@include flexColumn;
	background-color: $white;
	border: 1px solid $neutral200;
	height: fit-content;
	position: relative;
	width: 100%;
	padding: 0;
	gap: 0;
}

.title {
	color: $neutral;
	@extend .fontWeight450;
}

.text {
	@extend .fontSize24;
	@extend .fontWeight250;
	line-height: 3.2rem;
}

.decimalNumber {
	position: relative;
}

.decimalNumber::after {
	@extend .fontSize12;
	@extend .fontWeight250;
	opacity: 0.6;
}

.boldTitle {
	@extend .fontSize24;
}

.topInfo {
	@include flex;
	justify-content: space-between;
	width: 100%;
	align-items: center;
}

.topInfoContainer {
	@include flexColumn;
	justify-content: space-between;
	row-gap: 2.4rem;
	width: 100%;
	border-bottom: 1px solid $neutral200;
	padding: 2.4rem 2.4rem 1.4rem 2.4rem;
}

.bottomInfoContainer {
	width: 100%;
	@include flex;
	overflow: hidden;
}

.timeFilter {
	@include flexCenter;
	gap: 1.4rem;
}

.dates {
	@extend .fontSize14;
	@extend .fontWeight650;
	color: $neutral;
}

.select {
	padding: 1.4rem;
}

.middleLeft {
	width: 50%;
	border-right: 1px solid $neutral200;
	padding: 2.4rem 2.4rem 2.4rem 0;
	@include flexColumn;
}

.middleRight {
	width: 50%;
	padding: 2.4rem 0 2.4rem 2.4rem;
	@include flexColumn;
}

.needLicenseBox {
	opacity: 0.1;
}

.hidden {
	visibility: hidden;
}

@media only screen and (max-width: 1400px) {
	.container {
		padding: 2.4rem 13rem;
	}
}

@media only screen and (max-width: $minScreenResolution) {
	.container {
		padding: 2.4rem 9rem;
	}
}
