@import '../../../../../constants/colors.scss';
@import '../../../../../constants/mixins.scss';
@import '../../../../../constants/global.scss';

.modalContainer {
	@include flexColumnCenter;
	width: 41.1rem;
	padding: 2.4rem;
}

.icon {
	width: 16.7rem;
}

.title {
	@extend .fontWeight650;
	margin-bottom: 2.4rem;
}

.inputContainerClassName {
	width: 100%;
	margin-bottom: 2.4rem;
}

.input {
	@extend .fontSize32;
	@extend .fontWeight650;
	height: 4.8rem;
	border-radius: 0;
	border: none;
	border-bottom: 1px solid $neutral300;
	box-shadow: none;
	text-align: center;
	&::placeholder {
		height: 4.8rem;
		@extend .fontSize32;
		@extend .fontWeight650;
	}
}

.footerButtons {
	width: 100%;
	height: 4.8rem;
}
