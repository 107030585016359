@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.container {
	@include flex;
	overflow: scroll;
	min-height: 3rem;
	overflow: scroll;
}
