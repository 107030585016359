@import '../../../constants/colors.scss';
@import '../../../constants/mixins.scss';
@import '../../../constants/global.scss';

.sidebar {
	@include flexColumn;
	width: 24.8rem;
	min-width: 24.8rem;
	background-color: $sideBarBackground;
	overflow: hidden;
	padding: 2.4rem;
	justify-content: space-between;
}

.iconContainer {
	@include flex;
	justify-content: space-between;
	align-items: flex-start;
}

.cardIcon {
	width: 6.2rem;
	height: 6.2rem;
	margin-bottom: 1.4rem;
}

.title {
	@extend .fontSize20;
	width: 24.8rem;
}

.publishButton {
	height: 4.8rem;
	margin-bottom: 2.4rem;
}

.badge {
	background-color: $success900;
	color: $success400;
	@extend .fontSize12;
	@extend .fontWeight450;
	border-radius: 0.5rem;
}

.header {
	@include flexColumn;
	gap: 2.4rem;
}

.success {
	@extend .fontSize14;
	@extend .fontWeight250;
	color: $success !important;
	margin-top: 0.3rem;
}

.iconWrapper {
	@include flex;
	width: 6.5rem;
	height: 6.5rem;
	background-color: $white;
}

.descriptionContent {
	@include flex;
	flex-wrap: wrap;
}

.description {
	@include overflowHiddenEllipsisNowrap;
	max-width: 23rem;
	color: $neutral50;
	@extend .fontSize14;
	@extend .fontWeight450;
}

.neutralText {
	color: $neutral600;
	&::before {
		content: ' \00a0|\00a0';
	}
}
