@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.headerContainer {
	@include horizontalDivider;
	@include flex;
	align-items: center;
	color: $neutral700;
	border-left: 2px solid transparent;
	width: fit-content;
	min-width: 100%;
	background-color: $neutral100;
}

.addColum {
	@include flexCenter;
	height: 4.4rem;
	width: 4.6rem;
	border-radius: 0;
}

.addIcon {
	height: 10px;
	width: 10px;
}

.switchSpace {
	width: 3.2rem;
	margin-left: 1rem;
}
