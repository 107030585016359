@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.container {
	height: 100%;
}

.header {
	@include flex;
	@extend .fontSize12;
	@include horizontalDivider;
	align-items: center;
	height: 3.6rem;
	padding: 0 2.4rem;
	text-transform: uppercase;
}

.pricingNameTitle {
	width: 25rem;
}

.listItem {
	@include flex;
	@include horizontalDivider;
	padding-right: 2.4rem;
	height: 6.4rem;
	align-items: center;
	cursor: pointer;
	transition: 400ms;
	border-left: 4px solid transparent;
	&:hover {
		background-color: $neutral50;
		transition: 400ms;
	}
}

.itemTitle {
	@include flex;
	@extend .fontSize14;
	@extend .fontWeight250;
	align-items: center;
	width: 27rem;
	padding: 0 2.4rem;
	gap: 1.4rem;
}

.itemIconContainer {
	@include borderNeutral;
	@include flexCenter;
	height: 4rem;
	width: 4rem;
	background-color: $white;
}

.listItemSelected {
	border-left: 4px solid $black;
	.itemTitle {
		@extend .fontWeight450;
	}
}

.itemTag {
	@include overflowHiddenEllipsisNowrap;
	@extend .fontSize12;
	color: $neutral;
	height: 2.2rem;
	border: 1px solid $black;
	padding: 0 0.8rem;
	min-width: 4.5rem;
	max-width: 9rem;
}

.listContainer {
	height: calc(100% - 3.6rem);
	overflow: scroll;
}
