@import '../../../constants/colors.scss';
@import '../../../constants/mixins.scss';
@import '../../../constants/global.scss';

.container {
	padding: 2.4rem;
	height: 100%;
}

.header {
	@include flex;
	justify-content: space-between;
	height: 6rem;
}

.addIntegrationButton {
	height: 4rem;
	width: 4rem;
}

.itemsContainer {
	@include flex;
	flex-wrap: wrap;
	gap: 1.4rem;
	height: calc(100% - 6rem);
	overflow: scroll;
}

.emptyItemsContainer {
	@include flexColumnCenter;
	width: 100%;
	height: calc(100% - 6rem);
}

.image {
	height: 15rem;
	width: 15rem;
}

.button {
	height: 3.2rem;
	gap: 1rem;
	margin-top: 1.4rem;
}

.iconContainer {
	@include borderNeutral;
	@include flexColumnCenter;
	height: 13rem;
	width: 12.4rem;
	padding: 0 !important;
}

.cardIcon {
	height: auto;
	width: 5.4rem;
}

.cardIconHeight {
	@include flexCenter;
	height: 5.4rem;
}

.connected {
	@extend .fontSize12;
	color: $success700;
	background-color: $success50;
	width: fit-content;
	margin-top: 0.4rem;
	padding: 0 0.8rem;
	border-radius: 0.4rem;
}
