@import '../../../../constants/colors.scss';
@import '../../../../constants/mixins.scss';
@import '../../../../constants/global.scss';

.container {
	height: 100%;
}

.filtersContainer {
	@include flex;
	width: 100%;
	height: 4.8rem;
}

.searchBar {
	border-radius: 0 !important;
	border-color: $white $white $neutral200 $white !important;
	width: 72%;
}

.searchBarFullWidth {
	width: 100%;
	border-color: $white $white $neutral200 $white !important;
	border-radius: 0 !important;
}

.select {
	border-radius: 0;
	border-top: 0;
	box-shadow: 0rem 0.1rem 0.2rem rgba(16, 24, 40, 0.05);
	&:hover {
		border-top: 1px solid $primary300;
	}
}

.header {
	display: flex;
	height: 4.4rem;
	width: 100%;
	@include horizontalDivider;
}

.headerItem {
	@include flex;
	@extend .fontSize12;
	text-transform: uppercase;
	padding: 1.4rem;
	&:nth-child(1) {
		width: 18rem;
		max-width: 18rem;
	}
	&:nth-child(2) {
		width: 13.2rem;
	}

	&:nth-child(3) {
		width: 12rem;
	}
	&:nth-child(4) {
		width: 12.2rem;
		justify-content: center;
	}
}

.listContainer {
	height: calc(100% - 5rem);
	overflow: scroll;
}

.cell {
	flex: 1;
}
