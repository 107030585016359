@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.container {
	@include flexColumn;
	width: 100%;
	height: 100%;
	background-color: rgba(17, 24, 39, 0.4);
	align-items: center;
	border-radius: 0;
	@extend .appearAnimation;
}

.pdfContainer {
	@include flexCenter;
	z-index: 50;
}

.content {
	@include flex;
	overflow: scroll;
	gap: 12.9rem;
	height: 100%;
}

.wideContent {
	width: 100%;
}

.selectOverlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 40;
	opacity: 0.5;
}

.timelineContainer {
	z-index: 50;
	width: 42rem;
	min-width: 42rem;
	background-color: $white;
	max-height: 100%;
	overflow: scroll;
	display: none;
}

.visible {
	display: flex;
}
