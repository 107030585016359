@import 'src/constants/colors';
@import 'src/constants/mixins';
@import 'src/constants/global';

.container {
	min-width: 20rem;
	width: 100%;
	height: 30%;
	border: none;
	text-align: left;
	padding: 0;
	@include defaultBoxShadow;
}

.container:hover {
	background-color: $primary50;
	@include borderPrimary;
}

.textContainer {
	width: calc(100% - 8.4rem);
	padding: 0 0.5rem 0 2rem;
	color: $neutral900;
}

.subtitle {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.icon {
	width: 25%;
	height: auto;
}
