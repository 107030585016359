@import 'src/constants/colors';
@import 'src/constants/mixins';
@import 'src/constants/global';

.container {
	@include flexColumn;
	align-items: center;
	justify-content: space-between;
	width: 47.6rem;
	min-height: 47.6rem;
	padding: 2.4rem 2.4rem 5rem 2.4rem;
}

.icon {
	width: auto;
	height: 18.8rem;
}

.title {
	padding-bottom: 1rem;
	text-align: center;
}

.text {
	width: 42.8rem;
	max-height: 7.5rem;
	overflow: hidden;
	color: $neutral;
	text-align: center;
	margin-bottom: 1rem;
}

.button {
	width: 100%;
	height: 4rem;
}

.modalContainer {
	z-index: 92;
	border-radius: 2rem;
}

.modalOverlay {
	z-index: 53;
}

.xIcon {
	align-self: flex-end;
	height: 2.4rem;
	width: 2.4rem;
}

.buttonContainer {
	@extend .flexCenter;
	width: 100%;
	gap: 2.4rem;
}
