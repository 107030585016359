@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.categoriesHeader {
	@include flex;
	@extend .fontSize12;
	align-items: center;
	height: 3.6rem;
	padding: 0 2.4rem;
	text-transform: uppercase;
	justify-content: space-between;
}

.headerButtons {
	padding: 0;
}

.categoriesListContainer {
	margin: 0.8rem 1.4rem 0 2.4rem;
	border-left: 1px solid $neutral200;
}

.productListItem {
	@include flex;
	@extend .fontSize14;
	padding-left: 1.4rem;
	height: 3.6rem;
	color: $neutral;
	align-items: center;
	justify-content: space-between;
	border-left: 2px solid transparent;
	transition: 400ms;
	cursor: pointer;
	&:hover {
		@extend .fontWeight650;
		transition: 400ms;
		background-color: $neutral50;
	}
}

.productListItemSelected {
	border-left: 2px solid $black;
	color: $neutral700;
	@extend .fontWeight650;
}

.allProductsListItem {
	@extend .productListItem;
	margin-bottom: 0.8rem;
}

.productListItemTitle {
	@include flex;
	align-items: center;
	gap: 0.4rem;
}

.productListItemCount {
	@include flex;
	@extend .fontSize12;
	align-items: center;
	height: 2rem;
	border-radius: 0.4rem;
	padding: 0 0.8rem;
	background-color: $neutral100;
	color: $neutral700;
}

.rightSection {
	@include flex;
	align-items: center;
}

.name {
	@include overflowHiddenEllipsisNowrap;
	max-width: 15rem;
	padding-top: 0.3rem;
}

.hamburger {
	padding: 0 0 0 0.4rem;
}
