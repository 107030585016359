@import '../../constants/colors.scss';
@import '../../constants/mixins.scss';
@import '../../constants/global.scss';

.container {
	min-width: 31.3rem;
	height: 100%;
	overflow: hidden;
	background-color: $white;
}

.header {
	@include flexColumn;
	@include horizontalDivider;
	padding: 2.4rem;
	height: 8.6rem;
}

.headerWithTitles {
	height: 13.1rem;
	padding-bottom: 0;
	justify-content: space-between;
}

.titles {
	@include flex;
	@extend .fontSize12;
	text-transform: uppercase;
	height: 4.5rem;
	justify-content: space-between;
	align-items: center;
}

.subTitle {
	@extend .fontSize12;
	color: $neutral400;
}

.listContainer {
	overflow: scroll;
	height: calc(100% - 9.8rem);
}

.amountContainer {
	text-align: right;
}

.nameContainer {
	@include flexColumn;
	justify-content: center;
}

.customersContainer {
	@include horizontalDivider;
	@include flex;
	gap: 1rem;
	align-items: center;
	justify-content: space-between;
	padding: 0 2.4rem;
	height: 7.2rem;
	cursor: pointer;
	background-color: $white;
	transition: 500ms;
	&:hover {
		background-color: $success200;
		transition: 500ms;
	}
	&:active {
		background-color: $success300;
	}
}

.iconContainer {
	@include flexCenter;
	@include borderNeutral;
	overflow: hidden;
	height: 4.4rem;
	width: 4.4rem;
	background-color: $neutral50;
	margin-right: 1rem;
}

.noAvatarContainer {
	@extend .fontSize18;
	@extend .fontWeight650;
	color: $white;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: $success600;
	height: 3.4rem;
	width: 3.4rem;
	border-radius: 50%;
}

.iconStyle {
	background-color: $neutral50;
	width: 100%;
	height: auto;
}

.fullIcon {
	background-color: $white;
	border: none;
	border-radius: 0;
}

.fullNoIcon {
	height: 3.4rem;
	width: 4.4rem;
	border-radius: 0.6rem;
}

.filters {
	@include flex;
	gap: 0.8rem;
}

.headerItems {
	@include flex;
	justify-content: space-between;
}

.navigateButton {
	height: 4rem;
	width: 4rem;
}
