@import 'src/constants/colors.scss';
@import 'src/constants/global.scss';

.card {
	@include flexColumn;
	justify-content: space-between;
	border: 1px solid $neutral200;
	width: 28rem;
	padding: 1.4rem;
	gap: 1rem;
}

.cardDisabled {
	opacity: 0.5;
}

.iconContainer {
	@include flexCenter;
	background-color: $neutral50;
	width: 8.4rem;
	height: 8.4rem;
}

.cardIcon {
	width: 64px;
	height: auto;
}

.cardDescription {
	@extend .fontSize14;
	@extend .fontWeight250;
	color: $neutral;
}

.cardButton {
	width: auto;
	color: $neutral700;
	@extend .fontSize14;
}

.connectedTag {
	color: $success700;
	@extend .fontSize14;
	background-color: $success50;
	align-self: flex-start;
	padding: 0 0.8rem;
}

.connectingTag {
	color: $warning600;
	@extend .fontSize14;
	background-color: $warning100;
	align-self: flex-start;
}

.reconnectTag {
	color: $neutral600;
	@extend .fontSize14;
	background-color: $neutral100;
	align-self: flex-start;
}

.disconnectButton {
	color: $destructive;
}

.topContainer {
	@include flex;
	justify-content: space-between;
}

.buttons {
	@include flex;
	gap: 1.4rem;
}

.disconnect {
	width: 4.8rem;
}

.configuration {
	@extend .cardButton;
	width: calc(100% - 6.2rem);
	margin: 0;
}
