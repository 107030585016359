@import '../../../../constants/colors.scss';
@import '../../../../constants/mixins.scss';
@import '../../../../constants/global.scss';

.container {
	@include horizontalDivider;
	width: 100%;
	padding-bottom: 1.4rem;
	margin-bottom: 1.4rem;
	&:last-child {
		border: none;
	}
}

.containerClose {
	height: 11rem;
	transition: 400ms;
}

.containerOpen {
	height: 21rem;
	transition: 400ms;
}

.sectionContainer {
	border: 1px solid $neutral200;
	border-radius: 1rem;
	display: flex;
}

.usageServiceItem {
	display: flex;
	align-items: center;
}

.leftSection {
	border-right: 1px solid $neutral200;
	background-color: $neutral50;
	width: 25.6rem;
	padding: 0 2.4rem;
	display: flex;
	align-items: center;
	cursor: pointer;
	height: 10rem;
	@extend .editable;
}

.rightSection {
	color: $neutral300;
	padding: 2.4rem;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	width: calc(100% - 25.6rem);
}

.invoiceList {
	@extend .fontSize14neutral;
	@include overflowHiddenEllipsisNowrap;
	max-width: 14rem;
}

.title {
	@include overflowHiddenEllipsisNowrap;
	@extend .fontSize14;
	max-width: 14rem;
}

.subTitle {
	@extend .fontSize14;
	@extend .fontWeight250;
	color: $neutral400;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	max-width: 14rem;
}

.titleInput {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	max-width: 5rem;
}

.iconContainer {
	display: flex;
	height: 5.2rem;
	width: 5.2rem;
	border-radius: 2.6rem;
	align-items: center;
	justify-content: center;
	background-color: $success200;
	margin-right: 1.4rem;
}

.summaryIcon {
	height: 16px;
}

.arrowDown {
	width: 10px;
	transition: 400ms;
}

.arrowUp {
	width: 10px;
	transform: rotate(180deg);
	transition: 400ms;
}

.input {
	@extend .fontSize14;
	@extend .fontWeight450;
	width: 10rem;
	height: 2.2rem;
	color: $neutral700;
	margin-right: 0.8rem;
	border: none;
	padding: 0 0.5rem 0 0.5rem;
	text-align: right;
}

.input:hover {
	border-bottom: 1px solid $neutral300;
}

.input:focus {
	outline: none;
	border-bottom: 1px solid $primary300;
}

.invoiceButton {
	@extend .fontSize14;
	padding: 0;
	justify-content: flex-start;
	max-width: 14rem;
	text-decoration: underline;
}

.subActivityInput {
	margin-top: 1.4rem;
}

.tooltipLabel {
	@include flex;
	white-space: pre;
	flex-wrap: wrap !important;
	word-break: keep-all !important;
	word-wrap: break-word !important;
}
