@import 'src/constants/colors.scss';
@import 'src/constants/global.scss';
@import 'src/constants/mixins.scss';

.itemContainer {
	@include borderNeutral;
	@include flexCenter;
	justify-content: space-between;
	align-items: flex-start;
	border-radius: 1rem;
	padding: 1.4rem;
	width: 100%;
	height: 9.2rem;
	transition: 400ms;
}

.logoContainer {
	@include flexCenter;
	height: 6.4rem;
	width: 6.4rem;
	border-radius: 0.4rem;
	margin-right: 1.4rem;
	background-color: $neutral50;
	transition: 400ms;
	border: 1px solid transparent;
}

.logo {
	height: auto;
	width: 4.4rem;
}

.clickableItemContainer:hover {
	@include borderPrimary;
	background-color: $primary50;
	transition: 400ms;
}

.clickableItemContainer:hover .logoContainer {
	@include borderNeutral;
	background-color: $white;
	transition: 400ms;
}

.actions {
	@include flexCenter;
	gap: 0.5rem;
	align-self: flex-start;
}

.actionButton {
	padding: 0.6rem;
}

.arrow {
	transition: 400ms;
}

.arrowClosed {
	transform: rotate(180deg);
	transition: 400ms;
}

.description {
	@include flexColumn;
	align-items: flex-start;
	gap: 0.4rem;
}

.descriptionLabel {
	@extend .neutralFontSize12;
	@extend .fontWeight250;
	@include overflowHiddenEllipsisNowrap;
	max-width: 21rem;
}

.select {
	padding: 0 0.8rem;
	@extend .fontSize12;
	@extend .fontWeight450;
	color: $neutral700;
	height: 1.6rem;
	width: 23rem;
}

.inputSelect {
	background-color: transparent;
	@extend .fontSize12;
	@extend .fontWeight450;

	&::placeholder {
		@extend .fontSize12;
	}
}

.borderPrimary {
	@include borderPrimary;
}

.name {
	@extend .fontSize14;
	@extend .fontWeight650;
	@include overflowHiddenEllipsisNowrap;
	max-width: 23rem;
}
