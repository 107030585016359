@import '../../../constants/colors.scss';
@import '../../../constants/mixins.scss';
@import '../../../constants/global.scss';

.overviewContainer {
	@extend .appearAnimation;
	height: 100%;
	width: 100%;
}

.topContainer {
	@include flex;
	width: 100%;
	gap: 2.4rem;
	height: 30rem;
}

.statistic {
	@include flexColumn;
	padding: 2.4rem;
	width: 10%;
}

.graphContainer {
	@include flexColumn;
	height: 100%;
	padding: 0 2.4rem 2.4rem 2.4rem;
	width: 90%;
}

.graph {
	height: 20rem !important;
}

.graphHeader {
	padding-top: 2.4rem;
	@include flexColumn;
	gap: 0.5rem;
}

.title {
	@extend .fontSize20;
	@extend .fontWeight250;
}

.bottomContainer {
	@include flex;
	height: calc(100% - 8rem);
}

.tableContainer {
	width: 100%;
	height: 100%;
	border-left: none;
	border-right: none;
}
