@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.sidebar {
	height: 100%;
	position: fixed;
	right: 60.1rem;
	background-color: $white;
	@extend .sidebarTransition;
	overflow: hidden;
	z-index: 30;
	top: 0.1rem;
}
