@import '../../constants/colors.scss';
@import '../../constants/mixins.scss';
@import '../../constants/global.scss';

.pageContainer {
	@extend .minScreenSize;
	@extend .appearAnimation;
	@include flexColumn;
	height: unset;
	gap: 1.4rem;
	padding: 3.4rem 2.4rem 2.4rem 2.4rem;
	overflow: scroll;
}

.pageHeader {
	@include flex;
	justify-content: space-between;
	align-items: center;
}

.headerButtons {
	@include flex;
	gap: 0.8rem;
}

.card {
	@include borderNeutral;
	background-color: $white;
	padding: 2.4rem;
}

.graphsSection {
	@include flex;
	width: 100%;
	gap: 1.4rem;
	height: 33.4rem;
}

.rightSection {
	width: 35%;
}

.leftSection {
	width: 65%;
}

.collectionStatusContainer {
	@extend .card;
	width: 54%;
}

.cashflowContainer {
	@extend .card;
	width: 46%;
	height: 100%;
}

.cardHeader {
	@include flex;
	justify-content: space-between;
	margin-bottom: 1.4rem;
}

.graphsCardHeader {
	@extend .cardHeader;
	padding: 0 1.6rem;
}

.cardSubTitle {
	@extend .fontSize12;
	color: $neutral400;
}

.graphContainer {
	height: calc(100% - 4.8rem);
	width: calc(100% - 0.4rem);
}

.analyticsListsSection {
	@extend .graphsSection;
	height: 40.4rem;
}

.analyticsLeftSection {
	@extend .leftSection;
	@include flex;
	gap: 1.4rem;
}

.analyticsList {
	@extend .card;
	padding: 0;
	width: 50%;
	height: 100%;
}

.tableSection {
	@extend .graphsSection;
	height: 51.5rem;
	width: 100%;
}

.arAging {
	@extend .card;
	@extend .leftSection;
	padding: 0;
}

.rightListContainer {
	@extend .card;
	@extend .rightSection;
	padding: 0;
}

.zero {
	color: $neutral400;
}

.navigateButton {
	height: 4rem;
	width: 4rem;
}

@media only screen and (max-width: 1300px) {
	.rightSection {
		width: 30%;
	}

	.leftSection {
		width: 70%;
	}
}

@media only screen and (max-width: 1565px) {
	.collectionStatusContainer {
		padding: 2.4rem 1.4rem;
		width: 52%;
	}

	.cashflowContainer {
		padding: 2.4rem 1.4rem;
		width: 48%;
	}
}

@media only screen and (max-width: 1470px) {
	.collectionStatusContainer {
		padding: 2.4rem 1.4rem;
		width: 50%;
	}

	.cashflowContainer {
		padding: 2.4rem 1.4rem;
		width: 50%;
	}
}

@media only screen and (max-width: 1422px) {
	.collectionStatusContainer {
		padding: 2.4rem 0.4rem;
		width: 50%;
	}

	.cashflowContainer {
		padding: 2.4rem 0.4rem;
		width: 50%;
	}
}
