@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.container {
	@extend .minScreenSize;
	@extend .appearAnimation;
}

.header {
	padding: 2.4rem 2.4rem 1.4rem 2.4rem;
}

.customHeader {
	@include flex;
	justify-content: space-between;
	align-items: center;
}

.buttonsContainer {
	@include flex;
	height: 4rem;
	gap: 0.8rem;
}

.search {
	width: 18.5rem;
}

.newAgreement {
	@include flexCenter;
}

.emptyStateWrapper {
	@include flex;
	gap: 0.6rem;
}

.tableContainer {
	height: calc(100% - 10rem);
	@extend .appearAnimation;
	border-radius: 0;
	border: none;
}

.createContract {
	height: 2rem;
	width: fit-content;
}

.contractEndBillingBadge {
	background-color: $neutral100;
	color: $neutral400;
}

.cardsContainer {
	@include flex;
	padding: 2.4rem;
}

.card {
	height: 7.2rem;
	@include flexColumn;
}

.cardTitle {
	@extend .fontSize12;
	@extend .fontWeight250;
	color: $neutral;
}

.cardQuantity {
	@extend .fontSize20;
	@extend .fontWeight250;
	color: $neutral800;
}

.cardDescription {
	@extend .fontSize12;
	@extend .fontWeight450;
	color: $neutral400;
}

.cardWrapper {
	@include flex;
	align-items: center;

	&:last-child {
		.divider {
			display: none;
		}
	}
}

.divider {
	border: 0.1px solid $neutral200;
	height: 4rem;
	width: 0;
	margin: 0 3.4rem;
}
