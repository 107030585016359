@import '../../../constants/colors.scss';
@import '../../../constants/mixins.scss';
@import '../../../constants/global.scss';

.overviewContainer {
	@extend .appearAnimation;
	height: 100%;
	width: 100%;
}

.statistic {
	@include flex;
	height: 37rem;
}

.bottomContainer {
	@include flex;
	height: calc(100% - 38rem);
}

.summarySection {
	width: 29.2rem;
	overflow: hidden;
	@include flexColumn;
	justify-content: center;
	padding: 2.4rem;
	border-right: 1px solid $neutral200;
}

.cashflow {
	width: calc(100% - 29.2rem);
	padding: 2.4rem;
}

.cardTitle {
	@extend .fontSize20;
	@extend .fontWeight650;
	display: block;
}

.cardDescription {
	@extend .fontSize12;
	color: $neutral400;
	display: block;
}

.overflowGraph {
	height: 29.5rem;
	@include flex;
	justify-content: center;
	width: 100%;
}

.tableContainer {
	width: 75%;
	height: 100%;
}

.usageContainer {
	width: 25%;
	padding: 1.4rem;
	height: 100%;
	border-top: 1px solid $neutral200;
}

.productUseContainer {
	width: 100%;
	padding: 0 18%;
	height: 100%;
}
