@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.container {
	@include borderNeutral;
	@include flexColumn;
	width: 100%;
	border-radius: 1rem;
	transition: 350ms;
	padding: 1.4rem 2.4rem;
	cursor: pointer;
	height: 8.5rem;
	justify-content: space-between;
	gap: 1.4rem;
	background-color: $white;
	overflow: hidden;
	margin-bottom: 1.4rem;
}

.container:hover {
	border: 2px solid $primary;
}

.container:active {
	background-color: $primary100;
}

.selectedContainer {
	border: 2px solid $primary;
	background-color: $primary50;
}

.isOpen {
	height: 17rem;
	transition: 650ms;
}

.iconContainer {
	position: relative;
	background-color: $primary100;
	width: 5.2rem;
	height: 5.2rem;
	border-radius: 10rem;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 2rem;
}

.text {
	@extend .fontSize14;
	@include overflowHiddenEllipsisNowrap;
	max-width: 27rem;
}

.statusDot {
	position: absolute;
	right: 0;
	bottom: 0.5rem;
	height: 1.2rem;
	width: 1.2rem;
	border-radius: 0.6rem;
	border: 2px solid $white;
}

.MANUAL {
	background-color: $success;
}

.API {
	background-color: $success;
}

.NOT_CONNECTED {
	background-color: $warning;
}

.description {
	@extend .fontSize14;
	@extend .fontWeight250;
	color: $neutral400;
}

.topContainer {
	@include flex;
	justify-content: space-between;
	width: 100%;
}

.selectTitle {
	@extend .fontSize12;
	color: $neutral700;
}

.select {
	height: 5.6rem;
}

.inputClassName {
	height: 3.2rem;
	padding-left: 0.6rem;
}

.hamburger {
	transform: rotate(90deg);
}

.menuButton:hover .hamburger {
	animation: spin 400ms linear;
}

@keyframes spin {
	0% {
		transform: rotate(90deg);
	}
	80% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(90deg);
	}
}

.isMenuOpen {
	transform: rotate(0deg);
	transition: 0.2s;
	visibility: visible;
}

.isMenuClosed {
	transform: rotate(90deg);
	transition: 0.2s;
}
