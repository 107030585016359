@import '../../constants/mixins.scss';
@import '../../constants/colors.scss';
@import '../../constants/global.scss';

.leftContainer {
	@include flexColumn;
}

.title {
	@extend .fontSize14;
	color: $neutral900;
	margin-bottom: 1.2rem;
}
