@import '/src/constants/colors.scss';
@import '/src/constants/mixins.scss';
@import '/src/constants/global';

.container {
	width: 100%;
	height: 31rem;
	width: 28rem;
}

.title {
	@extend .fontSize20;
	@extend .fontWeight450;
	@include horizontalDivider;
	padding: 1.4rem 2.4rem;
}

.daysContainer {
	@include flex;
	height: calc(100% - 6rem);
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 1.4rem;
	padding: 1.4rem 2.4rem;
}

.dayItem {
	@extend .fontSize14;
	@include flexCenter;
	height: 4rem;
	width: 10.5rem;
	color: $neutral;
	border-radius: 0.6rem;
	border: 2px solid transparent;
	cursor: pointer;
}

.dayItemSelected {
	height: 4rem;
	width: 10.5rem;
	background-color: $primary100;
	border: 2px solid $primary200;
	color: $primary;
}

.dayItem:hover {
	background-color: $neutral100;
	color: $primary800;
	border: 2px solid transparent;
}

.dayItem:active {
	background-color: $neutral50;
	color: $primary700;
	border: 2px solid transparent;
}
