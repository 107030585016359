@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.pageContainer {
	@extend .minScreenSize;
	@extend .appearAnimation;
	@include flexColumn;
	padding: 2.4rem;
	gap: 1.4rem;
	overflow: scroll;
	background-color: $white;
}

.reportSelectClassName {
	background-color: $neutral100;
	height: 2.7rem !important;
	border: 1px solid $neutral300;
}

.reportSelectInputClassName {
	background-color: $neutral100;
	height: 2.4rem !important;
}

.rowContainer {
	min-height: 40rem;
}

.box {
	border: 1px solid $neutral200;
}

.tableContainer {
	@extend .box;
	min-height: 50rem;
}

.stackBarGraph {
	height: 27rem !important;
}

.pageHeader {
	@include flexCenter;
	justify-content: space-between;
}
