@import 'src/constants/colors.scss';
@import 'src/constants/global.scss';
@import 'src/constants/mixins.scss';

.itemContainer {
	@include borderNeutral;
	@include flexCenter;
	justify-content: space-between;
	align-items: flex-start;
	padding: 1.4rem;
	width: 100%;
	height: 9.2rem;
	transition: 400ms;
}

.logoContainer {
	@include flexCenter;
	height: 6.4rem;
	width: 6.4rem;
	border-radius: 0.4rem;
	margin-right: 1.4rem;
	background-color: $neutral50;
	transition: 400ms;
	border: 1px solid transparent;
}

.logo {
	height: auto;
	width: 4.4rem;
}

.clickableItemContainer:hover {
	@include borderPrimary;
	background-color: $primary50;
	transition: 400ms;
}

.clickableItemContainer:hover .logoContainer {
	@include borderNeutral;
	background-color: $white;
	transition: 400ms;
}

.actions {
	@include flexCenter;
	gap: 0.5rem;
	align-self: flex-start;
}

.tag {
	@extend .fontSize12;
	border-radius: 0.6rem;
	cursor: pointer;
	width: fit-content;
}

.disconnect,
.cancel,
.completeDetails,
.reconnect {
	display: none;
	width: 100%;
}

.tag:hover {
	.disconnect {
		background-color: $destructive50;
		color: $destructive700;
		padding: 0.3rem 0.6rem;
		border-radius: 0.6rem;
	}
	.cancel {
		background-color: $destructive50;
		color: $destructive700;
		padding: 0.2rem 0.8rem;
	}
	.connected {
		background-color: $destructive50;
		color: $destructive700;
		padding: 0.6rem;
		border-radius: 0.6rem;
	}
	.completeDetails {
		background-color: $primary100;
		color: $primary700;
		padding: 0.3rem 0.6rem;
		border-radius: 0.6rem;
	}
	.reconnect {
		background-color: $primary100;
		color: $primary700;
		padding: 0.3rem 0.6rem;
		border-radius: 0.6rem;
		cursor: pointer;
		&:active {
			background-color: $primary50;
		}
	}
}

.tag:hover .disconnect {
	display: block;
}

.tag:hover .connected {
	display: none;
}
.tag:hover .cancel {
	display: block;
}
.tag:hover .completeDetails {
	display: block;
}

.tag:hover .reconnect {
	display: block;
}

.connectedTag {
	padding: 0.7rem 0.6rem;
	border-radius: 0.6rem;
	color: $success700;
	background-color: $success50;
}

.connectingTag {
	padding: 0.7rem 0.6rem;
	border-radius: 0.6rem;
	color: $warning600;
	background-color: $warning100;
}

.disconnectedTag {
	padding: 0.7rem 0.6rem;
	border-radius: 0.6rem;
	color: $neutral600;
	background-color: $neutral100;
}

.delete {
	padding: 0.6rem;
	height: 3.2rem;
	width: 3.6rem;
}

.menuOptions {
	height: 1.6rem;
}

.connectIntegrationAccount {
	padding: 0 0.8rem;
	border-radius: 0.3rem;
	@extend .fontSize12;
	@extend .fontWeight450;
	color: $neutral700;
	height: 2rem;
}

.borderSuccess {
	@include borderSuccess;
}

.arrow {
	transition: 400ms;
}

.arrowClosed {
	transform: rotate(180deg);
	transition: 400ms;
}

.description {
	@include flexColumn;
}

.descriptionLabel {
	@extend .neutralFontSize12;
	@extend .fontWeight250;
	@include overflowHiddenEllipsisNowrap;
	max-width: 21rem;
}
