@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';
@import 'src/constants/colors.scss';

.container {
	height: 100% !important;
	min-height: 90px;
}

.tableContainer {
	height: calc(100% - 11rem) !important;
}

.tableRow {
	@extend .fontSize14;
}

.ellipsis {
	@include overflowHiddenEllipsisNowrap;
	max-width: 90%;
}

.summaryBlock {
	width: 30rem !important;
}

.statusTag {
	width: fit-content;
}
