@import 'src/constants/colors';
@import 'src/constants/global';

.container {
	@include flexColumnCenter;
	animation: appear 0.4s linear 1;
	text-align: center;
	height: 100%;
}

@keyframes appear {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.emptyIconContainer {
	@include flexCenter;
	height: 7.2rem;
	width: 7.2rem;
	border-radius: 4rem;
	background-color: $neutral50;
	margin-bottom: 1rem;
}

.emptyIcon {
	width: 11rem;
	height: auto;
}

.title {
	@extend .fontSize14;
	@extend .fontWeight650;
	text-transform: capitalize;
}

.subTitle {
	@extend .fontSize14neutral;
	@extend .fontWeight250;
}
