.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
	background-color: #ffffff;
}

.react-datepicker {
	border: none !important;
}

.react-datepicker__header {
	border: none !important;
	background-color: #fff !important;
}

.react-datepicker__day--outside-month {
	color: #9ca3af !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name,
.react-datepicker__day-names > * {
	width: 40px !important;
	color: #111827;
}

.react-datepicker__month-container {
	height: 100%;
}

.react-datepicker__day--keyboard-selected {
	border: none;
	background-color: #fff;
}

.react-datepicker__day--outside-month {
	color: #e5e7eb !important;
	pointer-events: none;
	background-color: #ffffff;
}
