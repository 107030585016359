@import '../../../../constants/colors.scss';
@import '../../../../constants/mixins.scss';
@import '../../../../constants/global.scss';

.container {
	height: 100%;
}

.header {
	display: flex;
	height: 4.4rem;
	width: 100%;
	@include horizontalDivider;
}

.headerItem {
	@include flex;
	@extend .fontSize12;
	text-transform: uppercase;
	padding: 1.4rem;
	&:nth-child(1) {
		width: 18rem;
		max-width: 18rem;
	}
	&:nth-child(2) {
		width: 13.2rem;
	}

	&:nth-child(3) {
		width: 12rem;
	}
	&:nth-child(4) {
		width: 12.2rem;
		justify-content: center;
	}
}

.listContainer {
	height: calc(100% - 5rem);
	overflow: scroll;
}

.cell {
	flex: 1;
}
