@import '../../constants/colors.scss';
@import '../../constants/global.scss';

.container {
	@extend .minScreenSize;
	@extend .appearAnimation;
	width: 100%;
	display: flex;
	padding-left: 2.4rem;
	background: $white;
}

.header {
	display: flex;
	align-items: center;
}

.logoImg {
	height: 2.3rem;
	margin-right: 1.2rem;
}

.content {
	@include flexCenter;
	flex: 1;
}

.loginContainer {
	display: flex;
	flex-direction: column;
	width: 34.8rem;
	height: 40rem;
	justify-content: space-between;
	align-items: center;
}

.loginTitleContainer {
	display: flex;
	flex-direction: column;
	gap: 0.4rem;
	width: 100%;
}

.title {
	@extend .fontWeight850;
	font-size: 3.6rem;
	line-height: 4.4rem;
}

.description {
	@extend .fontWeight250;
	color: $neutral400;
}

.loginFields {
	padding-top: 2.4rem;
	display: flex;
	flex-direction: column;
	gap: 2.4rem;
	width: 34.8rem;
}

.signInButton {
	width: 100%;
	height: 4.8rem;
}

.x_icon {
	height: 1.2rem;
}

.errorBox {
	@include flex;
	align-items: center;
	padding: 1.2rem 2rem;
	gap: 0.8rem;
	margin-top: 3.2rem;
	background: $destructive50;
	border: 1px solid $destructive600;

	span {
		color: $destructive600;
	}

	div:last-of-type {
		margin-left: auto;
	}
}

.rightContainer {
	@include flex;
	background-color: black;
	flex: 2;
}

.leftContainer {
	display: flex;
	flex-direction: column;
	height: 100%;
	flex: 4.2;
	background: $white;
	padding: 3.6rem 0rem;
	transition: 650ms;
}

.image {
	height: 100%;
	width: fit-content;
}

.eyeButton:active {
	&:focus {
		box-shadow: none;
	}
	&:active {
		box-shadow: none;
	}
}
