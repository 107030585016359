@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.sidebar {
	@include borderNeutral;
	@extend .sidebarTransition;
	@extend .sidebarSize;
	position: fixed;
	right: 0;
	top: 0;
	background-color: $white;
	z-index: 32;
	min-height: 700px;
	height: 100vh;
}

.sidebar:active {
	@extend .sidebarTransition;
}

.sidebarHidden {
	width: 0;
	height: 100%;
	position: fixed;
	right: -100%;
	overflow: hidden;
	@extend .sidebarTransition;
}

.sidebarHidden:active {
	@extend .sidebarTransition;
}

.header {
	@include horizontalDivider;
	display: flex;
	justify-content: space-between;
	padding: 2.4rem;
	align-items: center;
	height: 8rem;
}

.title {
	@extend .fontSize20;
}

.editNameIcon {
	margin-right: 0.8rem;
	margin-bottom: 0.2rem;
}

.footer {
	@include flex;
	justify-content: space-between;
	border-top: 1px solid $neutral200;
	padding: 2.4rem;
	width: 100%;
	height: 8rem;
}

.footerButtons {
	height: 4rem;
}

.subTitle {
	padding: 1.4rem 0 0.8rem 0;
}

.dataContainer {
	height: calc(100% - 16rem);
	padding: 2.4rem;
	overflow: scroll;
}

.discountTaxContainer {
	@include flex;
	justify-content: space-between;
	gap: 1.4rem;
	width: 100%;
	margin-bottom: 1.4rem;
}

.discountContainer {
	width: 50%;
}

.discountTitle {
	@include flex;
	justify-content: space-between;
}

.discountInput {
	width: 100%;
}

.row {
	margin-bottom: 1.4rem;
}

.switchContainer {
	@include flex;
	@extend .fontSize14;
	@extend .fontWeight250;
	color: $neutral400;
	align-items: center;
	padding-bottom: 0.4rem;
	gap: 0.4rem;
}

.selectContainer {
	margin-bottom: 1.4rem;
}

.multiline {
	min-height: 10rem;
}

.stripeAccountItem {
	@include flexCenter;
	gap: 0.5rem;
}
