@import 'src/constants/colors';
@import 'src/constants/global';
@import 'src/constants/mixins';

.container {
	height: 47rem;
	width: 44rem;
	padding: 2.4rem;
}

.closeButton {
	@include flex;
	justify-content: flex-end;
	align-items: center;
}

.x_icon {
	height: 1rem;
}

.image {
	width: 18rem;
	height: 18rem;
}

.modalBody {
	@include flexColumnCenter;
	height: calc(100% - 4.8rem);
	justify-content: space-between;
	text-align: center;
}

.texts {
	@include flexColumnCenter;
	text-align: center;
}

.title {
	@extend .fontWeight850;
}

.message {
	@extend .fontWeight250;
	color: $neutral;
}

.messageContainer {
	padding-top: 1rem;
	text-align: center;
}

.buttonsContainer {
	@include alignItemsCenter;
	justify-content: space-around;
	width: 100%;
	gap: 1.4rem;
}

.button {
	width: 20rem;
	height: 4.8rem;
	border-radius: 0.6rem;
}
