@import 'src/constants/colors';
@import 'src/constants/mixins';
@import 'src/constants/global';

.container {
	@include flex;
	height: calc(100% - 13rem);
	width: 100%;
}

.categoriesContainer {
	border-right: 1px solid $neutral200;
	height: 100%;
	width: 25rem;
}

.tableContainer {
	height: 100%;
	width: calc(100% - 25rem);
}

.tableCard {
	@include borderNeutral;
	background-color: $white;
	width: 100%;
	border-radius: 0.6rem;
	height: 30rem;
}

.noProductsFound {
	@extend .appearAnimation;
	@include flexColumn;
	height: 100%;
	align-items: center;
	justify-content: center;
}

.emptyPricingModel {
	border: none;
}
