@import '../../constants/colors.scss';
@import '../../constants/mixins.scss';
@import '../../constants/global.scss';

.sideBarContainer {
	width: 39.5rem;
	height: 100%;
	position: fixed;
	right: 0;
	background-color: $white;
	transition: 650ms;
	overflow: hidden;
	z-index: 30;
}

.sideBarContainer:active {
	transition: 350ms;
}

.sidebarHidden {
	width: 0;
	height: 100%;
	position: fixed;
	overflow: hidden;
	right: -100%;
	overflow: hidden;
	transition: 650ms;
}

.sidebarHidden:active {
	transition: 350ms;
}

.modalOverlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(17, 24, 39, 0.4);
	z-index: 20;
}

.header {
	@extend .fontSize24;
	@extend .fontWeight650;
	@include horizontalDivider;
	display: flex;
	justify-content: space-between;
	color: $neutral700;
	height: 9.6rem;
	padding: 2.4rem;
	align-items: center;
}

.xIcon {
	height: 1.4rem;
}

.section {
	@include horizontalDivider;
}

.sectionOpen {
	height: 16.1rem;
	transition: 400ms;
}

.sectionClose {
	height: 5.5rem;
	overflow: hidden;
	transition: 400ms;
}

.sectionHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 5.5rem;
	width: 100%;
	padding: 0;
}

.sectionHeaderTitle {
	@extend .fontSize14;
	@extend .fontWeight650;
	display: flex;
	gap: 2rem;
	align-items: center;
	color: $neutral900;
	text-transform: uppercase;
}

.sectionIcon {
	height: 1.6rem;
}

.arrow {
	width: 10px;
	margin: 0 1.5rem;
	transition: 400ms;
}

.arrowClosed {
	width: 10px;
	margin: 0 1.5rem;
	transform: rotate(180deg);
	transition: 400ms;
}

.filterContainer {
	height: calc(100% - 19.2rem);
	padding: 2.4rem;
	overflow: scroll;
}

.footer {
	height: 9.6rem;
	display: flex;
	padding: 2.4rem;
	justify-content: space-between;
	border-top: 1px solid $neutral200;
}

.showButton {
	width: 17rem;
	height: 4.8rem;
}

.clearButton {
	@extend .fontSize14;
	@extend .fontWeight650;
}

.inputContainer {
	display: flex;
	align-items: center;
	gap: 0.4rem;
	color: $neutral;
	transition: 400ms;
}

.dateContainer {
	min-width: 16.4rem;
	width: 16.4rem;
}

.formTitle {
	@extend .fontSize14;
	display: block;
	padding-bottom: 0.4rem;
	color: $neutral900;
}

.bottomCustomerText {
	@extend .fontSize12;
	@extend .fontWeight250;
	display: flex;
	gap: 0.4rem;
	color: $neutral;
}

.showSelectedButton {
	@extend .fontSize12;
	@extend .fontWeight250;
	padding: 0;
}
.customerSection {
	margin-top: 1.4rem;
}

.customerSearch {
	margin-top: 1.5rem;
}

.customerItem {
	height: 7.2rem;
	@include horizontalDivider;
	display: flex;
	padding: 0 2.4rem;
	align-items: center;
	gap: 1.5rem;
}

.logoContainer {
	height: 4rem;
	width: 4rem;
	margin-right: 1.4rem;
	display: flex;
	align-items: center;
	justify-content: center;
}

.noImgLogo {
	background-color: $primary;
	color: $primary50;
}

.customerName {
	@extend .fontSize14;
	@extend .fontWeight650;
}

.checkboxContainer {
	@include flexColumn;
	@extend .fontSize14;
	transition: 400ms;
	gap: 2rem;
}

.checkboxItem {
	@include flex;
	gap: 1.4rem;
}
