@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.headerCell {
	@include flex;
	overflow: hidden;
	position: relative;
	flex: 1;
	padding-left: 2.6rem;
	align-items: center;
	height: 4.4rem;
	transition: 400ms;
	min-width: $minPricingModelTableColumnWidth;
}

.menuContainer {
	width: 100%;
}

.titleContainer {
	@include flex;
	align-items: center;
	width: 100%;
	justify-content: space-between;
}

.title {
	@extend .inputContainer;
	@include flex;
	align-items: center;
}

.hamburger {
	cursor: pointer;
	opacity: 0;
	padding: 0;
	transition: 400ms;
}

.headerCell:hover .hamburger {
	opacity: 1;
	transition: 400ms;
}

.headerCell:hover {
	color: $primary700;
}

.inputContainer {
	@extend .fontSize12;
	@extend .fontWeight450;
}

.inputContainer:hover {
	border-color: $primary300;
}

.inputContainer:focus {
	outline: none;
	border: 1px solid $primary;
	@include primary200BoxShadow;
}

.hideHeaderCell {
	@include flexCenter;
	min-width: 2rem;
	width: 3rem;
	flex: none;
	padding-left: 0;
	transition: 400ms;
	cursor: pointer;
	height: 4.4rem;
}

.hiddenColumn {
	@include flexCenter;
	cursor: col-resize;
}

.text {
	color: $neutral;
}

.HideIcon {
	margin-left: 0.8rem;
}

.purpleDiamond {
	@extend .purpleDiamond;
	margin-right: 0.7rem;
}

.name {
	@extend .inputContainer;
	@include overflowHiddenEllipsisNowrap;
	line-height: 1.5rem;
	background-color: transparent;
	border: none;
	color: $neutral600;
	text-transform: uppercase;
	width: 100%;
}
