@import '../../../constants/colors.scss';

.container {
	height: 2rem;
	width: 3.2rem;
	border-radius: 2.7rem;
	display: flex;
	align-items: center;
	cursor: pointer;
	overflow: hidden;
}

.smallContainer {
	height: 1.6rem;
	width: 2.7rem;
	border-radius: 1.9rem;
}

.switchOn {
	background-color: $success;
	transition: 500ms;
	border: 1px solid $success;
}

.switchOff {
	background-color: $neutral200;
	transition: 500ms;
	border: 1px solid $neutral200;
}

.movingSwitch {
	height: calc(100% - 2px);
	aspect-ratio: 1 / 1;
	border-radius: 1.4rem;
	background-color: $white;
}

.movingSwitchRight {
	animation: movingSwitchLeft 0.4s ease-out forwards;
}
.movingSwitchLeft {
	animation: movingSwitchRight 0.4s ease-out forwards;
}

@keyframes movingSwitchRight {
	0% {
		transform: translateX(100%);
	}
	100% {
		transform: translateX(0%);
	}
}

@keyframes movingSwitchLeft {
	0% {
		transform: translateX(0%);
	}
	100% {
		transform: translateX(100%);
	}
}

.disabled {
	pointer-events: none;
	border: 1px solid $neutral400;
	opacity: 0.5;
}
