@import 'src/constants/mixins';
@import 'src/constants/global';

.container {
	@include flex;
	justify-content: space-between;
	align-items: center;
	width: 100% !important;
}

.titleIconContainer {
	@include flex;
}

.formTitle {
	@extend .fontSize14;
	display: block;
	padding-bottom: 0.4rem;
	margin-right: 0.5rem;
	color: $neutral600;
}

.icon {
	margin-top: 0.3rem;
}
