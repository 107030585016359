@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.sidebar {
	position: fixed;
	right: 0;
	top: 0;
	background-color: $white;
	z-index: 30;
	border: 1px solid $neutral400;
	@extend .sidebarTransition;
	@extend .sidebarSize;
}

.sidebarContent {
	min-height: 700px;
	height: 100%;
}

.sidebar:active {
	@extend .sidebarTransition;
}

.sidebarHidden {
	width: 0;
	height: 100%;
	position: fixed;
	right: -100%;
	overflow: hidden;
	z-index: 50;
	@extend .sidebarTransition;
}

.sidebarHidden:active {
	@extend .sidebarTransition;
}

.secondarySidebar {
	height: 100%;
	position: fixed;
	background-color: $white;
	z-index: 20;
	right: 60.2rem;
	@extend .sidebarTransition;
	@extend .sidebarSize;
}

.topContainer {
	padding: 0 2rem;
}

.header {
	height: 11rem;
	@include flex;
	align-items: center;
	justify-content: space-between;
}

.titleContainer {
	@include flex;
}

.iconContainer {
	height: 6.8rem;
	width: 6.8rem;
	background-color: $neutral50;
	@include flexCenter;
	border: 1px solid $neutral200;
}

.title {
	@extend .fontSize14;
	color: $neutral;
}

.boldTitle {
	@extend .fontSize20;
	@extend .fontWeight850;
}

.titles {
	@include flexColumn;
	margin-left: 1.4rem;
	justify-content: center;
}

.detailsRowContainer {
	padding: 2.4rem 2.4rem 0 0;
	width: fit-content;
}

.detailsRow {
	@include flex;
	margin-bottom: 1.4rem;
}

.detailKey {
	@extend .fontSize12;
	color: $neutral;
	width: 11.3rem;
}

.detailValue {
	@extend .fontSize12;
	color: $neutral800;
}

.badge {
	width: fit-content;
}

.tabsSection {
	height: calc(100% - 39rem);
	min-height: calc(100% - 39rem);
	overflow: hidden;
}

.tabsContent {
	height: 100%;
}

.hamburgerButton {
	width: 2rem;
	transition: 400ms;
}

.hamburgerButtonWrapper {
	margin-left: 0.7rem;
	width: 4rem;
	height: 4rem;
	border: 1px solid $neutral200;
}

.hamburgerRotate {
	transform: rotate(90deg);
	transition: 400ms;
}

.link {
	cursor: pointer;
	&:hover {
		color: $success;
	}
}

.detailsText {
	@extend .neutralFontSize12;
	display: inline-block;
	width: 9rem;
}

.blackText {
	@extend .fontSize12;
	color: $neutral800;
}

.greyText {
	@extend .fontSize12;
	@extend .fontWeight250;
	color: $neutral400;
}

.ellipsis {
	@include overflowHiddenEllipsisNowrap;
	max-width: 33rem;
}

.tabComponent {
	&:first-child {
		padding-left: 0;
	}
}

.tabsContainer {
	padding: 0px 2.4rem;
}

.button {
	min-width: 7.2rem;
	height: 4rem;
	padding: 1rem 1.2rem;
	@extend .fontSize14;
	@extend .fontWeight650;
}

.buttonText {
	margin-top: 0.2rem;
}
