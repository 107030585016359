@import 'src/constants/colors';
@import 'src/constants/mixins';
@import 'src/constants/global';

.container {
	padding: 2.4rem;
	width: 100%;
}

.header {
	@include flex;
	align-items: center;
	gap: 1.4rem;
}

.logoContainer {
	@include flexCenter;
	@extend .fontSize24;
	@extend .fontWeight650;
	border: 1px solid $neutral;
	overflow: hidden;
	background-color: $neutral200;
	height: 8.8rem;
	width: 8.8rem;
	border-radius: 11.4rem;
}

.logo {
	width: 8.8rem;
	height: auto;
}

.role {
	@extend .fontSize14;
	padding: 0.2rem 1rem;
	background-color: $neutral200;
	width: fit-content;
}

.name {
	@extend .fontSize36;
	cursor: pointer;
}

.nameInput {
	@include overflowHiddenEllipsisNowrap;
	@extend .fontSize36;
	background-color: transparent;
	max-width: 25rem;
	color: $neutral700;
	outline: none;
	border: transparent;
	border-bottom: 1px solid $success;
	width: 100%;
	&::placeholder {
		@extend .fontWeight450;
		@extend .fontSize36;
		color: $neutral400;
	}
}

.error {
	border-bottom: 2px solid $destructive;
}

.editName {
	@include flex;
	gap: 1.4rem;
}

.inputs {
	width: 37.5rem;
}

.dataRows {
	@include flex;
	align-items: center;
	justify-content: space-between;
	height: 9.6rem;
	border-bottom: 1px solid $neutral200;
	width: 100%;
}

.phoneRow {
	display: flex;
	gap: 1.4rem;
}

.countryCode {
	width: 8.3rem;
}

.phone {
	width: calc(37.5rem - 9.7rem);
}

.icon {
	margin-left: 2.4rem;
}
