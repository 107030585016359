.plusButton {
	height: 4.7rem;
	width: 4.7rem;
}

.xIcon {
	animation: loopBack 0.5s linear 1;
	transform: rotate(45deg);
	transition: 400ms;
}

.xIconClicked {
	animation: loop 0.5s linear 1;
	transition: 400ms;
}

@keyframes loop {
	0% {
		transform: rotate(45deg);
	}
	50% {
		transform: rotate(-50deg);
	}
	100% {
		transform: rotate(0deg);
	}
}

@keyframes loopBack {
	0% {
		transform: rotate(0deg);
	}
	50% {
		transform: rotate(100deg);
	}
	100% {
		transform: rotate(45deg);
	}
}
