@import '/src/constants/colors';
@import '/src/constants/mixins';
@import '/src/constants/global';

.settingsBarContainer {
	@include flexColumn;
	padding: 2.4rem;
}

.header {
	@include flexCenter;
	justify-content: space-between;
}

.settingsBarTitle {
	@extend .fontSize18;
	@extend .fontWeight450;
	color: $neutral900;
	padding-bottom: 1.4rem;
}

.select {
	width: 100%;
	height: 4rem;
	background-color: $white;
}

.settingsContent {
	@include flexColumn;
	gap: 1.4rem;
}

.needLicenseButton {
	@include flexCenter;
	width: 10rem;
	height: 3.6rem;
}

.disabledFeature {
	opacity: 0.4;
}

.rightSideInputs {
	position: relative;
	@include flex;
	align-items: flex-end;
}

.issueDatePicker {
	border-radius: 6px 0 0 6px;
	border-right-color: transparent;
	width: 14rem;
	padding: 1.4rem;
}

.tooltipText {
	@extend .fontSize12;
	color: $neutral900;
}

.periodSelect {
	border-radius: 0px 6px 6px 0px;
	padding: 1.4rem 0.8rem;
}
