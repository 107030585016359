@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.sidebar {
	position: fixed;
	right: 0;
	top: 0;
	background-color: $white;
	z-index: 32;
	@include borderNeutral;
	@extend .sidebarTransition;
	@extend .sidebarSize;
}

.sidebarContent {
	min-height: 700px;
	height: 100vh;
}

.sidebar:active {
	@extend .sidebarTransition;
}

.secondarySidebar {
	position: fixed;
	background-color: $white;
	z-index: 20;
	right: 60.2rem;
	@extend .sidebarTransition;
	@extend .sidebarSize;
}

.sidebarHidden {
	width: 0;
	height: 100%;
	position: fixed;
	right: -100%;
	overflow: hidden;
	@extend .sidebarTransition;
}

.sidebarHidden:active {
	@extend .sidebarTransition;
}

.header {
	display: flex;
	justify-content: space-between;
	padding: 2.4rem;
	align-items: center;
	@include horizontalDivider;
}

.groupButton {
	width: 50%;
	margin-bottom: 3.5rem;
}

.title {
	@extend .fontSize20;
	@extend .fontWeight850;
}

.subTitle {
	color: $neutral;
}

.xIcon {
	height: 10px;
}

.footer {
	display: flex;
	justify-content: space-between;
	border-top: 1px solid $neutral200;
	padding: 2.4rem;
	width: 100%;
}

.footerButtons {
	width: 100%;
	height: 4.8rem;
}

.contentContainer {
	height: calc(100% - 18.5rem);
	padding: 0 2.4rem;
	overflow: scroll;
}

.section {
	@include horizontalDivider;
	padding: 2.4rem 0;
}

.sectionLast {
	padding: 2.4rem 0;
}

.sectionTitle {
	@extend .fontSize12;
	@extend .fontWeight650;
	text-transform: uppercase;
	color: $black;
	margin-bottom: 1.95rem;
}

.step {
	padding: 0.2rem 0.8rem;
	color: $white;
	background-color: $black;
	letter-spacing: 0.3px;
	border-radius: 0.3rem;
	margin-right: 0.8rem;
}

.userList {
	width: 90%;
}

.usageSubTitle {
	@extend .fontSize14;
	margin-bottom: 2rem;
}

.emptyState {
	@include flexColumnCenter;
	@extend .appearAnimation;
	background-color: $neutral50;
	padding: 2.4rem;
	height: calc(100% - 37rem);
}

.emptyCircle {
	@include flexCenter;
	height: 7.2rem;
	width: 7.2rem;
	border-radius: 5rem;
	background-color: $neutral100;
	margin-bottom: 1rem;
}

.text {
	@extend .fontWeight250;
	color: $neutral;
	text-align: center;
}

.usageSubTitleContainer {
	@include flexRowSpaceBetween;
}

.subActivityButton {
	padding: 0;
	margin-bottom: 2rem;
}
