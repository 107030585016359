@import '../../../constants/colors.scss';
@import '../../../constants/mixins.scss';
@import '../../../constants/global.scss';

.container {
	@extend .appearAnimation;
	@extend .minScreenSize;
	@include flex;
	width: 100%;
}
