@import '../IntegrationCard/IntegrationCard.module.scss';

.connectedTag {
	color: $success700;
	@extend .fontSize14;
	@extend .fontWeight450;
	background-color: $success50;
	align-self: flex-start;
	padding: 0 0.8rem;
}
