@import 'src/constants/colors.scss';
@import 'src/constants/global.scss';
@import 'src/constants/mixins.scss';

.button {
	width: fit-content;
	padding: 0.2rem 1rem;
	@extend .fontSize12;
	@extend .fontWeight450;
	@include flexCenter;
	transition: 400ms;
	height: unset !important;
}

.iconContainer {
	opacity: 0;
	transition: 400ms;
	display: none;
}

.button:hover .iconContainer {
	opacity: 1;
	transition: 400ms;
	@include flex;
}

.isShortContainer {
	@include flex;
	@include overflowHiddenEllipsisNowrap;
	width: fit-content;
	min-height: 1.8rem;
	padding: 0.2rem 1rem;
}

.tooltip {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	max-width: 32rem;
	white-space: break-spaces;
}

.reason {
	@include overflowHiddenEllipsisNowrap;
	width: 100%;
	@extend .fontSize12;
	@extend .fontWeight250;
}
