@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.summarySection {
	display: flex;
}

.box {
	@include flex;
	align-items: center;
}

.diviner {
	height: 5.4rem;
	width: 1px;
	background-color: $neutral200;
	margin: 0 2.4rem;
}
