@font-face {
	font-family: 'TWKLausanne150';
	src: local('TWKLausanne-150'), url('./fonts/TWKLausanne-150.woff') format('woff'), url('./fonts/TWKLausanne-150.woff2') format('woff2');
}

@font-face {
	font-family: 'TWKLausanne250';
	src: local('TWKLausanne-250'), url('./fonts/TWKLausanne-250.woff') format('woff'), url('./fonts/TWKLausanne-250.woff2') format('woff2');
}

@font-face {
	font-family: 'TWKLausanne350';
	src: local('TWKLausanne-350'), url('./fonts/TWKLausanne-350.woff') format('woff'), url('./fonts/TWKLausanne-350.woff2') format('woff2');
}

@font-face {
	font-family: 'TWKLausanne450';
	src: local('TWKLausanne-450'), url('./fonts/TWKLausanne-450.woff') format('woff'), url('./fonts/TWKLausanne-450.woff2') format('woff2');
}

@font-face {
	font-family: 'TWKLausanne650';
	src: local('TWKLausanne-650'), url('./fonts/TWKLausanne-650.woff') format('woff'), url('./fonts/TWKLausanne-650.woff2') format('woff2');
}

@font-face {
	font-family: 'TWKLausanne850';
	src: local('TWKLausanne-850'), url('./fonts/TWKLausanne-850.woff') format('woff'), url('./fonts/TWKLausanne-850.woff2') format('woff2');
}