@import '../../../constants/colors.scss';
@import '../../../constants/global.scss';
@import '../../../constants/mixins.scss';

.mainContainer {
	height: calc(100% - 16rem);
}

.header {
	@include borderNeutral;
	@extend .neutralFontSize12;
	height: 4.4rem;
	display: flex;

	background-color: $primary50;
}

.header .headerItem:first-child {
	padding-left: 1rem;
}

.rowsContainer {
	height: 100%;
	overflow: scroll;
}

.headerInput {
	border: none;
	@extend .neutralFontSize12;
	color: $neutral600;
	background-color: $primary50;
}

.fileContainer {
	@include flexCenter;
	width: 100%;
}

.switchContainer {
	@include flexCenter;
	padding: 1.2rem;
}

.switch {
	width: 3.4rem;
	height: 2rem;
}

.divider {
	border-right: 1px solid $neutral200;
	margin-right: 1rem;
	height: 100%;
}

.headerItem {
	@include flex;
	border: none;
	height: 4rem;
	border-radius: 0;
	@include verticalDivider;
	align-items: center;
	background-color: $primary50;
	min-width: 12rem;
	&:nth-child(2) {
		color: transparent;
		min-width: 9rem;
	}
}

.headerItem:hover {
	@include verticalDivider;
}

.row {
	@include flex;
	@include horizontalDivider;
	transition: 400ms;
	height: 7rem;
}

.headerItemText {
	padding-left: 1rem;
}

.hideRow {
	height: 0;
	overflow: hidden;
	transition: 400ms;
	border: none;
}

.showRow {
	transition: 400ms;
}

.rowItem {
	@include flexColumnCenter;
	align-items: center;
	min-width: 12rem;
	max-width: 13rem;
	@extend .fontSize14;
	@extend .fontWeight250;
	&:nth-child(2) {
		justify-content: center;
		min-width: 9rem;
		width: 9rem;
	}
}

.noImgIcon {
	@include flexCenter;
	@include borderNeutral;
	border-radius: 0.4rem;
	background-color: $neutral50;
	width: 8rem;
	height: 4rem;
}

.imgIcon {
	max-width: 8rem;
	max-height: 4rem;
	height: auto;
	width: auto;
}

.rowItemInput {
	padding-bottom: 0;
	@extend .fontSize14;
	@extend .fontWeight250;
	color: $neutral600;
	background-color: $white;
	border: none;
	box-shadow: none;
	width: calc(100% - 0.5rem);
	height: 3rem;
	padding: 1rem 1.4rem 0 1.4rem !important;
	&::placeholder {
		@extend .fontSize14;
		color: $neutral900;
	}
	@include overflowHiddenEllipsisNowrap;
}

.rowItemInput:focus-visible,
.rowItemInput:active,
.rowItemInput:focus {
	outline: none;
	border: none !important;
}

rowItemInput:-webkit-autofill,
rowItemInput:-webkit-autofill:hover,
rowItemInput:-webkit-autofill:focus,
rowItemInput:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px $white inset !important;
	border: none !important;
}

.selectedRow {
	background-color: $primary50;
}

.disabledRow {
	opacity: 0.6;
}

.disabledInput {
	pointer-events: none;
}

.notValidRow {
	background-color: $destructive50;
}

.notValidInput {
	@extend .fontWeight250;
	color: $destructive;
	&::placeholder {
		color: $destructive;
	}
	&:disabled {
		color: $destructive;
	}
}

.selectContainer {
	padding: 0;
	@extend .fontSize14;
	color: $neutral900;
	height: 3rem;
	border: none;
}

.badge {
	text-transform: capitalize;
	border-radius: 0.3rem;
	width: max-content;
	min-width: max-content;
}

.valueWrapper {
	@include flexColumnCenter;
}

.oldValue {
	text-decoration: line-through;
	@extend .fontSize12;
	@extend .fontWeight450;
	@include overflowHiddenEllipsisNowrap;
	width: calc(100% - 0.5rem);
	text-align: start;
	padding-left: 2.5rem;
	padding: 0rem 1.2rem;
}
