@import '../../../constants/colors.scss';
@import '../../../constants/global.scss';

.customersContainer {
	@extend .minScreenSize;
	@extend .appearAnimation;
}

.header {
	padding: 0rem 2.4rem;
}

.customersHeader {
	@include flex;
	justify-content: space-between;
	margin-bottom: 2.4rem;
	align-items: center;
	padding-top: 3.4rem;
}

.buttonsContainer {
	@include flex;
	height: 4rem;
	gap: 0.8rem;
}
.customerSearch {
	width: 19.2rem;
}

.customerButtons {
	@include flexCenter;
}

.tableContainer {
	height: calc(100% - 10rem);
	@extend .appearAnimation;
	border-radius: 0;
}

.tableRowClassName {
	@extend .fontWeight250;
}

.cellHeader {
	@extend .fontWeight450;
	@extend .fontSize12;
	color: $neutral;
}

.badges {
	@include flex;
	gap: 0.4rem;
	height: 2rem;
}

.cardsContainer {
	@include flex;
	gap: 1.4rem;
	margin-bottom: 1.4rem;
}

.box {
	border: 1px solid $neutral200;
	padding: 1.4rem;
	min-width: 19rem;
	flex: 1;
}

.emptyStateContainer {
	gap: 0.8rem;
}

.addIcon {
	margin-bottom: 0.2rem;
}
