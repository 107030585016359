@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.item {
	@include flex;
	height: 7.2rem;
	width: 100%;
	@include horizontalDivider;
}

.item:hover {
	background-color: $success50;
}

.isActive {
	background-color: $success100;
	border-left: 0.5rem $success solid;
}

.cell {
	@extend .fontSize14;
	@extend .fontWeight250;
	@include flexColumnCenter;
	justify-content: baseline;
	padding: 1.4rem;
	width: 12rem;

	&:nth-child(1) {
		width: 20rem;
		min-width: 20rem;
		align-items: flex-start;
		@include overflowHiddenEllipsisNowrap;
	}

	&:nth-child(2) {
		align-items: flex-start;
		width: 13.2rem;
		min-width: 13.2rem;
	}
	&:nth-child(3) {
		width: 11rem;
		min-width: 11rem;
		align-items: flex-start;
	}
	&:nth-child(4) {
		width: 10.8rem;
		min-width: 10.8rem;
		align-items: center;
	}
}

.defaultCell {
	flex: 3;
}

.statusCell {
	@include flexCenter;
	flex: 3.5;
	padding-right: 2em;
}

.badge {
	width: fit-content;
	border-radius: 0.5rem;
}

.itemCellSubValue {
	@extend .fontSize12;
	@extend .fontWeight250;
	color: $neutral400;
	align-self: flex-start;
}

.hamburgerContainer {
	width: 2rem;
	@include flexCenter;
}

.item:hover .hamburgerButton {
	display: flex;
}
