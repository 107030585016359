@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.cell {
	@extend .fontSize14;
	@extend .fontWeight250;
	@extend .flexColumn;
	justify-content: center;
	border: none;
	color: $neutral900;
	width: 100%;
	height: 100%;
	overflow: hidden;
	padding-left: 2.4rem;
	transition: 300ms;
	border: 1px solid transparent;
}

.inputContainer {
	@extend .flex;
	align-items: center;
	height: 2.5rem;
}

.cell:hover {
	border: 1px solid $neutral300;
	transition: 300ms;
}

.isEditable {
	background-color: $neutral50;
	transition: 300ms;
}

.editMode {
	border: 1px solid $black;
	background-color: $neutral50;
	transition: 300ms;
}

.editMode:hover {
	border: 1px solid $success400;
	transition: 300ms;
}


.input {
	@extend .fontSize14;
	@extend .fontWeight250;
	width: 100%;
	height: 100%;
	outline: none;
	border: none;
	color: $neutral700;
}

.input:focus {
	outline: none;
	border: none;
}
