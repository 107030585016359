@import '../../../constants/colors.scss';
@import '../../../constants/global.scss';

.container {
	position: relative;
}

.tooltipContainer {
	position: relative;
	background-color: $white;
	color: $neutral;
}

.blackTooltipContainer {
	background-color: $neutral900;
	color: $white;
}

.container .tooltipContainer {
	@extend .fontSize12;
	@extend .fontWeight450;
	visibility: hidden;
	padding: 1.5rem;
	position: absolute;
	z-index: 100;
	left: 50%;
	transform: translateX(-50%);
	border-radius: 0.6rem;
	box-shadow: 0rem 0.7rem 2rem 0.3rem rgba(0, 0, 0, 0.1);
	width: max-content;
}

.tooltipContainer::before {
	content: ' ';
	left: 50%;
	border: solid transparent;
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-width: 0.7rem;
	margin-left: calc(0.7rem * -1);
}

.tooltipContainer.top {
	bottom: calc(100% + 1rem);
}

.tooltipContainer.top::before {
	top: 100%;
	border-top-color: $white;
}

.blackTooltipContainer.top::before {
	top: 100%;
	border-top-color: $neutral900;
}

.tooltipContainer.right {
	left: calc(100% + 0.5rem);
	top: 50%;
	transform: translateX(0) translateY(-50%);
}

.tooltipContainer.right::before {
	left: calc(0.7rem * -0.8);
	top: 50%;
	transform: translateX(0) translateY(-50%);
	border-right-color: $white;
}

.blackTooltipContainer.right::before {
	border-right-color: $neutral900;
}

.tooltipContainer.bottom {
	bottom: calc(3rem * -1);
}

.tooltipContainer.bottom::before {
	bottom: 100%;
	border-bottom-color: $white;
}

.blackTooltipContainer.bottom::before {
	border-bottom-color: $neutral900;
}

.tooltipContainer.left {
	left: auto;
	right: 100%;
	top: 50%;
	transform: translateX(0) translateY(-50%);
}

.tooltipContainer.left::before {
	left: auto;
	right: calc(0.7rem * -1.9);
	top: 50%;
	transform: translateX(0) translateY(-50%);
	border-left-color: $white;
}

.blackTooltipContainer.left::before {
	border-left-color: $neutral900;
}

.container:hover .tooltipContainer {
	visibility: visible;
	position: absolute;
	z-index: 70;
}
