@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.header {
	@include flexCenter;
	justify-content: space-between;
	padding: 1rem 0 2rem 0;
	cursor: pointer;
}

.showBorder {
	border-bottom: 1px solid $neutral200;
	transition: 350ms;
}

.arrowCollapsed {
	transform: rotate(0deg);
	transition: 350ms;
}

.arrowExpanded {
	transform: rotate(180deg);
	transition: 350ms;
}

.collapsibleContent {
	height: 0;
	opacity: 0;
	overflow: hidden;
	transition: 400ms;
}

.expanded {
	opacity: 1;
	transition: 400ms;
}
