@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.container {
	@include borderNeutral;
	@include flex;

	position: fixed;
	width: 40rem;
	height: 9.5rem;
	box-shadow: 0px 20px 25px -5px rgba(16, 24, 40, 0.1), -10px 8px 40px -6px rgba(16, 24, 40, 0.1);
	background-color: $white;
	border-radius: 1rem;
	z-index: 60;
	bottom: 1rem;
	right: -40rem;
	overflow: hidden;
	animation: appear 500ms linear forwards;
	animation-delay: 200ms;
}

@keyframes appear {
	0% {
		right: -40rem;
		transform: skew(0deg);
	}
	80% {
		right: 3rem;
		transform: skew(0deg);
	}
	81% {
		right: 3rem;
		transform: skew(10deg);
	}
	100% {
		right: 1rem;
	}
}

.blueContainer {
	position: relative;
	display: flex;
	align-items: flex-end;
	width: 5.2rem;
	height: 100%;
}

.blueSide {
	background-color: $primary;
	width: 5.2rem;
	height: 100%;
	transition: 1.5s;
}

.informationIcon {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
}

.textContainer {
	width: calc(100% - 9rem);
	padding: 1.4rem 0.4rem 1.4rem 1.4rem;
}

.xIcon {
	width: 3.2rem;
	height: 3.2rem;
	margin: 1rem 0;
}

.text {
	@extend .fontSize12;
	color: $neutral;
}

.link {
	text-decoration: underline;
	cursor: pointer;
}
