@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.cell {
	@include overflowHiddenEllipsisNowrap;
	@extend .fontSize14;
	@extend .fontWeight250;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	color: $neutral700;
	padding-left: 2.4rem;
	border: 1px solid transparent;
	transform: 400ms;
}

.cell:focus {
	transform: 400ms;
	background-color: $neutral50;
	border: 1px solid $black;
	outline: none;
}

.cell:hover {
	border: 1px solid $neutral300;
	transform: 400ms;
}

.isEditable {
	border: 1px solid $black;
	background-color: $neutral50;
	transform: 400ms;
}

.isEditable:hover {
	transform: 400ms;
	border: 1px solid $success400;
}
