@import 'src/constants/colors';
@import 'src/constants/global';
@import 'src/constants/mixins';

.container {
	height: 26.2rem;
	width: 27.4rem;
	@include borderNeutral;
	@include flexColumn;
	color: $neutral400;
	padding: 1.4rem;

	&:hover {
		background-color: $neutral50;
		.icon {
			background-color: $white;
		}
	}

	&:active {
		transform: scale(1.01);
	}
}

.topSection {
	@include flexRowSpaceBetween;
	width: 100%;
	align-items: flex-start;
	height: 9.5rem;
}

.bottomSection {
	margin-top: 1rem;
	flex: 1.8;
	text-align: start;
	width: 100%;
	@include flexColumnSpaceBetween;
	line-height: 2rem;
}

.tag {
	width: fit-content;
	color: $success700;
	max-width: 14rem;
	@include overflowHiddenEllipsisNowrap;
}

.square {
	height: 6.4rem;
	width: 6.4rem;
	background-color: $white;
	@include borderNeutral;
	margin-right: 0.8rem;
	@include flexCenter;
}

.hamburgerButton {
	height: 5rem;
	width: 5rem;
	border: none;
	color: $neutral700;
	padding: 0;
	&:hover {
		cursor: default;
	}

	@include flex;
	align-items: flex-start;
	justify-content: flex-end;
}

.title {
	@extend .fontSize24;
	@extend .fontWeight650;
	@include overflowHiddenEllipsisNowrap;
	color: $neutral800;
	max-width: 22rem;
}

.badge {
	background-image: url('data:image/svg+xml;utf8,<svg width="14" height="16" viewBox="0 0 14 16" fill="rgba(254, 243, 199, 1)" xmlns="http://www.w3.org/2000/svg"><path d="M1.55469 2.55556C1.55469 1.69645 2.25113 1 3.11024 1H10.888C11.7471 1 12.4436 1.69645 12.4436 2.55556V15L6.99913 12.2778L1.55469 15V2.55556Z" stroke="rgba(252, 211, 77, 1)" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/></svg>');
	background-repeat: no-repeat;
	height: 2em;
	width: 2.5rem;
	background-size: 2rem 2rem;
	background-position: top 0.7rem right 0.5rem;
}

.subTitle {
	@extend .fontWeight650;
	color: $neutral600;
}

.location {
	width: 90%;
	@include overflowHiddenEllipsisNowrap;
	line-height: 2rem;
}

.country::before {
	content: ' ,';
}

.hidden {
	display: 'none';
}

.tag {
	@extend .fontSize14;
	color: $success700;
	padding: 0.2rem 1rem;
	background-color: $success100;
	margin-right: 0.5rem;
	border-radius: 0.03rem;
}

.icon {
	background-color: 'none';
}

.logo {
	height: auto;
	width: auto;
	max-width: 18rem;
	max-height: 10rem;
}
