@import '../../../constants/colors.scss';
@import '../../../constants/mixins.scss';
@import '../../../constants/global.scss';

.summarySection {
	display: flex;
	gap: 2.4rem;
	width: 100%;
	height: 19.6rem;
	overflow-x: scroll;
	padding: 2.4rem;
}
