@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.tooltip {
	width: 100%;
	height: 100%;
}

.cell {
	@extend .fontSize14;
	@extend .fontWeight250;
	@include flex;
	justify-content: space-between;
	border: none;
	width: 100%;
	height: 100%;
	align-items: center;
	overflow: hidden;
	padding-left: 2.4rem;
	transition: 300ms;
	border: 1px solid transparent;
	color: $neutral700;
}

.cell:hover {
	border: 1px solid $neutral300;
	transition: 300ms;
}

.cell:focus {
	background-color: $neutral50;
	border: 1px solid $black;
	outline: none;
	transition: 300ms;
}

.formula {
	@include flexCenter;
	@extend .fontWeight450;
	@extend .fontSize12;
	@include borderNeutral;
	padding: 0 0.6rem;
	text-transform: uppercase;
}

.centerText {
	height: 1rem;
	width: 1rem;
}

.editMode {
	border: 1px solid $black;
	background-color: $neutral50;
	transition: 300ms;
}

.editMode:hover {
	border: 1px solid $success400;
	transition: 300ms;
}

.tooltipPlacement {
	bottom: 50% !important;
}

.billingCycle {
	@extend .fontSize14;
	color: $neutral400;
	padding-left: 0.2rem;
}
