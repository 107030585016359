@import 'src/constants/colors';
@import 'src/constants/mixins';
@import 'src/constants/global';

.container {
	@include flexColumn;
	min-width: 33.6rem;
	padding: 2.4rem;
	background: $white;
	height: fit-content;
	border-bottom: 1px solid $neutral200;
}

.icon {
	height: 1.4rem;
	width: 1.4rem;
}

.subTitle {
	@extend .fontSize14;
	color: $neutral600;
}

.row {
	@include flexRowSpaceBetween;
	height: 4.8rem;
	margin-bottom: 1rem;
}

.sumWrapper {
	@include flexCenter;
	gap: 1.4rem;
}

.totalSumWrapper {
	@extend .fontSize20;
	@extend .fontWeight650;
	color: $neutral700;
}

.total {
	@extend .fontSize20;
	@extend .fontWeight650;
	padding-right: 2rem;
}

.title {
	@extend .fontSize18;
	@extend .fontWeight250;
	margin-bottom: 2rem;
}

.boldSubTitle {
	@extend .fontSize14;
	@extend .fontWeight650;
	color: $neutral600;
}

.note {
	@extend .fontSize12;
	@extend .fontWeight250;
	padding-bottom: 1rem;
	color: $neutral;
}

.creditRow {
	@include flexRowSpaceBetween;
	margin-bottom: 2rem;
}

.fadedText {
	opacity: 50%;
}
