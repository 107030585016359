@import '../../../constants/colors.scss';
@import '../../../constants/global.scss';
@import '../../../constants/mixins.scss';

.container {
	@extend .minScreenSize;
	@extend .appearAnimation;
}

.referralAgreement {
	@include flexCenter;
	align-items: center;
}

.header {
	padding: 0rem 2.4rem;
}

.customHeader {
	display: flex;
	justify-content: space-between;
	margin-bottom: 2.4rem;
	align-items: center;
	padding-top: 3.4rem;
}

.buttonsContainer {
	display: flex;
	height: 4rem;
	gap: 0.8rem;
}

.search {
	width: 19.2rem;
}

.tableContainer {
	height: calc(100% - 8rem);
	@extend .appearAnimation;
	border-radius: 0;
	border: none;
}

.tableRowClassName {
	@extend .fontWeight250;
}

.extendedTableRowClassName {
	@extend .fontWeight250;
	height: 4.5rem;
}

.createButton {
	margin-top: 1.4rem;
}

.emptyButton {
	margin-top: 1.4rem;
}

.iconContainerClassName {
	border: none;
}

.addIcon {
	margin-bottom: 0.4rem;
}

.addIcon {
	margin-bottom: 0.2rem;
}

.createAgreement {
	height: 2rem;
	width: fit-content;
}
