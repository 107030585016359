@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';

.modalContainer {
	width: 41.1rem;
	padding: 2.4rem;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.footerButtons {
	width: 36.3rem;
	height: 4.8rem;
}
