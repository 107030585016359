@import '../../../constants/colors.scss';
@import '../../../constants/mixins.scss';
@import '../../../constants/global.scss';

.container {
	@include flexColumn;
	gap: 1.4rem;
}

.tag {
	background-color: $neutral100;
	border-radius: 0.3rem;
	padding: 0 1rem;
	margin: 0.2rem;
	height: 2rem;
	gap: 0.4rem;
	max-width: 98%;
}

.deleteIcon {
	height: 0.8rem;
	width: 0.8rem;
}

.tagText {
	@extend .fontSize14;
	@extend .fontWeight450;
	color: $neutral700;
	@include overflowHiddenEllipsisNowrap;
	max-width: 100%;
}

.deleteButton {
	width: 0.4rem;
	height: 0.2rem;
	padding: 0.8rem;
	border-radius: 1rem;
}

.tagsContainer {
	@include flex;
	flex-wrap: wrap;
	max-height: 7rem;
	min-height: 0;
	width: 100%;
	overflow: scroll;
}

.hide {
	display: none;
}

.removeAllEmails {
	@extend .fontSize12;
	height: 2rem;
	width: 15rem;
}
