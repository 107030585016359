@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.emptyContainer {
	position: relative;
	@include flexCenter;
	height: 100%;
	width: 100%;
}

.container {
	position: relative;
}

.totalText {
	position: absolute;
	font-size: 170%;
	line-height: 4.8rem;
	right: 50%;
	bottom: 50%;
	transform: translate(50%, 50%);
}
