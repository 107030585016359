@import '/src/constants/colors';
@import '/src/constants/mixins';
@import '/src/constants/global';

.container {
	@include flex;
	min-height: 100%;
	width: 100%;
}

.pricingSettings {
	@include flexColumn;
	flex: 1;
	width: 30.3rem;
	min-height: 100%;
	background-color: $neutral50;
	border-bottom: 1px solid $neutral200;
}

.tableContainer {
	width: calc(100% - 30.3rem);
	min-height: 100%;
	border: none !important;
	border-right: 1px solid $neutral200 !important;
	border-radius: 0 !important;
}
