@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.tabsSection {
	@include flexColumn;
	justify-content: space-between;
	height: calc(100% - 5rem);
	transition: 150ms;
}

.container {
	height: 100%;
	padding: 2.4rem;
	overflow: scroll;
}

.title {
	@extend .fontSize14;
	display: block;
}

.grayText {
	@extend .fontSize12;
	color: $neutral;
}

.headerOverviewContainer {
	@include flexCenter;
	justify-content: space-between;
	padding-bottom: 1.5rem;
}

.footer {
	padding: 1rem 2.4rem;
}

.cards {
	@include flexColumn;
	gap: 1.4rem;
}
