.truncateList {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
	list-style: none;
	overflow: auto;
}

.truncateList *,
.truncateList *::before,
.truncateList *::after {
	box-sizing: inherit;
}
