@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.container {
	padding: 2.4rem 2.4rem 15rem;
	@include flexColumnCenter;
	height: 100%;
	justify-content: flex-start;
	margin-top: 10rem;
}

.img {
	height: auto;
	width: 11rem;
}

.title {
	@extend .fontSize14;
	@extend .fontWeight650;
}

.description {
	@extend .fontWeight250;
	color: $neutral;
	padding: 1rem 1rem 1.4rem 0;
	text-align: center;
	margin: 0 3rem;
}

.button {
	height: 4.8rem;
	width: 24rem;
}
