@import '../../constants/colors.scss';
@import '../../constants/mixins.scss';
@import '../../constants/global.scss';

.detailsContainer {
	@include flexColumn;

	&:last-child {
		.bodyContainer {
			border: none;
		}
	}
}

.iconContainer {
	width: 4.8rem;
	height: 4.8rem;
	background-color: $neutral100;
	@include flexCenter;
}

.dataContainer {
	@include flexColumn;
}

.body {
	@extend .fontSize18;
	@extend .fontWeight650;
	color: $neutral900;
}

.bodyContainer {
	@include flex;
	border-right: 1px solid $neutral300;
	padding-right: 8.3rem;
}

.title {
	text-transform: uppercase;
}

.text {
	color: $neutral;
	@extend .fontSize12;
}

.hidden {
	width: 0;
}

.arrow {
	align-self: center;
	margin-left: 0.9rem;
}

.arrowUp {
	transition: 400ms;
}

.arrowDown {
	transition: 400ms;
	transform: rotate(180deg);
}

.footer {
	margin-top: 0.4rem;
}
