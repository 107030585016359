@import 'src/constants/colors';
@import 'src/constants/mixins';
@import 'src/constants/global';

.mainContainer {
	width: 47.3rem;
	max-height: calc(100vh - 5rem);
	height: 70rem;
	min-height: 40rem;
}

.header {
	@include horizontalDivider;
	height: 10rem;
	padding: 2.4rem;
	display: flex;
	justify-content: space-between;
}

.xIcon {
	height: 12px;
}

.title {
	@extend .fontSize20;
	@extend .fontWeight850;
}

.description {
	@extend .fontWeight250;
	line-height: 2.4rem;
	color: $neutral;
}

.subTitle {
	@extend .fontSize14;
}

.dataContainer {
	padding: 0 2.4rem;
	overflow: scroll;
	height: calc(100% - 20rem);
	min-height: calc(100% - 20rem);
	@include flexColumn;
	flex: 1;
}

.structureList {
	overflow: scroll;
	max-height: 20rem;
	overflow: scroll;
	height: 100%;
	min-height: 5rem;
}

.dataSection {
	@include horizontalDivider;
	padding: 1.4rem 0;
	min-height: 12rem;
}

.listSection {
	height: 100%;
	overflow: scroll;
}

.structureTitle {
	display: flex;
	justify-content: space-between;
}

.structureItem {
	display: flex;
	justify-content: space-between;
	height: 4rem;
	border-left: 1px solid $neutral200;
	margin-top: 1.2rem;
	padding-left: 0.8rem;
}

.structureTopText {
	@extend .fontSize12;
	color: $neutral600;
}

.structureBottomText {
	@extend .fontSize12;
	@extend .fontWeight250;
	color: $neutral400;
}

.footer {
	align-self: flex-end;
	padding: 2.4rem;
	display: flex;
	justify-content: space-between;
	height: 10rem;
	border-top: 1px solid $neutral200;
}

.publishButton {
	width: 16rem;
	height: 4.8rem;
}

.summary {
	display: flex;
	justify-content: space-between;
	padding: 1.4rem 0;
}

.grayText {
	@extend .fontSize12;
	@extend .fontWeight250;
	color: $neutral;
}

.grayTextValue {
	color: $neutral;
}

.ellipsis {
	@include overflowHiddenEllipsisNowrap;
	max-width: 42rem;
}
