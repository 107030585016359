@import '../../../constants/colors.scss';
@import '../../../constants/mixins.scss';
@import '../../../constants/global.scss';

.container {
	@include flex;
	gap: 1.4rem;
	height: 21.4rem;
}

.doubleCardContainer {
	@include flexColumn;
	gap: 1.4rem;
}

.arrMrr {
	@extend .doubleCardContainer;
	flex: 1;
	min-width: 14rem;
	max-width: 18rem;
}

.card {
	@include borderNeutral;
	background-color: $white;
	padding: 2.4rem;
	height: 10rem;
}

.doubleCard {
	@extend .card;
	@include flexColumn;
	height: 100%;
}

.billedCurrentMonth {
	@extend .doubleCard;
	flex: 1;
	justify-content: space-between;
	max-width: 29rem;
}

.upForRenewal {
	@extend .doubleCard;
	max-width: 22.8rem;
	min-width: 16rem;
	flex: 1;
}

.readyToSend {
	@extend .doubleCardContainer;
	flex: 1;
	min-width: 27rem;
}

.graphContainer {
	@extend .doubleCard;
	flex: 1;
	max-width: calc(100% - 22.8rem);
	min-width: 20rem;
}

.cardWithIcon {
	@extend .card;
	@include alignItemsCenter;
	gap: 1.4rem;
}

.iconContainer {
	@include flexCenter;
	height: 5.2rem;
	width: 5.2rem;
	border-radius: 3rem;
	background-color: $neutral100;
}

.rightSection {
	@include flex;
	gap: 1.4rem;
	width: 43%;
}

.leftSection {
	@include flex;
	gap: 1.4rem;
	width: 57%;
}

.graphContainerClassName {
	height: 88%;
	width: 51%;
	transition: 400ms;
}

.loadingLine {
	margin-top: 0.4rem;
}

.zero {
	color: $neutral400;
}

@media only screen and (max-width: 1500px) {
	.topProductGraphContainer {
		justify-content: center;
	}
	.graphContainerClassName {
		width: 85%;
		min-width: 16rem;
		transition: 400ms;
	}
	.itemsContainerClassName {
		display: none;
	}

	.rightSection {
		width: 35%;
	}

	.leftSection {
		width: 65%;
	}
}
