@import 'src/constants/mixins';
@import 'src/constants/colors';
@import 'src/constants/global';

.mainButton {
	@extend .fontWeight250;
	width: 25rem;
}

.selectDropdownContainer {
	position: fixed;
	width: 25rem;
	z-index: 20;
	@include defaultBoxShadow;
	border-radius: 0 0 0.6rem 0.6rem;
	overflow: scroll;
}

.selectDropdownContainerOpen {
	border: 1px solid $neutral200;
	max-height: 18.8rem;
	transition: 400ms;
}

.selectDropdownClose {
	height: 0;
	overflow: hidden;
	transition: 400ms;
}

.selectItem {
	height: 4rem;
	margin-top: 0.2rem;
	width: 100%;
}

.arrowDown {
	width: 15px;
	transition: 400ms;
}

.arrowUp {
	width: 15px;
	transform: rotate(180deg);
	transition: 400ms;
}

.selectOverlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10;
	opacity: 0.5;
}
