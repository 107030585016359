@import 'src/constants/colors';
@import 'src/constants/mixins';
@import 'src/constants/global';

.pageHeader {
	@include horizontalDivider;
	padding: 2.4rem;
}

.actionBar {
	@include horizontalDivider;
	display: flex;
	padding: 2.4rem;
	gap: 0.8rem;
	align-items: center;
}

.actionBarButtons {
	display: flex;
	min-width: 31.6rem;
	gap: 0.8rem;
}

.description {
	@extend .fontWeight250;
	color: $neutral400;
}

.tableContainer {
	border: 0;
	box-shadow: none;
}

.tableRowContainer {
	grid-template-columns: 32rem repeat(3, minmax(14rem, 1fr));
}

.sendInvitationButton {
	width: 12.9rem;
}

.capitalLetter {
	text-transform: capitalize;
}

.icon {
	border: none;
}
