@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.container {
	@include flexColumn;
}

.title {
	color: $neutral900;
	@extend .fontSize14;
	@extend .fontWeight450;
	color: $neutral700;
}

.quantity {
	@extend .fontSize18;
	@extend .fontWeight250;
	color: $neutral600;
}

.quantity {
	@extend .fontSize18;
	@extend .fontWeight250;
	color: $neutral600;
}

.descriptionContainer {
	@include flex;
	align-items: center;
	@extend .fontSize12;
	@extend .fontWeight450;
}

.up {
	transform: rotate(90deg);
}

.down {
	transform: rotate(270deg);
}

.subText {
	@include overflowHiddenEllipsisNowrap;
	max-width: 22rem;
}
