@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.container {
	@include flexColumn;
	justify-content: space-between;
	height: calc(100% - 5rem);
	background-color: $white;
	box-shadow: 0px 20px 25px -5px rgba(16, 24, 40, 0.1), -10px 8px 40px -6px rgba(16, 24, 40, 0.1);
	min-width: 400px;
}

.errorContainer {
	@include flexCenter;
	width: 100%;
	height: 100%;
	background-color: rgba(17, 24, 39, 0.5);
	cursor: pointer;
}

.refreshImage {
	position: absolute;
	top: 35rem;
	right: 21rem;
	opacity: 1;
	width: 14.2rem;
	z-index: 99999;
}

.refreshView {
	z-index: 9;
	background-color: rgba(17, 24, 39, 0.5);
	position: absolute;
	max-width: 59.5rem !important;
	height: calc(100% - 12.7rem);
	width: 100% !important;
	min-width: 400px !important;
	cursor: pointer;

	&:hover {
		background-color: rgba(17, 24, 39, 0.3);
	}
}

.pdf {
	width: 100% !important;
	height: 100% !important;
	@include flex;
}

.pagePdf {
	width: 100% !important;
	height: 100% !important;
	min-height: 120px !important;
	min-width: 400px !important;
	margin-bottom: 20rem;
	@extend .appearAnimation;

	canvas {
		width: 100% !important;
	}
}

@media (max-width: 1300px) {
	.pagePdf {
		canvas {
			width: 100% !important;
		}
	}
}
