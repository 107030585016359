@import 'src/constants/colors';
@import 'src/constants/global';

.container {
	@include flexColumnCenter;
	width: 24%;
	height: 35rem;
	text-align: center;
}

.button {
	@extend .fontSize14;
	width: 24rem;
	color: $white;
}

.header {
	@extend .fontSize32;
	@extend .fontWeight850;
	margin-bottom: 0.4rem;
}
