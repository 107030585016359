@import 'src/constants/colors';
@import 'src/constants/mixins';
@import 'src/constants/global';

.box {
	@include flex;
	background-color: $white;
	border: 1px solid $neutral200;
	height: fit-content;
	padding: 2.4rem;
	gap: 2.4rem;
}

.fullBox {
	width: 100%;
	@include flexColumn;
	padding: 0;
	gap: 0;
	max-height: 50rem;
}

.minWidthBox {
	@include flexColumn;
	width: fit-content;
	min-width: 10rem;
}

.title {
	color: $neutral;
	@extend .fontWeight450;
}

.text {
	@extend .fontSize24;
	@extend .fontWeight250;
	line-height: 3.2rem;
}

.boldTitle {
	@extend .fontSize24;
}

.topInfo {
	@include flex;
	justify-content: space-between;
	width: 100%;
	align-items: center;
}

.topInfoContainer {
	@include flexColumn;
	justify-content: space-between;
	row-gap: 2.4rem;
	width: 100%;
	border-bottom: 1px solid $neutral200;
	padding: 2.4rem 2.4rem 1.4rem 2.4rem;
}

.bottomInfoContainer {
	width: 100%;
	padding: 0 2.4rem 0 2.4rem;
	@include flex;
}

.timeFilter {
	@include flexCenter;
	gap: 1.4rem;
}

.dates {
	@extend .fontSize14;
	@extend .fontWeight650;
	color: $neutral;
}

.leftBottomInfo {
	@include flexColumn;
	border-right: 1px solid $neutral200;
	width: 70%;
	padding: 2.4rem 2.4rem 2.4rem 0;
	height: 100%;
	justify-content: space-between;
}

.emptyState {
	justify-content: start;
	gap: 3rem;
}

.rightBottomInfo {
	width: 30%;
	padding: 2.4rem 0 2.4rem 2.4rem;
}

.productDataContainer {
	@include flex;
	width: 100%;
	height: 6rem;
	max-height: 6rem;
	gap: 2.4rem;
}

.select {
	padding: 1.4rem;
}

.noDataImage {
	width: 17rem;
	height: 17rem;
}

.emptyState {
	@include flexColumn;

	.topEmptyState {
		padding: 2.4rem;
		border-bottom: 1px solid $neutral200;
	}

	.emptyStateBody {
		@include flexColumnCenter;
		padding: 10rem 2.4rem;
	}
}

@media only screen and (max-width: 1400px) {
	.container {
		padding: 2.4rem 13rem;
	}
}

@media only screen and (max-width: $minScreenResolution) {
	.container {
		padding: 2.4rem 9rem;
	}
}
