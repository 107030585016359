@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.container {
	z-index: 50;
}

.collapsible {
	padding: 1.4rem 2.4rem 1rem 1.4rem;
}

.expandedGroup {
	@include flexColumn;
	max-height: 100%;
	transition: max-height 0.3s ease-out;
	gap: 1.4rem;
}

.axisContainer {
	@include flex;
	gap: 2.4rem;
	position: relative;
}

.timeline {
	width: 5rem;
	z-index: -1;
	position: absolute;
	left: 1.15rem;
	top: 0;
	height: 100%;
	border-left: 1px solid $neutral200;
}
