@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.modal {
	@include flexColumn;
	width: 100%;
	height: 100%;
	background-color: rgba(17, 24, 39, 0.4);
	align-items: center;
	justify-content: flex-start;
	border-radius: 0;
	@extend .appearAnimation;
}

.container {
	@include flexColumn;
	width: 30.8rem;
	background-color: $white;
	border-radius: 1rem;
	padding: 1.4rem;
}

.header {
	@include flexColumn;
	padding-bottom: 1.4rem;
	@include horizontalDivider;
}

.title {
	@extend .fontSize12;
	@extend .fontWeight450;
	color: $neutral;
}

.mainTitle {
	@extend .fontSize24;
	@extend .fontWeight650;
	color: $neutral900;
}

.desc {
	@extend .fontSize14;
	@extend .fontWeight450;
	color: $neutral600;
}

.body {
	@include flexColumn;
	gap: 1rem;
	justify-content: center;
	padding: 1.4rem 0 2rem 0;
}

.button {
	@extend .fontSize12;
	@extend .fontWeight650;
	margin-top: 1.4rem;
}

.inputsWrapper {
	@include flex;
}

.select {
	border-radius: 0 0.6rem 0.6rem 0;
	padding: 0 0.8rem;
}

.input {
	border-radius: 0.6rem 0 0 0.6rem;
	padding: 1.4rem;
}
