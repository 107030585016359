@import '../../constants/colors.scss';
@import '../../constants/mixins.scss';
@import '../../constants/global.scss';

.customersContainer {
	display: flex;
	align-items: center;
}

.defaultIconStyle {
	@include borderNeutral;
	margin-right: 1.4rem;
	background-color: $neutral50;
}

.imageIcon {
	width: 7.8rem;
	max-width: 7.8rem;
	height: auto;
}

.imageIconSmall {
	width: 4rem;
	max-width: 4rem;
}

.logoContainer {
	height: 7.8rem;
	width: 7.8rem;
}

.logoContainerSmall {
	height: 4rem;
	width: 4rem;
}

.name {
	@extend .fontSize24;
	@extend .fontWeight650;
	display: block;
}

.description {
	@extend .fontWeight250;
	color: $neutral;
}

.nameSmall {
	@extend .fontSize14;
	display: block;
}

.descriptionSmall {
	@extend .fontWeight250;
	color: $neutral;
}

.noAvatarContainer {
	@extend .fontSize32;
	@extend .fontWeight650;
	color: $neutral400;
	display: flex;
	align-items: center;
	justify-content: center;
}

.syncedIcon {
	position: absolute;
	height: 2.8rem;
	top: 8.5rem;
	left: 8.5rem;
	border-radius: 6rem;
}

.emails {
	display: inline;
	color: $neutral;
	@extend .fontWeight250;
}

.arrow {
	align-self: center;
	width: 2.4;
	margin-left: 0.8rem;
}

.arrowUp {
	transform: rotate(180deg);
	transition: 400ms;
}

.arrowDown {
	transition: 400ms;
}

.partnerTag {
	@extend .fontSize12;
	background-color: $neutral100;
	width: fit-content;
	padding: 0 0.6rem;
	border-radius: 0.4rem;
}
