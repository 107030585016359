@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';
@import 'src/constants/global.scss';

.container {
	@include borderNeutral;
	@include flexColumn;
	padding: 2.4rem;
	width: 30rem;
	min-height: 16rem;
	height: max-content;
	position: relative;
	z-index: 45;
	background-color: $white;
	gap: 1.4rem;
	box-shadow: -10px 8px 40px -6px rgba(16, 24, 40, 0.09), 0 20px 25px -5px rgba(16, 24, 40, 0.09);
}

.title {
	@extend .fontSize18;
	@extend .fontWeight450;
}

.modalOverlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 40;
	background-color: rgba(17, 24, 39, 0);
}

.advancedSettings {
	width: 4rem;
	height: 4rem;
}
