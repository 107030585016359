@import 'src/constants/colors.scss';
@import 'src/constants/mixins.scss';

.container {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.subText {
  color: $neutral700;
}
