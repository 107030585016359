@import '../../constants/colors.scss';
@import '../../constants/mixins.scss';
@import '../../constants/global.scss';

.customersContainer {
	display: flex;
	align-items: center;
	height: 8.4rem;
	padding: 1.4rem;
}

.defaultIconStyle {
	@include flexCenter;
	@include borderNeutral;
	border-radius: 0.5rem;
	margin-right: 1.4rem;
	height: 6rem;
	width: 4rem;
}

.name {
	@extend .fontSize24;
	@extend .fontWeight650;
	display: block;
}

.description {
	@extend .fontWeight250;
	color: $neutral;
}

.nameSmall {
	@extend .fontSize14;
	display: block;
}

.descriptionSmall {
	@extend .fontWeight250;
	@extend .fontSize14;
	color: $neutral;
}

.syncedIcon {
	position: absolute;
	height: 2.8rem;
	top: 8.5rem;
	left: 8.5rem;
	border-radius: 6rem;
}

.emails {
	display: inline;
	color: $neutral;
	@extend .fontWeight250;
}

.arrow {
	align-self: center;
	width: 2.4;
	margin-left: 0.8rem;
}

.arrowUp {
	transform: rotate(180deg);
	transition: 400ms;
}

.arrowDown {
	transition: 400ms;
}

.dot {
	@extend .fontSize12;
	color: $neutral300;
}

.stripeTag {
	@include flex;
	align-items: center;
	height: 2rem;
	gap: 0.2rem;
	text-align: end;
}

.stripeIcon {
	height: 1.5rem;
}

.stripeText {
	@extend .fontWeight250;
	@extend .fontSize14;

	padding-top: 0.4rem;
}
