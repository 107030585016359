@import '../../../constants/colors.scss';
@import '../../../constants/mixins.scss';
@import '../../../constants/global.scss';

.pageContainer {
	@extend .minScreenSize;
	@extend .appearAnimation;
	padding: 0 2.4rem 2.4rem 2.4rem;
	height: unset;
}

.pageHeader {
	@include flexRowSpaceBetween;
	margin-bottom: 2.4rem;
	padding-top: 3.4rem;
}

.topAnalyticsContainer {
	@include flex;
	gap: 1.4rem;
	margin-bottom: 1.4rem;
}

.summaryColorCards {
	@include flex;
	flex: 3;
}

.InvoicesForReviewCards {
	@include flex;
	flex: 2;
}

.buttonsContainer {
	@include flex;
	height: 4.8rem;
	gap: 0.8rem;
	align-items: center;
	justify-content: flex-start;
}

.summarySection {
	box-shadow: none !important;
	padding: 1.4rem 2.4rem;
}

.summarySectionIcon {
	margin: 1.4rem 1.4rem 1.4rem 0 !important;
}

.description {
	color: $neutral400;
	@extend .fontWeight250;
}

.allInvoices {
	color: $neutral700;
	background-color: $white;
}

.allInvoicesIcon {
	margin-left: 0.8rem;
}

.activityDescription {
	@extend .fontSize12;
	color: $neutral400;
}

.activityContainer {
	@include flex;
	gap: 1.4rem;
	height: calc(100% - 30rem);
}

.analyticsContainer {
	@include flex;
	height: 45%;
	justify-content: space-between;
}

.pieContainer {
	padding: 2.4rem 2.4rem 0 2.4rem;
	@include defaultBoxShadow;
	height: 33.4rem;
	border: 1px solid $neutral200;
}

.leftTop {
	padding: 2.4rem;
	@include defaultBoxShadow;
	height: 33.4rem;
	border: 1px solid $neutral200;
}

.stackGraphContainer {
	height: 85%;
}

.table {
	height: 51.5rem;
	margin-top: 1.4rem;
	border: 1px solid $neutral200;
}

.cardTitle {
	@extend .fontSize18;
	@extend .fontWeight450;
}

.cardSubTitle {
	@extend .fontSize12;
	color: $neutral400;
}

.pieGraphContainer {
	height: calc(100% - 6rem);
}

.leftSide {
	width: 70%;
	@include flexColumn;
}

.rightSide {
	width: 29%;
	@include flexColumn;
}

.emptyStateCard {
	height: calc(100% - 2.4rem);
}

.logoCellSubAccessor {
	width: calc(100% - 1.5rem) !important;
}

.logoCellAccessor {
	width: calc(100% - 1.5rem);
}
