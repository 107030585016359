@import 'src/constants/colors';
@import 'src/constants/global';
@import 'src/constants/mixins';

.content {
	padding: 1rem 3.4rem 0;
	height: 52rem;
	@include horizontalDivider;
	max-height: calc(100% - 20rem);
	overflow: scroll;
}

.firstRow {
	@include flex;
	gap: 2.4rem;
}

.fileRow {
	width: 50%;
}

.detailsForm {
	@include flex;
	width: 100%;
	margin-top: 1rem;
	justify-content: space-around;
	padding: 0 1rem;
	height: 32.8rem;
}

.leftContainer {
	width: 50%;
	margin-right: 1.4rem;
	@include flexColumn;
	gap: 1.2rem;
}

.rightContainer {
	width: 50%;
	margin-left: 1.4rem;
	@include flexColumn;
	gap: 1.2rem;
}

.inputClass {
	@include borderNeutral;
}

.input {
	padding-bottom: 0.04rem;
}

.button {
	margin-top: 2rem;
	width: 100%;
	height: 4.8rem;
}

.buttonContainer {
	padding: 0 2.4rem;
}

.fullWidth {
	width: 100%;
}

.phonePrefix {
	width: 8.3rem;
	margin-right: 0.8rem;
}

.phoneTitle {
	@extend .fontSize14;
}

.zipCode {
	width: 10.7rem;
	margin-left: 0.8rem;
}

.zipCodeTitle {
	margin-left: 0.8rem;
}

.zipCodeWithoutState {
	width: 28.1rem;
}
