@import '../../../constants/colors.scss';
@import '../../../constants/mixins.scss';
@import '/src/constants/global';

.modal {
	z-index: 50;
}

.header {
	display: flex;
	justify-content: space-between;
	padding: 14px 20px 0 24px;
}

.dot {
	@extend .fontSize12;
	color: $neutral400;
	margin: 0 5px;
}

.title {
	@extend .fontSize12;
	color: $neutral;
}

.resetTodayButtons {
	@extend .fontSize12;
	padding: 0;
}

.dayPickerContainer {
	@extend .fontWeight450;
	display: flex;
	justify-content: space-around;
	min-height: 346px;
	background-color: $white;
	color: $neutral900 !important;
	font-size: 16px;
	padding: 0 0 0 10px;
	margin-right: 10px;
}

.dayPickerMonthlyContainer {
	min-height: 200px;
}

.doublePicker {
	transition: 500ms;
	width: 650px;
}

.singlePicker {
	transition: 500ms;
	width: 330px;
}

.singleCustomPicker {
	transition: 500ms;
}

.calendarContainer {
	display: flex;
}

.day {
	@extend .fontSize14;
	@extend .fontWeight450;
	height: 37px;
	width: 37px;
	border-radius: 20px;
	padding: 9px 0;
	border-radius: 20px !important;
}

.day:hover {
	background-color: $neutral50;
	border-radius: 20px;
}

.currentMonth {
	color: $neutral900;
}

.currentDay {
	color: $success !important;
}

.selectedDay {
	background-color: $success !important;
	color: $white !important;
}

.selectedRange {
	background-color: $neutral100 !important;
	border-radius: 0 !important;
	color: $neutral900 !important;
}

.prevMonthSelected {
	background-color: $neutral50 !important;
	border-radius: 0 !important;
	color: $neutral900 !important;
}

.selectedFrom {
	border-radius: 20px 0 0 20px !important;
}

.selectedTo {
	border-radius: 0 20px 20px 0 !important;
}

.activeQuarter {
	border: 1px solid $black;
}

.activeQuarter {
	border: 1px solid $black;
}

.quarterButton {
	height: 3rem;
}

.quarterMonthsContainer {
	@include flexColumn;
	justify-content: 'center';
	height: 5rem;
	margin: 1rem;
}

.months {
	@include flexCenter;
	gap: 1.4rem;
}

.doneButton {
	width: fit-content;
	align-self: flex-end;
	margin: 1.4rem;
}

.disabledDate {
	opacity: 0.5 !important;
}
