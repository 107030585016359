@import 'src/constants/colors';
@import 'src/constants/global';
@import 'src/constants/mixins';

.container {
	position: relative;
}

.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10;
}

.buttonsContainer {
	@include borderNeutral;
	position: absolute;
	top: 5.5rem;
	right: 0;
	width: 26rem;
	height: 29.4rem;
	z-index: 11;
	background-color: $white;
	overflow: hidden;
	box-shadow: 0px 25px 50px -12px rgba(16, 24, 40, 0.25);
	animation: appear 0.2s ease-out forwards;
}

@keyframes appear {
	0% {
		opacity: 0;
		top: 7rem;
	}
	100% {
		opacity: 1;
		top: 5.5rem;
	}
}

.button {
	@include horizontalDivider;
	@include flexCenter;
	padding: 0.9rem 1.4rem;
	height: 9.8rem;
	cursor: pointer;
	transition: 400ms;
	background-color: $white;
}

.button:hover {
	transition: 400ms;
	background-color: $primary50;
}

.textContainer {
	padding-left: 1rem;
	color: $neutral900;
}

.icon {
	width: 7rem;
	height: auto;
}
