@import '../../constants/colors.scss';
@import '../../constants/mixins.scss';
@import '../../constants/global.scss';

.pageContainer {
	@extend .appearAnimation;
	@extend .minScreenSize;
	height: unset;
	height: 100%;
	min-height: 870px;
	min-width: 1150px;
}

.pageHeader {
	@include flexCenter;
	justify-content: space-between;
	align-items: center;
	padding: 2.4rem 2.4rem 0 2.4rem;
}

.pageTitle {
	@extend .fontSize28;
	@extend .fontWeight650;
	color: $neutral800;
}

.buttonsContainer {
	@include flex;
	height: 4.8rem;
	gap: 0.8rem;
}

.tabsContent {
	transition: 400ms;
	overflow: hidden;
	height: calc(100% - 13rem);
}

.activeTab {
	border-bottom: 1px solid $success;
	color: $success;
}

.menuButtons {
	width: 4rem;
}

.hidden {
	display: none;
}
