@import '../../../constants/colors.scss';
@import '../../../constants/mixins.scss';
@import '../../../constants/global.scss';

.sidebar {
	position: fixed;
	right: 0;
	background-color: $white;
	z-index: 30;
	@extend .sidebarTransition;
	@extend .sidebarSize;
}

.sidebarContent {
	min-height: 700px;
	height: 100vh;
}

.sidebar:active {
	@extend .sidebarTransition;
}

.secondarySidebar {
	height: 100%;
	position: fixed;
	background-color: $white;
	@extend .sidebarTransition;
	z-index: 20;
	top: 0.1rem;
	right: 60.2rem;
	@extend .sidebarSize;
}

.sidebarHidden {
	width: 0;
	height: 100%;
	position: fixed;
	right: -100%;
	overflow: hidden;
	top: 0.1rem;
	z-index: 50;
	@extend .sidebarTransition;
}

.sidebarHidden:active {
	@extend .sidebarTransition;
}

.topSection {
	padding: 0 2rem;
}

.titleContainer {
	@include flex;
	align-items: center;
	padding: 2.4rem 0 1.4rem 0;
	gap: 0.6rem;
}

.tabsContainer {
	padding: 0 2rem;
}

.detailsRowContainer {
	padding: 0 2.4rem 0 2.4rem;
}

.subTitle {
	@extend .fontSize14;
	color: $neutral900;
	display: block;
}

.detailsRow {
	margin-bottom: 1.4rem;
}

.detailsText {
	@extend .neutralFontSize12;
	display: inline-block;
	width: 9rem;
}

.grayText {
	@extend .fontSize14;
	color: $neutral400;
}

.blackText {
	@extend .fontSize12;
	color: $neutral800;
}

.link {
	@extend .fontSize12;
	color: $primary;
	text-decoration: none;
}

.tabsSection {
	height: calc(100% - 30rem);
	overflow: hidden;
}

.tabsContent {
	height: calc(100% - 5rem);
}

.contractIcon {
	@include borderNeutral;
	@include flexCenter;
	position: relative;
	border-radius: 0.6rem;
	width: 6.8rem;
	height: 6.8rem;
	margin-right: 1.4rem;
}

.poContainer {
	@include flexColumn;
	height: 6.8rem;
	&:focus-within {
		border-bottom: 2px solid $neutral900;
	}
}

.poInputContainer {
	@include flexCenter;

	input {
		height: 3.6rem;
		width: 28rem;
	}
}

.poPrefixEmpty {
	@extend .fontSize24;
	@extend .fontWeight850;
}

.invoiceTabContainer {
	height: calc(100% - 5rem);
}

.tabComponent {
	&:first-child {
		padding-left: 0;
	}
}

.activeTab {
	border-bottom: 1px solid $black;
}

.hamburgerButton {
	width: 4rem;
	height: 4rem;
	transition: 400ms;
}

.hamburgerRotate {
	transform: rotate(90deg);
	transition: 400ms;
}

.buttonIcon {
	margin-bottom: 0.5rem;
}
