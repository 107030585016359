@import '../../../constants/colors.scss';

.stepLinking {
	height: 6.2rem;
	width: 0.2rem;
	margin: 0rem 1.8rem;
}

.activeLinking {
	background-color: $success;
}

.inactiveLinking {
	background-color: $neutral200;
}

.inactiveLinkingNeutral {
	background-color: $neutral700;
}
