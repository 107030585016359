@import '../../constants/colors.scss';
@import '../../constants/mixins.scss';
@import '../../constants/global.scss';

.dataSelectContainer {
	position: relative;
	width: 100%;
}

.selectedSmallContainer {
	@include horizontalDivider;
	padding: 1rem 0;
	width: 100% !important;
	min-height: 6rem;
	align-items: center;
}

.selectListContainer {
	position: absolute;
	width: 100%;
	@include defaultBoxShadow;
	border-radius: 0;
	overflow: hidden;
	transition: 600ms;
	z-index: 300;
	box-shadow: -10px 8px 40px -6px rgba(16, 24, 40, 0.1), 0px 20px 25px -5px rgba(16, 24, 40, 0.1);
}

.selectListContainer:active {
	transition: 600ms;
}

.selectListContainerHidden {
	background-color: $white;
	position: absolute;
	height: 0rem;
	overflow: hidden;
}

.selectList {
	max-height: 29.5rem;
	overflow: auto;
}

.selectItem {
	padding: 2.4rem 1.6rem;
	@include horizontalDivider;
}

.selectItem:hover {
	background-color: $neutral50;
	cursor: pointer;
}

.customerDetails {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.hamburgerButton {
	height: 4.8rem;
	width: 4.8rem;
	padding: 0rem;
}

.hamburger {
	height: 2rem;
}

.selectAddButton {
	display: flex;
	height: 7.6rem;
	background-color: $neutral50;
	color: $success;
	align-items: center;
	padding: 0rem 2.4rem;
	border-top: 0.1rem solid $neutral200;
}

.selectAddButton:hover {
	background-color: $neutral100;
	color: $neutral600;
	cursor: pointer;
}

.selectOverlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 40;
}

.searchIcon {
	display: flex;
	height: 6.8rem;
	width: 6.8rem;
	background-color: $neutral50;
	border: 1px dashed $neutral400;
	align-items: center;
	justify-content: center;
}

.inputContainer {
	@extend .fontSize24;
	height: 4.8rem;
	width: 100%;
	border: none;
	color: $neutral;
	padding: 0rem 2.4rem 0rem 2.4rem;
	display: flex;
	align-items: center;
}

.inputContainer:focus {
	outline: none;
}

.ellipsis {
	@include overflowHiddenEllipsisNowrap;
	max-width: 90%;
}

.ellipsisSelected {
	@include overflowHiddenEllipsisNowrap;
	max-width: 65%;
}
